import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Dictionary } from 'app/shared/models/dictionary.model';
import { GhostOverlayRef } from 'app/shared/models/ghost-overlay.model';
import { Subscription } from 'rxjs';
import { KeyboardNaviagtionContainerDirective } from '../../directives/keyboard-navigation.directive';
import { Keys } from '../../enums/keys.enum';
import { ShortcutsService } from '../../services/shortcuts.service';
import { ConfirmationOverlayShortcutsConfiguration } from './confirmation-shortcuts.configuration';

export enum ConfirmationType {
    Danger = 'danger',
    Default = 'info',
    Warning = 'warning',
};

export interface ConfirmationOverlayBtn {
    key?: string;
    onClick: () => void;
    name?: string;
    text: string;
};

export interface ConfirmationOverlayData {
    buttons?: Array<ConfirmationOverlayBtn>;
    defaultConfirmationBtn?: DefaultConfirmationBtn;
    description?: string | Array<string>;
    descriptionFontSize?: number;
    extendedQuestions?: Array<{ title: string; messages: Array<string> }>;
    question?: string;
    shortcuts?: Dictionary<() => void>;
    title?: string;
    type?: ConfirmationType;
};

export enum DefaultConfirmationBtn {
    Yes = 'yesBtn',
    No = 'noBtn',
};

@Component({
    selector: 'confirmation-overlay',
    styleUrls: ['confirmation.component.css'],
    templateUrl: 'confirmation.component.html'
})
export class ConfirmationOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(KeyboardNaviagtionContainerDirective) public keyboardNavDirective: KeyboardNaviagtionContainerDirective;
    @Input() public buttons: Array<ConfirmationOverlayBtn> = [
        { text: 'btn.yes', key: 'Y', name: DefaultConfirmationBtn.Yes, onClick: () => this.close(true) },
        { text: 'btn.no', key: 'N', name: DefaultConfirmationBtn.No, onClick: () => this.close(false) },
    ];
    @Input() public shortcuts: Dictionary<() => void> = {
        [Keys.Escape]: () => this.close(false),
        [Keys.N]: () => this.close(false),
        [Keys.Y]: () => this.close(true)
    };
    public defaultConfirmationBtn: DefaultConfirmationBtn;
    public description: string | Array<string> = '';
    public descriptionFontSize: number = 16;
    public descriptionIsArray: boolean = false;
    public extendedQuestions?: Array<{ title: string; messages: Array<string> }>;
    public focusFirstValidNode: boolean = true;
    public justifyContentCenter: boolean = false;
    public question: string = 'alert.question.are_you_sure';
    public title: string = 'alert.title.confirmation';
    public type: ConfirmationType = ConfirmationType.Default;

    private shortcutsSubscription: Subscription;

    public constructor(private elementRef: ElementRef, private overlayRef: GhostOverlayRef<ConfirmationOverlayData>, private renderer: Renderer2) {
        if (this.overlayRef.data.defaultConfirmationBtn) {
            this.defaultConfirmationBtn = this.overlayRef.data.defaultConfirmationBtn;
            this.focusFirstValidNode = false;
        };
        this.overlayRef.data.defaultConfirmationBtn && (this.defaultConfirmationBtn = this.overlayRef.data.defaultConfirmationBtn);
        this.overlayRef.data.description && (this.description = this.overlayRef.data.description);
        this.overlayRef.data.descriptionFontSize && (this.descriptionFontSize = this.overlayRef.data.descriptionFontSize);
        this.descriptionIsArray = Array.isArray(this.description);
        this.overlayRef.data.extendedQuestions && (this.extendedQuestions = this.overlayRef.data.extendedQuestions);
        this.overlayRef.data.question && (this.question = this.overlayRef.data.question);
        this.overlayRef.data.title && (this.title = this.overlayRef.data.title);
        this.overlayRef.data.type && (this.type = this.overlayRef.data.type);
        if (!this.description && this.extendedQuestions?.length === 1) {
            this.justifyContentCenter = true;
        };
    };

    public ngOnInit() {
        this.shortcutsSubscription = ShortcutsService.setShortcutListener(ConfirmationOverlayShortcutsConfiguration.shortcutsSubject, this.shortcuts);
        this.renderer.addClass(this.elementRef.nativeElement, this.type);
    };

    public ngAfterViewInit() {
        if (this.defaultConfirmationBtn && this.keyboardNavDirective) {
            this.keyboardNavDirective.focusByNameAttribute(this.defaultConfirmationBtn);
        };
    };

    public ngOnDestroy() {
        this.shortcutsSubscription && this.shortcutsSubscription.unsubscribe();
    };

    public close = this.overlayRef.close;

    public onBtnKeydown(event: KeyboardEvent, btn: ConfirmationOverlayBtn) {
        if (event.type.toLowerCase() === 'click') {
            this.onClick(event, btn);
        } else if (event.key === Keys.Enter || event.key === Keys.Space) {
            ShortcutsService.setNextKeyCodeToPreventOnKeyup(event.key);
            this.onClick(event, btn);
        };
    };

    private onClick(event: KeyboardEvent | MouseEvent, btn: ConfirmationOverlayBtn) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        };
        btn.onClick();
    };
};