export class BookingScreenToggleFieldsApi {
    public cli: boolean;
    public ivr: boolean;

    public constructor(item?: BookingScreenToggleFieldsApi) {
        if (item) {
            item.cli && (this.cli = item.cli);
            item.ivr && (this.ivr = item.ivr);
        };
    };
};

export class BookingScreenToggleFieldsView {
    public cli: boolean = false;
    public ivr: boolean = false;

    public constructor(item?: BookingScreenToggleFieldsView) {
        if (item) {
            item.cli && (this.cli = item.cli);
            item.ivr && (this.ivr = item.ivr);
        };
    };
};