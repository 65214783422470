import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GhostOverlayRef } from 'app/shared/models/ghost-overlay.model';
import { Subscription } from 'rxjs';
import { Keys } from '../../enums/keys.enum';
import { ShortcutsService } from '../../services/shortcuts.service';
import { AlertOverlayShortcutsConfiguration } from './alert-shortcuts.configuration';

export enum AlertType {
    Error = 'error',
    Info = 'info',
    Warning = 'warning',
};

export interface AlertOverlayData {
    messages?: string | Array<string>;
    title?: string;
    type?: AlertType
};

@Component({
    selector: 'alert-overlay',
    styleUrls: ['alert.component.css'],
    templateUrl: 'alert.component.html'
})
export class AlertOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dismissBtn') public dismissBtn: ElementRef<HTMLElement>;
    public messages: string | Array<string> = '';
    public messagesIsArray: boolean = false;
    public title: string = 'alert.title.confirmation';
    public type: AlertType = AlertType.Info;

    private shortcuts = {
        [Keys.Escape]: () => this.overlayRef.dismiss()
    };
    private shortcutsSubscription: Subscription;

    public constructor(private elementRef: ElementRef, private overlayRef: GhostOverlayRef<AlertOverlayData>, private renderer: Renderer2) {
        this.overlayRef.data.messages && (this.messages = this.overlayRef.data.messages);
        this.messagesIsArray = Array.isArray(this.messages);
        this.overlayRef.data.title && (this.title = this.overlayRef.data.title);
        this.overlayRef.data.type && (this.type = this.overlayRef.data.type);
    };

    public ngOnInit() {
        this.renderer.addClass(this.elementRef.nativeElement, this.type);
        this.shortcutsSubscription = ShortcutsService.setShortcutListener(AlertOverlayShortcutsConfiguration.shortcutsSubject, this.shortcuts);
    };

    public ngAfterViewInit() {
        if (this.dismissBtn && this.dismissBtn.nativeElement) {
            this.dismissBtn.nativeElement.focus();
        };
    };

    public ngOnDestroy() {
        this.shortcutsSubscription && this.shortcutsSubscription.unsubscribe();
    };

    public dismiss(event?: MouseEvent | KeyboardEvent) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        };
        this.overlayRef.dismiss();
    };

    public onBtnKeydown(event: KeyboardEvent) {
        if (event.type.toLowerCase() === 'click') {
            this.dismiss(event);
        } else if (event.key === Keys.Enter || event.key === Keys.Space) {
            ShortcutsService.setNextKeyCodeToPreventOnKeyup(event.key);
            this.dismiss(event);
        };
    };
};