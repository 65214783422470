export enum BookingPermissions {
    AccessBookingSuspensions = 'AccessBookingSuspensions',
    AccessCabExchangeTransfer = 'AccessCabExchangeTransfer',
    AccessCallDetails = 'AccessCallDetails',
    AccessCallbackStatus = 'AccessCallbackStatus',
    AccessChangeHistory = 'AccessChangeHistory',
    AccessChannelMonitor = 'AccessChannelMonitor',
    AccessChannelStatistics = 'AccessChannelStatistics',
    AccessClearedRejectedVehiclesCommand = 'AccessClearedRejectedVehiclesCommand',
    AccessDataLogHistory = 'AccessDataLogHistory',
    AccessDockets = 'AccessDockets',
    AccessDriverInformation = 'AccessDriverInformation',
    AccessDriverMessageHistory = 'AccessDriverMessageHistory',
    AccessDriverMessages = 'AccessDriverMessages',
    AccessDriverQueue = 'AccessDriverQueue',
    AccessFlightInformation = 'AccessFlightInformation',
    AccessHoldReleaseZones = 'AccessHoldReleaseZones',
    AccessJobTemplates = 'AccessJobTemplates',
    AccessLiveDriverShifts = 'AccessLiveDriverShifts',
    AccessNewDriverMessage = 'AccessNewDriverMessage',
    AccessNotificationHistory = 'AccessNotificationHistory',
    AccessPrintBookingReceipt = 'AccessPrintBookingReceipt',
    AccessReports = 'AccessReports',
    AccessSupervisor = 'AccessSupervisor',
    AccessVehicleInformation = 'AccessVehicleInformation',
    AccessVehicleStatistics = 'AccessVehicleStatistics',
    AccessVehicleTracking = 'AccessVehicleTracking',
    AllowFinishVehicleOutOfShift = 'AllowFinishVehicleOutOfShift',
    AllowFinishVehicleWhenAllocated = 'AllowFinishVehicleWhenAllocated',
    AllowIvrToggleOnBooking = 'AllowIvrToggleOnBooking',
    AllowLaunchCustomerWebsite = 'AllowLaunchCustomerWebsite',
    AllowModifyCreditCardPaymentsAmount = 'AllowModifyCreditCardPaymentsAmount',
    AllowModifyDispatchedBookings = 'AllowModifyDispatchedBookings',
    AllowOverrideBookingControls = 'AllowOverrideBookingControls',
    AllowReplyCustomerTextMessage = 'AllowReplyCustomerTextMessage',
    AllowReplyDriverTextMessage = 'AllowReplyDriverTextMessage',
    AllowRingbackToggleOnBooking = 'AllowRingbackToggleOnBooking',
    AllowRingbackToggleOnBookingsWithMobileNumbers = 'AllowRingbackToggleOnBookingsWithMobileNumbers',
    AllowSendAllDriversTextMessage = 'AllowSendAllDriversTextMessage',
    AllowSendDriverBookingTextMessage = 'AllowSendDriverBookingTextMessage',
    AllowSendDriverTextMessage = 'AllowSendDriverTextMessage',
    AllowStartVehicleOutOfShift = 'AllowStartVehicleOutOfShift',
    AllowViewCustomerTextMessages = 'AllowViewCustomerTextMessages',
    AllowViewDriverTextMessages = 'AllowViewDriverTextMessages',
    BookingCommandCancelActive = 'BookingCommandCancelActive',
    BookingCommandCancelAdvanced = 'BookingCommandCancelAdvanced',
    BookingCommandHoldActive = 'BookingCommandHoldActive',
    BookingCommandHoldAdvanced = 'BookingCommandHoldAdvanced',
    BookingCommandHoldNew = 'BookingCommandHoldNew',
    BookingCommandOverrideActive = 'BookingCommandOverrideActive',
    BookingCommandReleaseActive = 'BookingCommandReleaseActive',
    BookingCommandReleaseAdvanced = 'BookingCommandReleaseAdvanced',
    BookingsRepeatBooking = 'BookingsRepeatBooking',
    CancelJobTemplates = 'CancelJobTemplates',
    ExportGlobalSearchResults = 'ExportGlobalSearchResults',
    ExportHistory = 'ExportHistory',
    Ghost365AccessActiveBookingsPanel = 'Ghost365AccessActiveBookingsPanel',
    Ghost365AccessCliBookingsPanel = 'Ghost365AccessCliBookingsPanel',
    Ghost365AccessCurrentBookingsPanel = 'Ghost365AccessCurrentBookingsPanel',
    Ghost365AccessAdvancedRepeatBookingsOneDayOption = 'Ghost365AccessAdvancedRepeatBookingsOneDayOption',
    Ghost365AccessAdvancedRepeatBookingsAllDaysOption = 'Ghost365AccessAdvancedRepeatBookingsAllDaysOption',
    Ghost365AccessDispatchedBookingsPanel = 'Ghost365AccessDispatchedBookingsPanel',
    Ghost365AccessExchangedBookingsPanel = 'Ghost365AccessExchangedBookingsPanel',
    Ghost365AccessPreBookingsPanel = 'Ghost365AccessPreBookingsPanel',
    Ghost365AccessSearchBookingsPanel = 'Ghost365AccessSearchBookingsPanel',
    Ghost365AccessWarningBookingsPanel = 'Ghost365AccessWarningBookingsPanel',
    IncrementalReleaseCode = 'IncrementalReleaseCode',
    InsertDocketFromHistory = 'InsertDocketFromHistory',
    LogOnToBookings = 'LogOnToBookings',
    ModifyGlobalSearchExportSettings = 'ModifyGlobalSearchExportSettings',
    ModifyHistoryExportSettings = 'ModifyHistoryExportSettings',
    OperatorCommandOperatorMessaging = 'OperatorCommandOperatorMessaging',
    OperatorMessageHistoryShowAll = 'OperatorMessageHistoryShowAll',
    PlayPrebookingReleasedSound = 'PlayPrebookingReleasedSound',
    ReassignBooking = 'ReassignBooking',
    TakeCreditCardPayments = 'TakeCreditCardPayments',
    ToggleDriverSuspended = 'ToggleDriverSuspended',
    ToggleIvrSystem = 'ToggleIvrSystem',
    ToggleMobileBookingSystem = 'ToggleMobileBookingSystem',
    ToggleTextBookingSystem = 'ToggleTextBookingSystem',
    VehicleCommandAccount = 'VehicleCommandAccount',
    VehicleCommandCancelDispatched = 'VehicleCommandCancelDispatched',
    VehicleCommandClear = 'VehicleCommandClear',
    VehicleCommandDispatch = 'VehicleCommandDispatch',
    VehicleCommandDriverMessageDelete = 'VehicleCommandDriverMessageDelete',
    VehicleCommandFinish = 'VehicleCommandFinish',
    VehicleCommandJobs = 'VehicleCommandJobs',
    VehicleCommandLocate = 'VehicleCommandLocate',
    VehicleCommandManualDispatch = 'VehicleCommandManualDispatch',
    VehicleCommandMobile = 'VehicleCommandMobile',
    VehicleCommandNoFare = 'VehicleCommandNoFare',
    VehicleCommandPlot = 'VehicleCommandPlot',
    VehicleCommandQuery = 'VehicleCommandQuery',
    VehicleCommandQueuePosition = 'VehicleCommandQueuePosition',
    VehicleCommandRecover = 'VehicleCommandRecover',
    VehicleCommandRemovePenalty = 'VehicleCommandRemovePenalty',
    VehicleCommandRequestVehicle = 'VehicleCommandRequestVehicle',
    VehicleCommandRingback = 'VehicleCommandRingback',
    VehicleCommandStart = 'VehicleCommandStart',
    VehicleCommandViewCurrentJob = 'VehicleCommandViewCurrentJob',
    // Hidden in ghost API
    // VehicleCommandViewVehicleDetails = 'VehicleCommandViewVehicleDetails',
    VehicleCommandVoiceRequest = 'VehicleCommandVoiceRequest',
    VehicleQueryDispatch = 'VehicleQueryDispatch',
    ZoneCommandMapView = 'ZoneCommandMapView',
    ZoneCommandStreetView = 'ZoneCommandStreetView'
};