import { DistanceUnits } from "../enums/distance-units.enum";

export class SystemDetailsApi {
    public companyId: string;
    public name: string;
    public ghostServerVersion: SystemDetailsGhostServerVersion;
    public ghostWebApiVersion: SystemDetailsGhostWebApiVersion;
    public cultureInfo: SystemDetailsCultureInfo;
    public authentication: SystemDetailsAuthentication;

    public constructor(item?: SystemDetailsApi) {
        this.companyId = (item && typeof item.companyId == 'number') ? item.companyId : null;
        this.name = (item && item.name) ? item.name : '';
        this.ghostServerVersion = (item && item.ghostServerVersion) ? new SystemDetailsGhostServerVersion(item.ghostServerVersion) : new SystemDetailsGhostServerVersion();
        this.ghostWebApiVersion = (item && item.ghostWebApiVersion) ? new SystemDetailsGhostWebApiVersion(item.ghostWebApiVersion) : new SystemDetailsGhostWebApiVersion();
        this.cultureInfo = (item && item.cultureInfo) ? new SystemDetailsCultureInfo(item.cultureInfo) : new SystemDetailsCultureInfo();
        this.authentication = (item && item.authentication) ? new SystemDetailsAuthentication(item.authentication) : new SystemDetailsAuthentication();
    };
};

export class SystemDetailsGhostServerVersion {
    public name: string;
    public major: number;
    public minor: number;
    public revision: number;
    public release: string;

    public constructor(item?: SystemDetailsGhostServerVersion) {
        this.name = (item && item.name) ? item.name : '';
        this.major = (item && typeof item.major == 'number') ? item.major : null;
        this.minor = (item && typeof item.minor == 'number') ? item.minor : null;
        this.revision = (item && typeof item.revision == 'number') ? item.revision : null;
        this.release = (item && item.release) ? item.release : '';
    };
};

export class SystemDetailsGhostWebApiVersion {
    public major: number;
    public minor: number;

    public constructor(item?: SystemDetailsGhostWebApiVersion) {
        this.major = (item && typeof item.major == 'number') ? item.major : null;
        this.minor = (item && typeof item.minor == 'number') ? item.minor : null;
    };
};

export class SystemDetailsCultureInfo {
    public name: string;
    public country: string;
    public country3: string;
    public currencySymbol: string;
    public distanceUnits: DistanceUnits;

    public constructor(item?: SystemDetailsCultureInfo) {
        this.name = (item && item.name) ? item.name : '';
        this.country = (item && item.country) ? item.country : '';
        this.country3 = (item && item.country3) ? item.country3 : '';
        this.currencySymbol = (item && item.currencySymbol) ? item.currencySymbol : '';
        this.distanceUnits = (item && item.distanceUnits) ? item.distanceUnits : null;
    };
};

export class SystemDetailsAuthentication {
    public mode: string;
    public parameters: any;

    public constructor(item?: SystemDetailsAuthentication) {
        this.mode = (item && item.mode) ? item.mode : '';
        this.parameters = (item && item.parameters) ? item.parameters : null;
    };
};