import { BookingScreenToggleFieldsApi, BookingScreenToggleFieldsView } from "./booking-screen-toggle-fields.model";
import { GlobalSearchActiveSourcesApi, GlobalSearchActiveSourcesView } from "./global-search-active-source.model";
import { GlobalSearchAddressFilterOptionsApi, GlobalSearchAddressFilterOptionsView } from "./global-search-address-filter-options.model";
import { GlobalSearchHistoricSourcesApi, GlobalSearchHistoricSourcesView } from "./global-search-historic-sources.model";
import { MandatoryFieldApi, MandatoryFieldView } from "./mandatory-field.model";

export enum UserInterfaceMode {
    Driver = 'Driver',
    Vehicle = 'Vehicle'
};

export enum MapClickDispatchMode {
    Off = 'Off',
    On = 'On',
    OnNoConfirmation = 'OnNoConfirmation'
};

export enum ZoneMapLabels {
    None = 'None',
    ID = 'ID',
    Descriptor = 'Descriptor',
    Name = 'Name'
};

export class UISettingsApi {
    public autoBoxMessageAction: boolean;
    public bookingReceiptCopies: number;
    public bookingReceiptNote: string;
    public bookingScreenToggleFields: BookingScreenToggleFieldsApi;
    public enableAlphaNumericCallSigns: boolean;
    public enableBookingReceipt: boolean;
    public enableBookingsButton: boolean;
    public enableCompanyHeaderInBookingEditor: boolean;
    public enableFlightAutoUpdatePickupTime: boolean;
    public enableNoJobQuery: boolean;
    public enableRoundButtons: boolean;
    public enableStreetView: boolean;
    public enableTrainingButton: boolean;
    public enableZoneHeaderBackgroundColour: boolean;
    public enableZoneHeaderForegroundColour: boolean;
    public globalSearchActiveSources: GlobalSearchActiveSourcesApi;
    public globalSearchAddressFilterOptions: GlobalSearchAddressFilterOptionsApi;
    public globalSearchExactMatchFilter: boolean;
    public globalSearchHistoricSources: GlobalSearchHistoricSourcesApi;
    public mandatoryBookingFields: Array<MandatoryFieldApi>;
    public mapClickDispatch: MapClickDispatchMode;
    public matchAnyWord: boolean;
    public maximumGlobalSearchResults: number;
    public showBookingReceiptPrintOptions: boolean;
    public showStreetJobs: boolean;
    public uiMode: UserInterfaceMode;
    public useTimeZones: boolean;
    public validatePickupInBookingEditor: boolean;
    public wallSurgePricing: boolean;
    public zoneMapLabels: ZoneMapLabels;

    public constructor(item?: UISettingsApi) {
        if (item) {
            item.autoBoxMessageAction && (this.autoBoxMessageAction = item.autoBoxMessageAction);
            item.bookingReceiptCopies !== null && item.bookingReceiptCopies !== undefined && (this.bookingReceiptCopies = item.bookingReceiptCopies);
            item.bookingReceiptNote && (this.bookingReceiptNote = item.bookingReceiptNote);
            item.bookingScreenToggleFields && (this.bookingScreenToggleFields = new BookingScreenToggleFieldsApi(item.bookingScreenToggleFields));
            item.enableAlphaNumericCallSigns && (this.enableAlphaNumericCallSigns = item.enableAlphaNumericCallSigns);
            item.enableBookingReceipt && (this.enableBookingReceipt = item.enableBookingReceipt);
            item.enableBookingsButton && (this.enableBookingsButton = item.enableBookingsButton);
            item.enableCompanyHeaderInBookingEditor && (this.enableCompanyHeaderInBookingEditor = item.enableCompanyHeaderInBookingEditor);
            item.enableFlightAutoUpdatePickupTime && (this.enableFlightAutoUpdatePickupTime = item.enableFlightAutoUpdatePickupTime);
            item.enableNoJobQuery && (this.enableNoJobQuery = item.enableNoJobQuery);
            item.enableRoundButtons && (this.enableRoundButtons = item.enableRoundButtons);
            item.enableStreetView && (this.enableStreetView = item.enableStreetView);
            item.enableTrainingButton && (this.enableTrainingButton = item.enableTrainingButton);
            item.enableZoneHeaderBackgroundColour && (this.enableZoneHeaderBackgroundColour = item.enableZoneHeaderBackgroundColour);
            item.enableZoneHeaderForegroundColour && (this.enableZoneHeaderForegroundColour = item.enableZoneHeaderForegroundColour);
            item.globalSearchActiveSources && (this.globalSearchActiveSources = new GlobalSearchActiveSourcesApi(item.globalSearchActiveSources));
            item.globalSearchAddressFilterOptions && (this.globalSearchAddressFilterOptions = new GlobalSearchAddressFilterOptionsApi(item.globalSearchAddressFilterOptions));
            item.globalSearchExactMatchFilter && (this.globalSearchExactMatchFilter = item.globalSearchExactMatchFilter);
            item.globalSearchHistoricSources && (this.globalSearchHistoricSources = new GlobalSearchHistoricSourcesApi(item.globalSearchHistoricSources));
            item.mandatoryBookingFields && (this.mandatoryBookingFields = item.mandatoryBookingFields);
            item.mapClickDispatch && (this.mapClickDispatch = item.mapClickDispatch);
            item.matchAnyWord && (this.matchAnyWord = item.matchAnyWord);
            item.maximumGlobalSearchResults !== null && item.maximumGlobalSearchResults !== undefined && (this.maximumGlobalSearchResults = item.maximumGlobalSearchResults);
            item.showBookingReceiptPrintOptions && (this.showBookingReceiptPrintOptions = item.showBookingReceiptPrintOptions);
            item.showStreetJobs && (this.showStreetJobs = item.showStreetJobs);
            item.uiMode && (this.uiMode = item.uiMode);
            item.useTimeZones && (this.useTimeZones = item.useTimeZones);
            item.validatePickupInBookingEditor && (this.validatePickupInBookingEditor = item.validatePickupInBookingEditor);
            item.wallSurgePricing && (this.wallSurgePricing = item.wallSurgePricing);
            item.zoneMapLabels && (this.zoneMapLabels = item.zoneMapLabels);
        };
    };
};

export class UISettingsView {
    public autoBoxMessageAction: boolean = false;
    public bookingReceiptCopies: number = null;
    public bookingReceiptNote: string = '';
    public bookingScreenToggleFields: BookingScreenToggleFieldsView = new BookingScreenToggleFieldsView();
    public enableAlphaNumericCallSigns: boolean = false;
    public enableBookingReceipt: boolean = false;
    public enableBookingsButton: boolean = false;
    public enableCompanyHeaderInBookingEditor: boolean = false;
    public enableFlightAutoUpdatePickupTime: boolean = false;
    public enableNoJobQuery: boolean = false;
    public enableRoundButtons: boolean = false;
    public enableStreetView: boolean = false;
    public enableTrainingButton: boolean = false;
    public enableZoneHeaderBackgroundColour: boolean = false;
    public enableZoneHeaderForegroundColour: boolean = false;
    public globalSearchActiveSources: GlobalSearchActiveSourcesView = new GlobalSearchActiveSourcesView();
    public globalSearchAddressFilterOptions: GlobalSearchAddressFilterOptionsView = new GlobalSearchAddressFilterOptionsView();
    public globalSearchExactMatchFilter: boolean = false;
    public globalSearchHistoricSources: GlobalSearchHistoricSourcesView = new GlobalSearchHistoricSourcesView();
    public mandatoryBookingFields: Array<MandatoryFieldView> = [];
    public mapClickDispatch: MapClickDispatchMode = MapClickDispatchMode.Off;
    public matchAnyWord: boolean = false;
    public maximumGlobalSearchResults: number = null;
    public showBookingReceiptPrintOptions: boolean = false;
    public showStreetJobs: boolean = false;
    public uiMode: UserInterfaceMode = UserInterfaceMode.Vehicle;
    public useTimeZones: boolean = false;
    public validatePickupInBookingEditor: boolean = false;
    public wallSurgePricing: boolean = false;
    public zoneMapLabels: ZoneMapLabels = ZoneMapLabels.None;

    public constructor(item?: UISettingsView) {
        if (item) {
            item.autoBoxMessageAction && (this.autoBoxMessageAction = item.autoBoxMessageAction);
            item.bookingReceiptCopies !== null && item.bookingReceiptCopies !== undefined && (this.bookingReceiptCopies = item.bookingReceiptCopies);
            item.bookingReceiptNote && (this.bookingReceiptNote = item.bookingReceiptNote);
            item.bookingScreenToggleFields && (this.bookingScreenToggleFields = new BookingScreenToggleFieldsView(item.bookingScreenToggleFields));
            item.enableAlphaNumericCallSigns && (this.enableAlphaNumericCallSigns = item.enableAlphaNumericCallSigns);
            item.enableBookingReceipt && (this.enableBookingReceipt = item.enableBookingReceipt);
            item.enableBookingsButton && (this.enableBookingsButton = item.enableBookingsButton);
            item.enableCompanyHeaderInBookingEditor && (this.enableCompanyHeaderInBookingEditor = item.enableCompanyHeaderInBookingEditor);
            item.enableFlightAutoUpdatePickupTime && (this.enableFlightAutoUpdatePickupTime = item.enableFlightAutoUpdatePickupTime);
            item.enableNoJobQuery && (this.enableNoJobQuery = item.enableNoJobQuery);
            item.enableRoundButtons && (this.enableRoundButtons = item.enableRoundButtons);
            item.enableStreetView && (this.enableStreetView = item.enableStreetView);
            item.enableTrainingButton && (this.enableTrainingButton = item.enableTrainingButton);
            item.enableZoneHeaderBackgroundColour && (this.enableZoneHeaderBackgroundColour = item.enableZoneHeaderBackgroundColour);
            item.enableZoneHeaderForegroundColour && (this.enableZoneHeaderForegroundColour = item.enableZoneHeaderForegroundColour);
            item.globalSearchActiveSources && (this.globalSearchActiveSources = new GlobalSearchActiveSourcesView(item.globalSearchActiveSources));
            item.globalSearchAddressFilterOptions && (this.globalSearchAddressFilterOptions = new GlobalSearchAddressFilterOptionsView(item.globalSearchAddressFilterOptions));
            item.globalSearchExactMatchFilter && (this.globalSearchExactMatchFilter = item.globalSearchExactMatchFilter);
            item.globalSearchHistoricSources && (this.globalSearchHistoricSources = new GlobalSearchHistoricSourcesView(item.globalSearchHistoricSources));
            item.mandatoryBookingFields && (this.mandatoryBookingFields = item.mandatoryBookingFields);
            item.mapClickDispatch && (this.mapClickDispatch = item.mapClickDispatch);
            item.matchAnyWord && (this.matchAnyWord = item.matchAnyWord);
            item.maximumGlobalSearchResults !== null && item.maximumGlobalSearchResults !== undefined && (this.maximumGlobalSearchResults = item.maximumGlobalSearchResults);
            item.showBookingReceiptPrintOptions && (this.showBookingReceiptPrintOptions = item.showBookingReceiptPrintOptions);
            item.showStreetJobs && (this.showStreetJobs = item.showStreetJobs);
            item.uiMode && (this.uiMode = item.uiMode);
            item.useTimeZones && (this.useTimeZones = item.useTimeZones);
            item.validatePickupInBookingEditor && (this.validatePickupInBookingEditor = item.validatePickupInBookingEditor);
            item.wallSurgePricing && (this.wallSurgePricing = item.wallSurgePricing);
            item.zoneMapLabels && (this.zoneMapLabels = item.zoneMapLabels);
        };
    };
};