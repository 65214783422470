import { Injectable } from '@angular/core';
import { Autocab365Session, SessionUser } from '../models/session.model';
import { Endpoints } from '../endpoints';
import { StorageService } from './storage.service';

@Injectable()
export class SessionService {
    private static _session: Autocab365Session = null;

    /*public static getSessionUser(): SessionUser {
        return SessionService._session ? SessionService._session.user : null;
    };*/

    /*public static getSessionUserId(): number {
        return SessionService._session && SessionService._session.user ? SessionService._session.user.id : null;
    };*/

    public static getToken(): string {
        return SessionService._session ? SessionService._session.token : '';
    };

    public static setToken(token: string) {
        return SessionService._session.token = token;
    };

    public static get session(): Autocab365Session {
        return SessionService._session;
    };

    public static set session(session: Autocab365Session) {
        SessionService._session = session;
        if (SessionService._session && SessionService._session.apiUrl) {
            Endpoints.API_URL = SessionService._session.apiUrl;
        };
    };
};