import { Dictionary } from '../../models/dictionary.model';
import { Keys } from '../../enums/keys.enum';
import { ShortcutConfig } from '../../interfaces/shortcut-config.interface';
import { Subject } from 'rxjs';

export class AlertOverlayShortcutsConfiguration {
    public static shortcutsSubject = new Subject<string>();

    public static _SHORTCUTS: Dictionary<ShortcutConfig> = {
        [Keys.Escape]: {
            callback: () => AlertOverlayShortcutsConfiguration.shortcutsSubject.next(Keys.Escape),
            description: 'shortcut.description.go_to_dashboard',
            descriptionKey: 'Esc',
            key: Keys.Escape,
            visible: true
        }
    };
};