import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DomChangeDirective } from './dom-change.directive';
import {
    KeyboardNaviagtionContainerDirective,
    KeyboardNavigationGroupDirective,
    KeyboardNavigationGroupNodeDirective,
    KeyboardNavigationNodeDirective
} from './keyboard-navigation.directive';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        DomChangeDirective,
        KeyboardNaviagtionContainerDirective,
        KeyboardNavigationNodeDirective,
        KeyboardNavigationGroupDirective,
        KeyboardNavigationGroupNodeDirective
    ],
    exports: [
        DomChangeDirective,
        KeyboardNaviagtionContainerDirective,
        KeyboardNavigationNodeDirective,
        KeyboardNavigationGroupDirective,
        KeyboardNavigationGroupNodeDirective
    ]
})
export class DirectivesModule { }