import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Endpoints } from "../endpoints";
import { Dictionary } from "../models/dictionary.model";
import { TimeZone } from "../models/time-zone.model";
import { DateUtilities } from "../utilities/date.utilities";
import { ObjectUtilities } from "../utilities/object.utilities";
import { RequestService } from "./request.service";

@Injectable()
export class TimeService implements OnDestroy {
    public static clientTimeZoneOffset: number = -((new Date()).getTimezoneOffset() * 60 * 1000);
    public static serverTimeZone: TimeZone = null;
    public static timeZones: Dictionary<TimeZone> = {};

    private static time: Date = null;
    private static timeUpdateInterval = null;

    public constructor(private ngZone: NgZone, private requestService: RequestService) {
        TimeService.setDefaultTimeZone();
    };

    public static getDateTime() {
        return new Date(TimeService.time);
    };

    public static getServerTimeZoneOffset() {
        return DateUtilities.getTimeZoneOffset(TimeService.time, TimeService.serverTimeZone.ianaName);
    };

    public static setDefaultTimeZone() {
        const date = new Date();
        const ianaName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        TimeService.serverTimeZone = {
            ianaName: ianaName,
            daylightAbbreviatedName: DateUtilities.getTimeZoneAbbreviatedName(date, ianaName)
        };
    };

    public ngOnDestroy() {
        clearInterval(TimeService.timeUpdateInterval);
    };

    public addFocusEvent(): void {
        window.onfocus = () => this.getSystemTime();
    };

    public removeFocusEvent(): void {
        window.onfocus = null;
    };

    public getServerTimeZoneObservable() {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.TIMEZONE_SERVER).pipe(
            catchError(() => of({ id: null })),
            map((timeZone: { id: number }) => {
                const serverTimeZone = TimeService.timeZones[timeZone.id];
                if (serverTimeZone) {
                    TimeService.serverTimeZone = serverTimeZone;
                };
                return TimeService.serverTimeZone;
            })
        );
    };

    public getSystemTime() {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.SYSTEM_TIME).subscribe((time) => {
            clearInterval(TimeService.timeUpdateInterval);
            this.setTime(time);
            this.ngZone.runOutsideAngular(() => {
                TimeService.timeUpdateInterval = setInterval(() => {
                    this.tick();
                }, 1000);
            });
        });
    };

    public getTimeZonesObservable() {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.TIMEZONES).pipe(
            catchError(() => of([])),
            map((timeZones: Array<TimeZone>) => TimeService.timeZones = ObjectUtilities.toDictionary(timeZones, 'id'))
        );
    };

    private setTime(time: string) {
        TimeService.time = new Date(time);
    };

    private tick() {
        TimeService.time.setSeconds(TimeService.time.getSeconds() + 1);
    };
};