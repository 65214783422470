import { Component } from '@angular/core';
import { Endpoints } from '../shared/endpoints';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { RequestService } from 'app/shared/services/request.service';

@Component({
    selector: 'login',
    styleUrls: ['login.component.css'],
    templateUrl: 'login.component.html'
})

export class LoginComponent {
    public _LOGIN_ELEMENT_IDS = {
        apiUrl: 'apiUrlInput',
        password: 'passwordInput',
        username: 'usernameInput'
    };
    public authenticated = false;
    public loginForm = {};
    public loggingIn = false;
    public model = {
        credentials: {
            apiUrl: 'server1-000016.ghostapi.net:29003',
            username: null,
            password: null
        },
        error: null
    };

    private _API_URL = '';
    private _LOGIN_URL = '';
    private attemptingAutoLogin = false;

    public constructor(private authenticationService: AuthenticationService, private requestService: RequestService) {
        var accessParameter = this.getUrlParameter('access', location.href);
        if (accessParameter) {
            try {
                this.attemptingAutoLogin = true;
                //var decodedAccessDetails = JSON.parse(atob(decodeURIComponent(accessParameter)));
                //Endpoints.API_URL = decodedAccessDetails.url;
                /*this.authenticationService.authenticateUser({
                    secret: decodedAccessDetails.token,
                    user: decodedAccessDetails.user
                });*/
                const decodedAccessDetails = JSON.parse(window.atob(decodeURIComponent(accessParameter)));
                this.authenticationService.authenticateUser(decodedAccessDetails.url, decodedAccessDetails.token);
                this.authenticated = true;
            } catch (error) {
                console.error(error);
            };
        };
    };

    public ngAfterViewInit() {
        if (!this.attemptingAutoLogin) {
            document.getElementById(this._LOGIN_ELEMENT_IDS.username).focus();
        };
    };

    public login(engineer?: boolean): void {
        this._API_URL = `https://${this.model.credentials.apiUrl}`;
        this._LOGIN_URL = this._API_URL + Endpoints.AUTHENTICATE;
        this.loggingIn = true;
        this.model.error = null;
        this.requestService.post(this._LOGIN_URL, Object.assign({ engineer: engineer ? engineer : undefined }, this.model.credentials)).pipe(first()).subscribe(this.onLoginSuccess, this.onLoginFailure);
    };

    public onKeyDown($event: KeyboardEvent) {
        if ($event.which === 13 && (<HTMLInputElement>$event.target).id === this._LOGIN_ELEMENT_IDS.password && this.isValidModel()) {
            this.login($event.ctrlKey);
        };
    };

    public isValidModel() {
        return this.model.credentials.apiUrl && this.model.credentials.username && this.model.credentials.password;
    };

    private getUrlParameter(key: string, url: string) {
        key = key.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regex = new RegExp("[\\?&]" + key + "=([^&#]*)");
        var results = regex.exec(url);
        return results == null ? null : results[1];
    };

    private onLoginFailure = (error): void => {
        if (error && error.status && error.status === 401) {
            this.model.error = 'Incorrect username or password';
        } else if (error && error.error.message) {
            this.model.error = error.error.message;
        } else if (error && error.message) {
            this.model.error = error.message;
        } else {
            this.model.error = 'Server is Unavailable';
        };
        this.loggingIn = false;
        setTimeout(() => {
            document.getElementById(this._LOGIN_ELEMENT_IDS.password).focus();
        });
    };

    private onLoginSuccess = (res): void => {
        //Endpoints.API_URL = this._API_URL;
        this.authenticated = true;
        this.authenticationService.authenticateUser(this._API_URL, res.secret);
    };
};