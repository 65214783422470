import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Endpoints } from '../endpoints';
import { ChangeEvent } from '../models/change-event.model';
import { SystemDetailsApi } from '../models/system-details.model';
import { RequestService } from './request.service';

@Injectable()
export class SystemDetailsService {
    public static DatePipe = new DatePipe('en-GB');
    public static LanguageCode: string = 'en-GB';
    public static ShortDateFormat: string = 'dd/MM';
    public static isUSDate: boolean = false;
    public static formatDate = (date: string | number | Date, format: string, timezone?: string) => SystemDetailsService.DatePipe.transform(date, format, timezone);

    public systemDetails: SystemDetailsApi;
    public systemDetailsUpdateSubject = new Subject<SystemDetailsApi>();

    public constructor(private requestService: RequestService) { };

    public getLanguageCode() {
        return SystemDetailsService.LanguageCode;
    };

    public getSystemDetailsObservable(onSuccess?: (details?: SystemDetailsApi) => void): Observable<SystemDetailsApi> {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.SYSTEM_DETAILS).pipe(
            catchError(() => of(null)),
            map((systemDetails: SystemDetailsApi) => {
                this.setSystemDetails(systemDetails);
                onSuccess && onSuccess(this.systemDetails);
                return this.systemDetails;
            })
        );
    };

    public processUpdateFromSignalR = (event: ChangeEvent<SystemDetailsApi>) => {
        this.systemDetailsUpdateSubject.next(this.setSystemDetails(event.data));
    };

    public setSystemDetails(systemDetails: SystemDetailsApi): SystemDetailsApi {
        this.systemDetails = new SystemDetailsApi(systemDetails);
        SystemDetailsService.LanguageCode = this.systemDetails.cultureInfo?.name || 'en-GB';
        SystemDetailsService.DatePipe = new DatePipe(SystemDetailsService.LanguageCode);
        SystemDetailsService.isUSDate = this.systemDetails.cultureInfo?.country === 'US';
        SystemDetailsService.ShortDateFormat = SystemDetailsService.isUSDate ? 'MM/dd' : 'dd/MM';
        return this.systemDetails;
    };
};