import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Endpoints } from '../endpoints';
import { ChangeEvent, ChangeEventType } from '../models/change-event.model';
import { UserApi, UserView } from '../models/user.model';
import { WhoAmI } from '../models/who-am-i.model';
import { RequestService } from './request.service';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { UserGroupService } from './user-group.service';

@Injectable()
export class UserService {
    public static currentUser: UserApi = new UserApi();
    public static currentUserImageStyleUrl: string = '';
    public static currentUserUpdateSubject = new Subject<UserView>();
    public static defaultUserImageStyleUrl: string = 'url("./shared/assets/images/profile-placeholder.png")';
    public static getCurrentUserImage: () => Observable<string>;

    public constructor(private requestService: RequestService, private userGroupService: UserGroupService) {
        UserService.getCurrentUserImage = this.getCurrentUserImage;
    };

    public getCurentUserAndUserGroupDataObservable() {
        /*return this.getCurrentUserObservable().pipe(mergeMap(() => {
            return this.userGroupService.getCurrentUserGroupByIdObservable(UserService.currentUser.userGroupId);
        }));*/
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.USERS._WHO_AM_I).pipe(tap((data: WhoAmI) => {
            UserService.currentUser = new UserApi(data.user);
            SentryErrorHandler.configureScope(data.user.id);
        }));
    };

    public getCurrentUserImage = () => {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.USERS._USERS + UserService.currentUser.id + Endpoints.USERS._PICTURE, {
            responseType: 'text'
        }).pipe(map((response: string) => UserService.currentUserImageStyleUrl = `url("data:image/jpeg;base64,${response}")`));
    };

    /*public getCurrentUserObservable(): Observable<void> {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.USERS._USERS + SessionService.getSessionUserId()).pipe(
            map((user: UserApi) => {
                UserService.currentUser = new UserView(user);
                SentryErrorHandler.configureScope(UserService.currentUser);
            })
        );
    };*/

    public processUpdateFromSignalR = (event: ChangeEvent<UserApi>) => {
        if (event.changeType.toLowerCase() === ChangeEventType.update && event.data.id === UserService.currentUser.id) {
            UserService.currentUser = new UserView(event.data);
            //UserService.currentUserUpdateSubject.next(UserService.currentUser);
        };
    };
};