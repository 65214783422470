import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Endpoints } from "../endpoints";
import { AnalyticsDashboard } from "../interfaces/analytics-dashboard-group.interface";
import { RequestService } from "./request.service";

@Injectable()
export class AnalyticsService {
    public constructor(private requestService: RequestService, private sanitizer: DomSanitizer) { };

    public getDashboardToken(dashboard: AnalyticsDashboard) {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.ANALYTICS_TOKENS).pipe(
            catchError((e) => {
                console.log('Cannot get tableau token', e);
                return of(null);
            }),
            map((response: { token: string }) => {
                return response ? 'https://tableau.autocab.net/trusted/' + response.token + '/views/' + dashboard.location + '?&:tabs=no&:toolbar=no&:showShareOptions=no&:embed=yes&:render:true' : null;
            })
        );
    };
};