import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SystemDetailsService } from "./system-details.service";

@Injectable()
export class GhostTranslationService {
    public static instant: (key: string | Array<string>, interpolateParams?: Object) => string | any;

    public constructor(
        private http: HttpClient,
        private translateService: TranslateService
    ) {
        GhostTranslationService.instant = (key: string | Array<string>, interpolateParams?: Object) => {
            return this.translateService.instant(key, interpolateParams);
        };
    };

    public setSystemLanguageTranslation(lang?: string) {
        const languageCode = lang ? lang : SystemDetailsService.LanguageCode;
        return this.http.get(location.origin + location.pathname + `./shared/assets/i18n/${languageCode}.json`).pipe(
            catchError(() => of(null)),
            tap(translations => {
                this.translateService.setTranslation(languageCode, translations);
                this.translateService.use(languageCode);
            })
        );
    };

    public setDefaultLanguageTranslation() {
        return this.http.get(location.origin + location.pathname + './shared/assets/i18n/en-GB.json').pipe(
            catchError(() => of(null)),
            tap(translations => {
                this.translateService.setTranslation('en-GB', translations);
                this.translateService.setDefaultLang('en-GB');
            })
        );
    };
};