import { OrderBy } from "../models/order-by.model";
import { ObjectUtilities } from "./object.utilities";

export class ArrayUtilities {
    public static compareValues(item1, item2, insensitive) {
        return insensitive ? ('' + item1).toLowerCase() === ('' + item2).toLowerCase() : item1 === item2;
    };

    public static filterMultipleProperties(array: Array<any>, props: Array<string>, string: string, limit?: number, orderBy?: Array<OrderBy>) {
        if (string) {
            let propsLen = props ? props.length : 0;

            if (propsLen) {
                let filterString = string.toLowerCase();
                let final = [];
                let results: Array<{ contains: Array<any>; startsWith: Array<any> }> = [];

                for (let i = 0; i < propsLen; i++) {
                    results[i] = { contains: [], startsWith: [] };
                };

                for (let i = 0; i < array.length; i++) {
                    let item = array[i];

                    for (let j = 0; j < props.length; j++) {
                        let val = ObjectUtilities.deepAccessUsingString(item, props[j]);

                        if (val || val === 0) {
                            val = val.toString().toLowerCase() as string;

                            if (val.startsWith(filterString)) {
                                results[j].startsWith.push(item);
                                break;
                            } else if (val.indexOf(filterString) !== -1) {
                                results[j].contains.push(item);
                                break;
                            };
                        };
                    };
                };

                for (let i = 0; i < propsLen; i++) {
                    if (orderBy) {
                        results[i].startsWith.sort(ArrayUtilities.compareFn(orderBy));
                        results[i].contains.sort(ArrayUtilities.compareFn(orderBy));
                    };

                    final = final.concat(results[i].startsWith, results[i].contains);

                    if (limit && final.length >= limit) {
                        return final.slice(0, limit);
                    };
                };

                return limit ? final.slice(0, limit) : final;
            };
        };

        if (orderBy) {
            array.sort(ArrayUtilities.compareFn(orderBy));
        };

        return limit ? array.slice(0, limit) : array;
    };

    public static compareFn(orderByFormatted: Array<OrderBy>, insensitive?: boolean) {
        const keysLen = orderByFormatted.length;
        return (a, b) => {
            let sorted = 0;
            let ix = 0;
            while (sorted === 0 && ix < keysLen) {
                if (orderByFormatted[ix]) {
                    let aVal = ObjectUtilities.deepAccessUsingString(a, orderByFormatted[ix].property);
                    let bVal = ObjectUtilities.deepAccessUsingString(b, orderByFormatted[ix].property);
                    sorted = orderByFormatted[ix].natural ? ArrayUtilities.naturalSort(insensitive)(aVal, bVal) * orderByFormatted[ix].direction : ArrayUtilities.keySort(aVal, bVal, orderByFormatted[ix].direction);
                    ix++;
                };
            };
            return sorted;
        };
    };

    public static getInsertionIndex = <T>(arr: Array<T>, item: T, compareFn: (a: T, b: T) => number) => {
        const itemsCount = arr.length;

        if (itemsCount === 0) {
            return 0;
        };

        const lastItem = arr[itemsCount - 1];

        if (compareFn(item, lastItem) >= 0) {
            return itemsCount;
        };

        const getMidPoint = (start, end) => Math.floor((end - start) / 2) + start;
        let start = 0;
        let end = itemsCount - 1;
        let index = getMidPoint(start, end);;

        while (start < end) {
            const curItem = arr[index];
            const comparison = compareFn(item, curItem);

            if (comparison === 0) {
                break;
            } else if (comparison < 0) {
                end = index;
            } else {
                start = index + 1;
            };
            index = getMidPoint(start, end);
        };

        return index;
    };

    public static keySort = (a, b, d) => {
        d = d !== null ? d : 1;
        // a = a.toLowerCase(); // this breaks numbers
        // b = b.toLowerCase();
        if (b instanceof Date) {
            a = (<Date>a).getTime();
            b = (<Date>b).getTime();
        };
        if (a === b) {
            return 0;
        };
        return a > b ? 1 * d : -1 * d;
    };

    public static last = <T>(array: Array<T>) => {
        if (array?.length) {
            return array[array.length - 1];
        };
        return null;
    };

    public static getReversed = <T>(collection: Array<T>) => {
        let reversed = [];

        if (collection && collection.length) {
            for (let i = collection.length - 1; i > -1; i--) {
                if (collection[i]) {
                    reversed.push(collection[i]);
                };
            };
        };

        return reversed;
    };

    public static naturalSort(insensitive?: boolean) {
        const ore = /^0/;
        const sre = /\s+/g;
        const tre = /^\s+|\s+$/g;
        // unicode
        const ure = /[^\x00-\x80]/;
        // hex
        const hre = /^0x[0-9a-f]+$/i;
        // numeric
        const nre = /(0x[\da-fA-F]+|(^[\+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|\d+)/g;
        // datetime
        const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/; // tslint:disable-line
        const toLowerCase = String.prototype.toLocaleLowerCase || String.prototype.toLowerCase;
        const normalize = insensitive ? (s: string | number) => toLowerCase.call(`${s}`).replace(tre, '') : (s: string | number) => (`${s}`).replace(tre, '');
        const tokenize = (s: string): string[] => s.replace(nre, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
        const parse = (s: string, l: number) => (!s.match(ore) || l === 1) && parseFloat(s) || s.replace(sre, ' ').replace(tre, '') || 0;

        return (a: string | number, b: string | number): number => {
            // trim pre-post whitespace
            const aa = normalize(a);
            const bb = normalize(b);

            // return immediately if at least one of the values is empty.
            // empty string < any others
            if (!aa && !bb) return 0;
            if (!aa && bb) return -1;
            if (aa && !bb) return 1;

            // tokenize: split numeric strings and default strings
            const aArr = tokenize(aa);
            const bArr = tokenize(bb);

            // hex or date detection
            const aHex = aa.match(hre);
            const bHex = bb.match(hre);
            const av = (aHex && bHex) ? parseInt(aHex[0], 16) : (aArr.length !== 1 && Date.parse(aa));
            const bv = (aHex && bHex) ? parseInt(bHex[0], 16) : av && bb.match(dre) && Date.parse(bb) || null;

            // try and sort Hex codes or Dates
            if (bv) {
                if (av === bv) return 0;
                if (av < bv) return -1;
                if (av > bv) return 1;
            };

            const al = aArr.length;
            const bl = bArr.length;

            // handle numeric strings and default strings
            for (let i = 0, l = Math.max(al, bl); i < l; i += 1) {
                const af = parse(aArr[i] || '', al);
                const bf = parse(bArr[i] || '', bl);

                // handle numeric vs string comparison.
                // numeric < string
                if (isNaN(af as number) !== isNaN(bf as number)) {
                    return isNaN(af as number) ? 1 : -1;
                };

                // if unicode use locale comparison
                if (ure.test((af as string) + (bf as string)) && (af as string).localeCompare) {
                    const comp = (af as string).localeCompare(bf as string);
                    if (comp > 0) return 1;
                    if (comp < 0) return -1;
                    if (i === l - 1) return 0;
                };

                if (af < bf) return -1;
                if (af > bf) return 1;
                if (`${af}` < `${bf}`) return -1;
                if (`${af}` > `${bf}`) return 1;
            };

            return 0
        };
    };

    public static sortAlphabetically(array: Array<any>, prop) {
        return array.sort((a, b) => {
            let nameA = a[prop].toLowerCase();
            let nameB = b[prop].toLowerCase();
            if (nameA < nameB) {
                return -1;
            };
            if (nameA > nameB) {
                return 1;
            };
            return 0;
        });
    };

    public static moveElementUp(array: Array<any>, index: number) {
        return this.moveElement(array, index, true);
    };

    public static moveElementDown(array: Array<any>, index: number) {
        return this.moveElement(array, index, false);
    };

    private static moveElement(array: Array<any>, index: number, up: boolean) {
        return [
            ...array.slice(0, index - 1 + (up ? 0 : 1)),
            array[index + (up ? 0 : 1)],
            array[index - 1 + (up ? 0 : 1)],
            ...array.slice(index + 1 + (up ? 0 : 1)),
        ];
    };
};