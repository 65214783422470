export class CoordinateApi {
    public latitude: number;
    public longitude: number;

    public constructor(item?: CoordinateApi) {
        this.latitude = (item && typeof item.latitude == 'number') ? item.latitude : null;
        this.longitude = (item && typeof item.longitude == 'number') ? item.longitude : null;
    };
};

export class CoordinateView {
    public latitude: number = 0;
    public longitude: number = 0;

    public constructor(item?: CoordinateView) {
        if (item) {
            item.latitude !== undefined && (this.latitude = item.latitude);
            item.longitude !== undefined && (this.longitude = item.longitude);
        };
    };
};