export enum UITimeFormat {
    Long,
    LongDecreasing,
    Short,
    Minutes,
    Seconds,
    MinutesAndSeconds,
};

export class DateUtilities {
    public static MinValue = '0001-01-01T00:00:00+00:00';

    public static addDays = (date: string | Date, daysToAdd: number) => {
        const d = new Date(date);
        d.setDate(d.getDate() + daysToAdd);
        return d;
    };

    public static compare = (date1: string, date2: string) => {
        const t1 = Date.parse(date1);
        const t2 = Date.parse(date2);
        return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
    };

    public static dateInTimeZone = (date: number | string | Date, timeZone: string) => {
        return new Date((new Date(date)).toLocaleString('en-US', { timeZone: timeZone }));
    };

    public static formatApiTimeString(time: string, timeFormat: UITimeFormat) {
        let timeChunks = time.split(':');
        let hours = timeChunks[0];
        let minutes = timeChunks[1];
        let seconds = timeChunks[2];

        if (seconds && seconds.length) {
            seconds = seconds.slice(0, 2);
        };

        const h = hours + 'h ';
        const m = minutes + 'm ';
        const s = seconds + 's';

        switch (timeFormat) {
            case UITimeFormat.Short:
                return hours + 'h ' + minutes + 'm ';
            case UITimeFormat.Long:
                return hours + 'h ' + minutes + 'm ' + seconds + 's';
            case UITimeFormat.LongDecreasing:
                if (hours) {
                    return h + m + s;
                } else if (minutes) {
                    return m + s;
                };
                return s;
            case UITimeFormat.Minutes:
                return minutes + 'm';
            case UITimeFormat.Seconds:
                return seconds + 's';
            case UITimeFormat.MinutesAndSeconds:
                return minutes + 'm ' + seconds + 's';
            default:
                return '';
        };
    };

    public static formatMillisecondsToTimeString(milliseconds: number, timeFormat: UITimeFormat) {
        let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
        milliseconds -= days * (1000 * 60 * 60 * 24);
        let hours = Math.floor(milliseconds / (1000 * 60 * 60));
        milliseconds -= hours * (1000 * 60 * 60);
        let minutes = Math.floor(milliseconds / (1000 * 60));
        milliseconds -= minutes * (1000 * 60);
        let seconds = Math.floor(milliseconds / (1000));
        milliseconds -= seconds * (1000);
        const h = hours + 'h ';
        const m = minutes + 'm ';
        const s = seconds + 's';

        switch (timeFormat) {
            case UITimeFormat.Short:
                return hours + 'h ' + minutes + 'm ';
            case UITimeFormat.Long:
                return hours + 'h ' + minutes + 'm ' + seconds + 's';
            case UITimeFormat.LongDecreasing:
                if (hours) {
                    return h + m + s;
                } else if (minutes) {
                    return m + s;
                };
                return s;
            case UITimeFormat.Minutes:
                return minutes + 'm';
            case UITimeFormat.Seconds:
                return seconds + 's';
            case UITimeFormat.MinutesAndSeconds:
                return minutes + 'm ' + seconds + 's';
            default:
                return '';
        };
    };

    public static getDatesBetween = (startDate: string | Date, endDate: string | Date, fortnightly?: boolean) => {
        const dates: Array<Date> = [];
        const formatedEndDate = new Date(endDate);
        const isOddWeek = DateUtilities.getWeekNumber(formatedEndDate) % 2;
        let currentDate = new Date(startDate);

        while (currentDate <= formatedEndDate) {
            if (!fortnightly || (isOddWeek === (DateUtilities.getWeekNumber(currentDate) % 2))) {
                dates.push(currentDate);
            };
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
        };

        return dates;
    };

    public static getDatesDifference(startDate: Date, endDate: Date, timeFormat?: string): string {
        if (startDate && endDate && startDate < endDate) {
            const start: any = startDate.getTime();
            const end: any = endDate.getTime();
            let timeDifference = null;
            let delta = Math.abs(end - start) / 1000;

            const days = Math.floor(delta / 86400);
            delta -= days * 86400;

            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            const seconds = Math.floor(delta % 60);

            let daysString = days ? (days + 'd ') : '';
            let hoursString = (hours || daysString && hours === 0) ? (hours + 'h ') : '';
            let minutesString = (minutes || hoursString && minutes === 0) ? (minutes + 'm ') : '';
            let secondsString = (seconds || minutesString && seconds === 0) ? (seconds + 's') : '';

            timeDifference = daysString + hoursString + minutesString + secondsString;

            if (timeFormat && timeFormat === 'HH:mm') {
                let allHours = days ? (days * 24) + hours : hours;
                hoursString = (allHours && allHours > 9) ? allHours.toString() : '0' + allHours;
                minutesString = (minutes && minutes > 9) ? minutes.toString() : '0' + minutes;
                timeDifference = hoursString + ':' + minutesString;
            };

            if (timeFormat && timeFormat === 'dd hh mm') {
                daysString = days ? daysString : '';
                hoursString = hours ? hoursString : '';
                minutesString = minutes ? minutesString : '';
                timeDifference = daysString + hoursString + minutesString;
            };

            return timeDifference;
        } else {
            return (timeFormat && timeFormat === 'HH:mm') ? '00:00' : '0s';
        };
    };

    public static getDatesDifferenceApi(startDate: Date, endDate: Date, timeFormat?: UITimeFormat): string {
        let time = '00:00:00';

        if (startDate && endDate && startDate < endDate) {
            const start: any = startDate.getTime();
            const end: any = endDate.getTime();
            let delta = Math.abs(end - start) / 1000;

            const hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            const minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            const seconds = Math.floor(delta % 60);

            let hoursString = hours.toString();
            if (hoursString.length === 1) {
                hoursString = '0' + hoursString;
            };
            let minutesString = minutes.toString();
            if (minutesString.length === 1) {
                minutesString = '0' + minutesString;
            };
            let secondsString = seconds.toString();
            if (secondsString.length === 1) {
                secondsString = '0' + secondsString;
            };

            time = hoursString + ':' + minutesString + ':' + secondsString;
        };

        return timeFormat !== undefined ? DateUtilities.formatApiTimeString(time, timeFormat) : time;
    };

    public static getDatesDifferenceInDays(startDate: Date, endDate: Date) {
        if (startDate < endDate) {
            return (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
        };
        return 0;
    };

    public static getDatesDifferenceInSeconds(startDate: Date, endDate: Date) {
        if (startDate < endDate) {
            return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
        };
        return 0;
    };

    public static getDaysInMonth(date?: Date, month?: number, year?: number) {
        if (date) {
            year = date.getFullYear();
            month = date.getMonth() + 1;
        };
        return new Date(year, month, 0).getDate();
    };

    public static getMillennium(date?: Date): number {
        let dateToCheck = date || new Date();
        return Math.round(dateToCheck.getFullYear() / 1000) * 1000;
    };

    public static getStartOfWeek = (date: string | Date, setToMidnight?: boolean) => {
        const dateCopy = new Date(date);
        if (setToMidnight) {
            dateCopy.setHours(0, 0, 0, 0);
        };
        const day = dateCopy.getDay();
        const diff = dateCopy.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(dateCopy.setDate(diff));
    };

    public static getStringDate(time: string, daysToAdd?: number) {
        let date = new Date();
        daysToAdd && date.setDate(date.getDate() + daysToAdd);
        DateUtilities.setTimeOfDate(date, time);
        date.setMilliseconds(0);

        return date.toJSON();
    };

    public static getShortDate(date: string | Date) {
        let _date = date instanceof Date ? date : new Date(date);
        let year = ('00' + (_date.getFullYear())).slice(-4);
        let month = ('0' + (_date.getMonth() + 1)).slice(-2);
        let day = ('0' + (_date.getDate())).slice(-2);

        return year + '-' + month + '-' + day;
    };

    public static getTime(date: string | Date, getSeconds?: boolean, getMilliseconds?: boolean) {
        let _date = date instanceof Date ? date : new Date(date);
        let hours = ('0' + _date.getHours()).slice(-2);
        let mins = ('0' + _date.getMinutes()).slice(-2);
        let time = `${hours}:${mins}`;

        if (getSeconds) {
            time += ':' + ('0' + _date.getSeconds()).slice(-2);
        };
        if (getMilliseconds) {
            time += ':' + ('00' + _date.getMilliseconds()).slice(-3);
        };

        return time;
    };

    public static getTimeZoneAbbreviatedName = (date: Date, timeZoneIana: string) => {
        return date.toLocaleString('en', { timeZone: timeZoneIana, timeZoneName: 'short' }).split(' ').pop();
    };

    public static getTimeZoneOffset = (date: number | string | Date, timeZoneIana: string) => {
        const d = new Date(date);
        const tz = DateUtilities.getTimeZoneAbbreviatedName(d, timeZoneIana);
        const dateString = d.toString();
        return Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
    };

    public static getTimeZoneOffsetString = (date: number | string | Date, timeZoneIana: string, offset?: number) => {
        const tzOffset = offset ?? DateUtilities.getTimeZoneOffset(date, timeZoneIana);
        const tzo = tzOffset / (60 * 1000);
        const dif = tzo >= 0 ? '+' : '-';
        const pad = (num: number) => {
            let norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
    };

    public static getISODateString(date: string | number | Date, ianaName: string, offset?: number) {
        const d = new Date(date);
        const tz = DateUtilities.getTimeZoneOffsetString(date, ianaName, offset);
        return d.getUTCFullYear() + '-'
            + DateUtilities.pad(d.getUTCMonth() + 1) + '-'
            + DateUtilities.pad(d.getUTCDate()) + 'T'
            + DateUtilities.pad(d.getUTCHours()) + ':'
            + DateUtilities.pad(d.getUTCMinutes()) + ':'
            + DateUtilities.pad(d.getUTCSeconds()) + tz;
    };

    public static getUTCTimestamp(date: Date) {
        return date.getTime() - DateUtilities.getTimeZoneOffset(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
    };

    public static getWeekNumber = (date: Date) => {
        let formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        let dayNum = formattedDate.getUTCDay() || 7;
        formattedDate.setUTCDate(formattedDate.getUTCDate() + 4 - dayNum);
        let yearStart = new Date(Date.UTC(formattedDate.getUTCFullYear(), 0, 1));
        return Math.ceil((((formattedDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
    };

    public static hoursToMilliseconds(hours: number) {
        if (hours) {
            return hours * 60 * 60 * 1000;
        };
        return 0;
    };

    public static isDateBeforeAnotherDate(date1: string | Date, date2: string | Date, ignoreSeconds?: boolean, ignoreMilliseconds?: boolean) {
        if (!date1 || !date2) return false;
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        if (ignoreSeconds) {
            d1.setSeconds(0);
            d2.setSeconds(0);
        };
        if (ignoreMilliseconds) {
            d1.setMilliseconds(0);
            d2.setMilliseconds(0);
        };
        return d1.getTime() < d2.getTime();
    };

    public static isInDST = (date: string | Date, timeZoneIana: string) => {
        let d = new Date(date);
        let offset = DateUtilities.getTimeZoneOffset(date, timeZoneIana);
        return offset > DateUtilities.getTimeZoneOffset(new Date(d.getFullYear(), 0, 1), timeZoneIana) || offset > DateUtilities.getTimeZoneOffset(new Date(d.getFullYear(), 5, 1), timeZoneIana);
    };

    public static isMinValue = (date: string) => {
        return date === DateUtilities.MinValue;
    };

    public static isSameDay(date1: string | Date, date2: string | Date) {
        let _date1 = new Date(date1);
        let _date2 = new Date(date2);

        return _date1.getFullYear() === _date2.getFullYear() && _date1.getMonth() === _date2.getMonth() && _date1.getDate() === _date2.getDate();
    };

    public static isValidDate(date: Date) {
        if (!date || !date.getTime) {
            return false;
        };
        return !isNaN(date.getTime());
    };

    public static minutesToMilliseconds(minutes: number) {
        if (minutes) {
            return minutes * 60 * 1000;
        };
        return 0;
    };

    public static mergeDateAndTimeOfTwoDates(date: Date | string, time: Date | string, timeZoneIana?: string) {
        const _date = new Date(date);
        const _time = new Date(time);
        const newDate = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), _time.getHours(), _time.getMinutes(), _time.getSeconds());
        if (timeZoneIana) {
            const offset = DateUtilities.getTimeZoneOffset(newDate, timeZoneIana);
            return new Date(newDate.getTime() + offset);
        };
        return newDate;
    };

    public static mergeDateAndTimeOfTwoDatesAsISOString(date: Date | string, time: Date | string, ianaName: string) {
        return DateUtilities.getISODateString(DateUtilities.mergeDateAndTimeOfTwoDates(date, time, ianaName), ianaName);
    };

    public static pad = (n: number) => {
        return n < 10 ? '0' + n : n;
    };

    public static setTimeOfDate(date: Date, time: string) {
        let timeCopy = time ? time : '00:00:00';
        let hours: Array<number> = timeCopy.split(':').map(item => parseInt(item));
        if (hours && hours.length) {
            date.setHours(hours[0] || 0, hours[1] || 0, hours[2] || 0);
        };
    };
};