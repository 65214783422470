import { Component, OnDestroy } from '@angular/core';
import { LoadingService } from 'app/shared/services/loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'loading',
    styleUrls: ['loading.component.css'],
    templateUrl: 'loading.component.html'
})

export class LoadingComponent implements OnDestroy {
    public description = 'cache.loading.message.loading_app_data';
    public progress = 0;

    private loadingSubscription: Subscription = null;

    public constructor(private loadingService: LoadingService) {
        this.loadingSubscription = this.loadingService.onAppDataLoadigChange().subscribe((change) => {
            this.description = change.description;
            this.progress = change.progress;
        })
    };

    public ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
        this.loadingService.resetProgress();
    };
};
