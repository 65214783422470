import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ConnectionTransports, SignalRModule } from '@autocab/ghost-signalr';
import {
    NgbButtonsModule, NgbProgressbarModule
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from 'app/loading/loading.component';
import { LoginComponent } from 'app/login/login.component';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { AnalyticsRouteGuardService } from 'app/shared/services/analytics-route-guard.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { CacheLoaderService } from 'app/shared/services/cache-loader.service';
import { GhostTranslationService } from 'app/shared/services/ghost-translation.service';
import { LoadingService } from 'app/shared/services/loading.service';
import { OverlayService } from 'app/shared/services/overlay.service';
import { PermissionsService } from 'app/shared/services/permissions.service';
import { RequestService } from 'app/shared/services/request.service';
import { ResponseErrorService } from 'app/shared/services/response-error.service';
import { SessionService } from 'app/shared/services/session.service';
import { SettingsService } from 'app/shared/services/settings.service';
import { SignalRConnectionService } from 'app/shared/services/signalr-connection.service';
import { StorageService } from 'app/shared/services/storage.service';
import { SystemDetailsService } from 'app/shared/services/system-details.service';
import { TimeService } from 'app/shared/services/time.service';
import { UserGroupService } from 'app/shared/services/user-group.service';
import { UserService } from 'app/shared/services/user.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocaleModule } from './shared/modules/locale/locale.module';

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        DirectivesModule,
        FormsModule,
        LocaleModule,
        HttpClientModule,
        NgbButtonsModule,
        NgbProgressbarModule,
        ObserversModule,
        OverlayModule,
        RouterModule,
        SignalRModule.forRoot({
            executeEventsInZone: true,
            hubName: 'messageHub',
            logging: false,
            qs: { 'token': SessionService.getToken() || "" },
            transport: [ConnectionTransports.webSockets]
        }),
        TranslateModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoadingComponent,
        LoginComponent
    ],
    providers: [
        { provide: LOCALE_ID, deps: [SystemDetailsService], useFactory: (systemDetailsService: SystemDetailsService) => systemDetailsService.getLanguageCode() },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        //{ provide: ErrorHandler, useClass: SentryErrorHandler },
        AnalyticsService,
        AuthenticationService,
        CacheLoaderService,
        GhostTranslationService,
        LoadingService,
        OverlayService,
        PermissionsService,
        RequestService,
        ResponseErrorService,
        AnalyticsRouteGuardService,
        SessionService,
        SettingsService,
        SignalRConnectionService,
        StorageService,
        SystemDetailsService,
        TimeService,
        UserService,
        UserGroupService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { };
