import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { CacheLoaderService } from './cache-loader.service';

@Injectable()
export class AnalyticsRouteGuardService implements CanActivate {

    public constructor(private authenticationService: AuthenticationService, private cacheLoaderService: CacheLoaderService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (state.url !== '/login' && !this.authenticationService.authenticated) {
            this.authenticationService.unauthenticateUser();
            return false;
        };
        if (!this.cacheLoaderService.appCacheResolved) {
            return this.cacheLoaderService.fetchAppData();
        };
        return true;
    };
};