export enum CliPhoneTypes {
    Disabled = 'Disabled',
    External = 'External',
    Integrated = 'Integrated'
};

export interface AuthenticationResponse {
    secret: string;
    user: AuthenticationUserPartial;
};

export interface AuthenticationUserPartial {
    id: number;
    name: string;
};

export interface DecodedJwtToken {
    aud: number;
    cId: string;
    engineer: boolean;
    exp: number;
    exp_time: string;
    iss: string;
};

export class Autocab365Session {
    public apiUrl: string;
    public cli: SessionCli;
    //public engineer: boolean;
    public token: string;
    //public user: SessionUser;

    public constructor(item?: Autocab365Session) {
        this.apiUrl = (item && item.apiUrl) ? item.apiUrl : '';
        this.cli = (item && item.cli) ? item.cli : new SessionCli();
        //this.engineer = (item && item.engineer) ? true : false;
        this.token = (item && item.token) ? item.token : (item && item.token) ? item.token : '';
        //this.user = (item && item.user) ? new SessionUser(item.user) : null;
    };
};

export class SessionCli {
    public extension: number;
    public phoneType: CliPhoneTypes;

    public constructor(item?: SessionCli) {
        this.extension = (item && item.extension) ? item.extension : null;
        this.phoneType = (item && item.phoneType) ? item.phoneType : CliPhoneTypes.External;
    };
};

export class SessionUser {
    public id: number;
    public name: string;

    public constructor(item: SessionUser) {
        this.id = (item && item.id) ? item.id : null;
        this.name = (item && item.name) ? item.name : '';
    };
};