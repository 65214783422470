export class GlobalSearchActiveSourcesApi {
    public active: boolean;
    public advanced: boolean;
    public mobile: boolean;

    public constructor(item?: GlobalSearchActiveSourcesApi) {
        this.active = (item && item.active) ? true : false;
        this.advanced = (item && item.advanced) ? true : false;
        this.mobile = (item && item.mobile) ? true : false;
    };
};

export class GlobalSearchActiveSourcesView {
    public active: boolean = false;
    public advanced: boolean = false;
    public mobile: boolean = false;

    public constructor(item?: GlobalSearchActiveSourcesView) {
        if (item) {
            item.active && (this.active = true);
            item.advanced && (this.advanced = true);
            item.mobile && (this.mobile = true);
        };
    };
};