import { PostalAddressApi, PostalAddressView } from './address.model';

export class UserApi {
    public address: PostalAddressApi;
    public companyId: number;
    public dateOfBirth: string;
    public emailAddress: string;
    public endDate: string;
    public extensionNumber: string;
    public forename: string;
    public hasEndDate: boolean;
    public id: number;
    public isSuspended: boolean;
    public landline: string;
    public mobile: string;
    public nationalInsuranceNumber: string;
    public receiveOperatorMessage: boolean;
    public rowVersion: number;
    public ssoIdentity: string;
    public startDate: string;
    public surname: string;
    public userGroupId: number;
    public username: string;
    public webUICustomisation: string;

    public constructor(item?: UserApi | UserView) {
        if (item) {
            item.address && (this.address = new PostalAddressApi(item.address));
            item.companyId !== undefined && (this.companyId = item.companyId);
            item.dateOfBirth && (this.dateOfBirth = item.dateOfBirth);
            item.emailAddress && (this.emailAddress = item.emailAddress);
            item.endDate && (this.endDate = item.endDate);
            item.extensionNumber && (this.extensionNumber = item.extensionNumber);
            item.forename && (this.forename = item.forename);
            item.hasEndDate !== undefined && (this.hasEndDate = item.hasEndDate);
            item.id !== undefined && (this.id = item.id);
            item.isSuspended !== undefined && (this.isSuspended = item.isSuspended);
            item.landline && (this.landline = item.landline);
            item.mobile && (this.mobile = item.mobile);
            item.nationalInsuranceNumber && (this.nationalInsuranceNumber = item.nationalInsuranceNumber);
            item.receiveOperatorMessage !== undefined && (this.receiveOperatorMessage = item.receiveOperatorMessage);
            item.rowVersion !== undefined && (this.rowVersion = item.rowVersion);
            item.ssoIdentity && (this.ssoIdentity = item.ssoIdentity);
            item.startDate && (this.startDate = item.startDate);
            item.surname && (this.surname = item.surname);
            item.userGroupId !== undefined && (this.userGroupId = item.userGroupId);
            item.username && (this.username = item.username);
            item.webUICustomisation && (this.webUICustomisation = item.webUICustomisation);
        };
    };
};

export class UserView {
    public address: PostalAddressView = new PostalAddressView();
    public companyId: number = null;
    public dateOfBirth: string = '';
    public emailAddress: string = '';
    public endDate: string = '';
    public extensionNumber: string = '';
    public forename: string = '';
    public fullName: string = '';
    public hasEndDate: boolean = false;
    public id: number = null;
    public isSuspended: boolean = false;
    public landline: string = '';
    public mobile: string = '';
    public nationalInsuranceNumber: string = '';
    public receiveOperatorMessage: boolean = false;
    public rowVersion: number = null;
    public ssoIdentity: string = '';
    public startDate: string = '';
    public surname: string = '';
    public userGroupId: number = null;
    public username: string = '';
    public webUICustomisation: string = '';

    public constructor(item?: UserApi | UserView) {
        if (item) {
            item.address && (this.address = new PostalAddressView(item.address));
            item.companyId !== undefined && (this.companyId = item.companyId);
            item.dateOfBirth && (this.dateOfBirth = item.dateOfBirth);
            item.emailAddress && (this.emailAddress = item.emailAddress);
            item.endDate && (this.endDate = item.endDate);
            item.extensionNumber && (this.extensionNumber = item.extensionNumber);
            if (item.forename) {
                this.forename = item.forename;
                this.fullName = item.forename;
            };
            item.hasEndDate !== undefined && (this.hasEndDate = item.hasEndDate);
            item.id !== undefined && (this.id = item.id);
            item.isSuspended !== undefined && (this.isSuspended = item.isSuspended);
            item.landline && (this.landline = item.landline);
            item.mobile && (this.mobile = item.mobile);
            item.nationalInsuranceNumber && (this.nationalInsuranceNumber = item.nationalInsuranceNumber);
            item.receiveOperatorMessage !== undefined && (this.receiveOperatorMessage = item.receiveOperatorMessage);
            item.rowVersion !== undefined && (this.rowVersion = item.rowVersion);
            item.ssoIdentity && (this.ssoIdentity = item.ssoIdentity);
            item.startDate && (this.startDate = item.startDate);
            if (item.surname) {
                this.surname = item.surname;
                this.fullName = this.fullName ? this.fullName + ' ' + item.surname : item.surname;
            };
            item.userGroupId !== undefined && (this.userGroupId = item.userGroupId);
            item.username && (this.username = item.username);
            if (item.webUICustomisation) {
                this.webUICustomisation = item.webUICustomisation;
            };
        };
    };
};

export class UserRefApi {
    public id: number;
    public name: string;

    public constructor(item?: UserRefApi) {
        if (typeof item === "object") {
            item.id !== null && item.id !== undefined && (this.id = item.id);
            item.name && (this.name = item.name);
        };
    };
};

export class UserRefView {
    public id: number = null;
    public name: string = '';

    public constructor(item?: UserRefApi | UserRefView) {
        if (typeof item === "object") {
            item.id !== null && item.id !== undefined && (this.id = item.id);
            item.name && (this.name = item.name);
        };
    };
};