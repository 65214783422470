import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import locale_ar from '@angular/common/locales/ar';
import locale_bg from '@angular/common/locales/bg';
import locale_bn from '@angular/common/locales/bn';
import locale_ca from '@angular/common/locales/ca';
import locale_cs from '@angular/common/locales/cs';
import locale_da from '@angular/common/locales/da';
import locale_de from '@angular/common/locales/de';
import locale_el from '@angular/common/locales/el';
import locale_en from '@angular/common/locales/en';
import locale_en_AU from '@angular/common/locales/en-AU';
import locale_en_GB from '@angular/common/locales/en-GB';
import locale_en_IE from '@angular/common/locales/en-IE';
import locale_es from '@angular/common/locales/es';
import locale_eu from '@angular/common/locales/eu';
import locale_fa from '@angular/common/locales/fa';
import locale_fi from '@angular/common/locales/fi';
import locale_fil from '@angular/common/locales/fil';
import locale_fr from '@angular/common/locales/fr';
import locale_gl from '@angular/common/locales/gl';
import locale_gu from '@angular/common/locales/gu';
import locale_hi from '@angular/common/locales/hi';
import locale_hr from '@angular/common/locales/hr';
import locale_hu from '@angular/common/locales/hu';
import locale_id from '@angular/common/locales/id';
import locale_it from '@angular/common/locales/it';
import locale_iw from '@angular/common/locales/he';
import locale_ja from '@angular/common/locales/ja';
import locale_kn from '@angular/common/locales/kn';
import locale_ko from '@angular/common/locales/ko';
import locale_lt from '@angular/common/locales/lt';
import locale_lv from '@angular/common/locales/lv';
import locale_ml from '@angular/common/locales/ml';
import locale_mr from '@angular/common/locales/mr';
import locale_nl from '@angular/common/locales/nl';
import locale_nn from '@angular/common/locales/nn';
import locale_no from '@angular/common/locales/nb';
import locale_or from '@angular/common/locales/or';
import locale_pl from '@angular/common/locales/pl';
import locale_pt from '@angular/common/locales/pt';
import locale_pt_BR from '@angular/common/locales/pt';
import locale_pt_PT from '@angular/common/locales/pt-PT';
import locale_rm from '@angular/common/locales/rm';
import locale_ro from '@angular/common/locales/ro';
import locale_ru from '@angular/common/locales/ru';
import locale_sk from '@angular/common/locales/sk';
import locale_sl from '@angular/common/locales/sl';
import locale_sr from '@angular/common/locales/sr';
import locale_sv from '@angular/common/locales/sv';
import locale_tl from '@angular/common/locales/fil';
import locale_ta from '@angular/common/locales/ta';
import locale_te from '@angular/common/locales/te';
import locale_th from '@angular/common/locales/th';
import locale_tr from '@angular/common/locales/tr';
import locale_uk from '@angular/common/locales/uk';
import locale_vi from '@angular/common/locales/vi';
import locale_zh_CN from '@angular/common/locales/zh-Hans';
import locale_zh_TW from '@angular/common/locales/zh-Hant';

registerLocaleData(locale_ar);
registerLocaleData(locale_bg);
registerLocaleData(locale_bn);
registerLocaleData(locale_ca);
registerLocaleData(locale_cs);
registerLocaleData(locale_da);
registerLocaleData(locale_de);
registerLocaleData(locale_el);
registerLocaleData(locale_en);
registerLocaleData(locale_en_AU);
registerLocaleData(locale_en_GB);
registerLocaleData(locale_en_IE);
registerLocaleData(locale_es);
registerLocaleData(locale_eu);
registerLocaleData(locale_fa);
registerLocaleData(locale_fi);
registerLocaleData(locale_fil);
registerLocaleData(locale_fr);
registerLocaleData(locale_gl);
registerLocaleData(locale_gu);
registerLocaleData(locale_hi);
registerLocaleData(locale_hr);
registerLocaleData(locale_hu);
registerLocaleData(locale_id);
registerLocaleData(locale_it);
registerLocaleData(locale_iw, 'iw');
registerLocaleData(locale_ja);
registerLocaleData(locale_kn);
registerLocaleData(locale_ko);
registerLocaleData(locale_lt);
registerLocaleData(locale_lv);
registerLocaleData(locale_ml);
registerLocaleData(locale_mr);
registerLocaleData(locale_nl);
registerLocaleData(locale_nn);
registerLocaleData(locale_no, 'nb-NO');
registerLocaleData(locale_or);
registerLocaleData(locale_pl);
registerLocaleData(locale_pt);
registerLocaleData(locale_pt_BR, 'pt_BR');
registerLocaleData(locale_pt_PT, 'pt_PT');
registerLocaleData(locale_rm);
registerLocaleData(locale_ro);
registerLocaleData(locale_ru);
registerLocaleData(locale_sk);
registerLocaleData(locale_sl);
registerLocaleData(locale_sr);
registerLocaleData(locale_sv);
registerLocaleData(locale_tl, 'tl');
registerLocaleData(locale_ta);
registerLocaleData(locale_te);
registerLocaleData(locale_th);
registerLocaleData(locale_tr);
registerLocaleData(locale_uk);
registerLocaleData(locale_vi);
registerLocaleData(locale_zh_CN, 'zh_CN');
registerLocaleData(locale_zh_TW, 'zh_TW');

@NgModule()
export class LocaleModule { };