import { ChangeEvent, ChangeEventType, ChangeEventTypeEnum } from '../models/change-event.model';
import { Dictionary } from '../models/dictionary.model';
import { OrderBy } from '../models/order-by.model';

const compareFn = (orderByFormatted: Array<OrderBy>) => {
    const keysLen = orderByFormatted.length;
    return (a, b) => {
        let sorted = 0;
        let ix = 0;
        while (sorted === 0 && ix < keysLen) {
            if (orderByFormatted[ix]) {
                let aVal = ObjectUtilities.deepAccessUsingString(a, orderByFormatted[ix].property);
                let bVal = ObjectUtilities.deepAccessUsingString(b, orderByFormatted[ix].property);
                sorted = aVal.localeCompare(bVal, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }) * orderByFormatted[ix].direction;
                ix++;
            };
        };
        return sorted;
    };
};
const isArray = Array.isArray;
const keyList = Object.keys;
const hasProp = Object.prototype.hasOwnProperty;

export class ObjectUtilities {
    public static deepAccessUsingArray = <T>(obj: T, keys: Array<string>) => {
        if (obj && keys) {
            return keys.reduce((nestedObject, key) => {
                if (nestedObject && key in nestedObject) {
                    return nestedObject[key];
                };
                return undefined;
            }, obj);
        };
        return null;
    };

    public static deepAccessUsingString = (obj, key) => {
        if (obj && key) {
            return key.split('.').reduce((nestedObject, key) => {
                if (nestedObject && key in nestedObject) {
                    return nestedObject[key];
                };
                return undefined;
            }, obj);
        };
        return null;
    };

    public static deepAssignUsingArray = (obj, keys: Array<string>, value, createObject?: boolean) => {
        if (obj) {
            if (keys.length == 1) {
                return obj[keys[0]] = value;
            } else if (keys.length == 0) {
                return obj;
            } else if (obj[keys[0]]) {
                return ObjectUtilities.deepAssignUsingArray(obj[keys[0]], keys.slice(1), value, createObject);
            } else if (createObject) {
                obj[keys[0]] = {};
                return ObjectUtilities.deepAssignUsingArray(obj[keys[0]], keys.slice(1), value, createObject);
            };
        };
        return null;
    };

    public static deepAssignUsingString = (obj, key, value, createObject?: boolean) => {
        if (obj) {
            if (typeof key == 'string') {
                return ObjectUtilities.deepAssignUsingString(obj, key.split('.'), value, createObject);
            } else if (key.length == 1) {
                return obj[key[0]] = value;
            } else if (key.length == 0) {
                return obj;
            } else if (obj[key[0]]) {
                return ObjectUtilities.deepAssignUsingString(obj[key[0]], key.slice(1), value, createObject);
            } else if (createObject) {
                obj[key[0]] = {};
                return ObjectUtilities.deepAssignUsingString(obj[key[0]], key.slice(1), value, createObject);
            };
        };
        return null;
    };

    /**
     * Returns a deep copy of provided value. 
     * @param {T} value - The value to recursively clone.
     */
    public static deepCopy = <T>(oldObj: T): T => {
        let newObj: any = oldObj;
        if (oldObj && typeof oldObj === 'object') {
            newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
            for (var i in oldObj) {
                newObj[i] = ObjectUtilities.deepCopy(oldObj[i]);
            };
        };
        return newObj;
    };

    public static filter<T>(dictionary: Dictionary<T>, predicate: (item: T) => boolean): Dictionary<T> {
        var result = {};
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key) && predicate(dictionary[key])) {
                result[key] = dictionary[key];
            };
        };
        return result;
    };

    public static filterMultipleProperties<T>(collection: Array<T> | Dictionary<T>, props: Array<string>, filterString: string, dictionaryKey: string): Dictionary<T> {
        let filtered = {};
        filterString = filterString.toLowerCase();

        for (const key in collection) {
            if (collection.hasOwnProperty(key)) {
                for (let i = 0; i < props.length; i++) {
                    const propValue = String(ObjectUtilities.deepAccessUsingString(collection[key], props[i])).toLowerCase();
                    if (propValue.indexOf(filterString) !== -1 || !filterString) {
                        filtered[collection[key][dictionaryKey]] = collection[key];
                    };
                };
            };
        };

        return filtered;
    };

    public static filterAndMap<T, V>(dictionary: Dictionary<T>, predicate: (item: T) => boolean, mapper: (item: T) => V): Dictionary<V> {
        var result = {};
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key) && predicate(dictionary[key])) {
                result[key] = mapper(dictionary[key]);
            };
        };
        return result;
    };

    public static find<T>(collection: Array<T> | Dictionary<T>, predicate: (item: T) => boolean): T | null {
        for (const key in collection) {
            if (Object.prototype.hasOwnProperty.call(collection, key) && predicate(collection[key])) {
                return collection[key];
            };
        };
        return null;
    };

    public static forEach<T>(dictionary: Dictionary<T>, callback: (item?: T, key?: string | number) => void): void {
        for (const key in dictionary) {
            if (Object.prototype.hasOwnProperty.call(dictionary, key)) {
                callback(dictionary[key], key);
            };
        };
    };

    /**
     * Check if two values are deeply equivalent
     * @param a 
     * @param b 
     */
    public static equal(a: any, b: any) {
        if (a === b) {
            return true;
        };

        if (a && b && typeof a == 'object' && typeof b == 'object') {
            var arrA = isArray(a);
            var arrB = isArray(b);
            var i;
            var length;
            var key;;

            if (arrA && arrB) {
                length = a.length;
                if (length != b.length) {
                    return false;
                };
                for (i = length; i-- !== 0;) {
                    if (!this.equal(a[i], b[i])) {
                        return false;
                    };
                };
                return true;
            };

            if (arrA != arrB) {
                return false;
            };

            var dateA = a instanceof Date;
            var dateB = b instanceof Date;

            if (dateA != dateB) {
                return false;
            };
            if (dateA && dateB) {
                return a.getTime() == b.getTime();
            };

            var regexpA = a instanceof RegExp;
            var regexpB = b instanceof RegExp;

            if (regexpA != regexpB) {
                return false;
            };
            if (regexpA && regexpB) {
                return a.toString() == b.toString();
            };

            var keys = keyList(a);
            length = keys.length;

            if (length !== keyList(b).length) {
                return false;
            };

            for (i = length; i-- !== 0;) {
                if (!hasProp.call(b, keys[i])) {
                    return false;
                };
            };

            for (i = length; i-- !== 0;) {
                key = keys[i];
                if (!this.equal(a[key], b[key])) {
                    return false;
                };
            };

            return true;
        };

        return a !== a && b !== b;
    };

    public static getFirst<T>(dictionary: Dictionary<T>) {
        for (const key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                return dictionary[key];
            };
        };
        return null;
    };

    public static generateGuid(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };

    public static map<T, V>(dictionary: Dictionary<T>, mapper: (item: T) => V): Dictionary<V> {
        let mapped = {};
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                mapped[key] = mapper(dictionary[key]);
            };
        };
        return mapped;
    };

    public static toArray<T>(dictionary: Dictionary<T>, orderBy?: Array<OrderBy>) {
        let array: Array<T> = [];
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                array.push(dictionary[key]);
            };
        };
        return orderBy ? array.sort(compareFn(orderBy)) : array;
    };

    public static toFilteredArray<T>(dictionary: Dictionary<T>, predicate: (item: T) => boolean, orderBy?: Array<OrderBy>) {
        let array: Array<T> = [];
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key) && predicate(dictionary[key])) {
                array.push(dictionary[key]);
            };
        };
        return orderBy ? array.sort(compareFn(orderBy)) : array;
    };

    public static toFilteredMappedArray<T, V>(dictionary: Dictionary<T>, predicate: (item: T) => boolean, mapper: (item: T) => V, orderBy?: Array<OrderBy>) {
        let array: Array<V> = [];
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key) && predicate(dictionary[key])) {
                array.push(mapper(dictionary[key]));
            };
        };
        return orderBy ? array.sort(compareFn(orderBy)) : array;
    };

    public static toMappedArray<T, V>(dictionary: Dictionary<T>, mapper: (item: T) => V, orderBy?: Array<OrderBy>) {
        let array: Array<V> = [];
        for (var key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                array.push(mapper(dictionary[key]));
            };
        };
        return orderBy ? array.sort(compareFn(orderBy)) : array;
    };

    public static toDictionary<T>(array: Array<T>, key: string) {
        let dictionary: Dictionary<T> = {};
        for (var i = 0, len = array.length; i < len; i++) {
            dictionary[array[i][key]] = array[i];
        };
        return dictionary;
    };

    public static toMappedDictionary<T, V>(array: Array<T>, key: string, mapper: (item: T) => V) {
        let dictionary: Dictionary<V> = {};
        for (let i = 0, len = array.length; i < len; i++) {
            const item = mapper(array[i]);
            if (item) {
                dictionary[array[i][key]] = item;
            };
        };
        return dictionary;
    };

    public static updateDictionaryByChangeEvent<T extends { id: number, rowVersion?: number }>(dictionary: Dictionary<T>, changeEvent: ChangeEvent<T>): void {
        changeEvent.changeType = changeEvent.changeType.toLowerCase() as ChangeEventType;

        switch (ChangeEventTypeEnum[changeEvent.changeType]) {
            case ChangeEventTypeEnum.delete:
                delete dictionary[changeEvent.data.id];
                break;
            case ChangeEventTypeEnum.insert:
                dictionary[changeEvent.data.id] = changeEvent.data;
                break;
            case ChangeEventTypeEnum.update:
                if (!dictionary[changeEvent.data.id] || !dictionary[changeEvent.data.id].rowVersion || dictionary[changeEvent.data.id].rowVersion < changeEvent.data.rowVersion) {
                    dictionary[changeEvent.data.id] = changeEvent.data;
                };
                break;
            default:
                break;
        };
    };

    public static updateAndMapDictionaryByChangeEvent<T extends { id: number, rowVersion?: number }>(dictionary: Dictionary<T>, changeEvent: ChangeEvent<T>, mapper: (item: any) => T): void {
        const key = changeEvent.data.id;
        changeEvent.changeType = changeEvent.changeType.toLowerCase() as ChangeEventType;

        switch (ChangeEventTypeEnum[changeEvent.changeType]) {
            case ChangeEventTypeEnum.delete:
                delete dictionary[key];
                break;
            case ChangeEventTypeEnum.insert:
                const item = mapper(changeEvent.data);
                if (item) {
                    dictionary[key] = item;
                };
                break;
            case ChangeEventTypeEnum.update:
                const currentItem = dictionary[key];
                const newItem = changeEvent.data;
                if (!currentItem || !currentItem.rowVersion || currentItem.rowVersion < newItem.rowVersion) {
                    const item = mapper(newItem);
                    if (item) {
                        dictionary[key] = item;
                    } else {
                        delete dictionary[key];
                    };
                };
                break;
            default:
                break;
        };
    };
};