import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../endpoints';
import { ChangeEvent } from '../models/change-event.model';
import { SettingsApi, SettingsView } from '../models/settings.model';
import { RequestService } from './request.service';

@Injectable()
export class SettingsService {
    public settings: SettingsView = null;
    public settingsUpdateSubject = new Subject<SettingsView>();


    public constructor(private requestService: RequestService) { };

    public getAppSettings() {
        return this.settings;
    };

    public getAppSettingsObservable() {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.SETTINGS).pipe(
            map((settings: SettingsApi) => this.setSettings(settings))
        );
    };

    public processUpdateFromSignalR = (event: ChangeEvent<SettingsApi>) => {
        this.setSettings(event.data);
        this.settingsUpdateSubject.next(this.settings);
    };
    
    public setSettings(settings: SettingsApi) {
        return this.settings = new SettingsView(settings);
    };
};
