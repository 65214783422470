export class PostalAddressApi {
    public addressLine1: string;
    public addressLine2: string;
    public postcode: string;
    public region: string;
    public summaryText: string;
    public town: string;

    public constructor(item?: PostalAddressApi) {
        if (item) {
            item.addressLine1 && (this.addressLine1 = item.addressLine1);
            item.addressLine2 && (this.addressLine2 = item.addressLine2);
            item.postcode && (this.postcode = item.postcode);
            item.region && (this.region = item.region);
            item.summaryText && (this.summaryText = item.summaryText);
            item.town && (this.town = item.town);
        };
    };
};

export class PostalAddressView {
    public addressLine1: string = '';
    public addressLine2: string = '';
    public postcode: string = '';
    public region: string = '';
    public summaryText: string = '';
    public town: string = '';

    public constructor(item?: PostalAddressView) {
        if (item) {
            item.addressLine1 && (this.addressLine1 = item.addressLine1);
            item.addressLine2 && (this.addressLine2 = item.addressLine2);
            item.postcode && (this.postcode = item.postcode);
            item.region && (this.region = item.region);
            item.summaryText && (this.summaryText = item.summaryText);
            item.town && (this.town = item.town);
        };
    };
};