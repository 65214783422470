import { Injectable } from '@angular/core';
import { ConnectionStatuses, ISignalRConnection, SignalRService } from '@autocab/ghost-signalr';
import { Endpoints } from '../endpoints';
import { ChangeEvent } from '../models/change-event.model';
import { SettingsApi } from '../models/settings.model';
import { SystemDetailsApi } from '../models/system-details.model';
import { UserGroupApi } from '../models/user-group.model';
import { UserApi } from '../models/user.model';
import { ArrayUtilities } from '../utilities/array.utilities';
import { AuthenticationService } from './authentication.service';
import { SettingsService } from './settings.service';
import { SystemDetailsService } from './system-details.service';
import { UserGroupService } from './user-group.service';
import { UserService } from './user.service';

interface IHasId {
    id: number;
    rowVersion?: number;
};

@Injectable()
export class SignalRConnectionService {
    private isSynchronised = false;
    private settingsDataChangeEvents: Array<ChangeEvent<SettingsApi>> = [];
    private systemDetailsDataChangeEvents: Array<ChangeEvent<SystemDetailsApi>> = [];
    private userDataChangeEvents: Array<ChangeEvent<UserApi>> = [];
    private userGroupsDataChangeEvents: Array<ChangeEvent<UserGroupApi>> = [];

    private applicationReloadPromptDisplayed = false;
    private disconnecting = false;

    public EVENT_NAMES = {
        _MANAGEMENT_SETTINGS_CHANGED: 'managementSettingsChanged',
        _SYSTEM_DETAILS: 'systemDetailsChanged',
        _USER_GROUP_DATA_CHANGED: 'userGroupDataChanged',
        _USER_DATA_CHANGED: 'userDataChanged'
    };
    public connection: ISignalRConnection;

    public constructor(
        private signalRService: SignalRService,
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService,
        private systemDetailsService: SystemDetailsService,
        private userService: UserService,
        private userGroupService: UserGroupService
    ) {
        this.authenticationService.unauthenticate.subscribe(() => this.disconnect());
    };

    public async connect(): Promise<ISignalRConnection> {
        this.connection = this.signalRService.createConnection({ url: Endpoints.API_URL });

        this.connection.errors.subscribe(error => {
            console.error('SignalR Error', error);
        });

        this.connection.status.subscribe(status => {
            switch (status.name) {
                case ConnectionStatuses.connecting.name:
                    console.log('Connecting to SignalR');
                    break;
                case ConnectionStatuses.connected.name:
                    console.log('Connected to SignalR');
                    break;
                case ConnectionStatuses.reconnecting.name:
                    console.log('Reconnecting to SignalR');
                    this.showApplicationReloadPrompt();
                    break;
                case ConnectionStatuses.disconnected.name:
                    console.log('Disconnected from SignalR');
                    this.showApplicationReloadPrompt();
                    break;
                default:
                    break;
            };
        });

        this.connection.start();
        this.connection.listenFor<ChangeEvent<SettingsApi>>(this.EVENT_NAMES._MANAGEMENT_SETTINGS_CHANGED).subscribe((event) => {
            this.isSynchronised ? this.settingsService.processUpdateFromSignalR(event) : this.settingsDataChangeEvents.push(event);
        });
        this.connection.listenFor<ChangeEvent<SystemDetailsApi>>(this.EVENT_NAMES._SYSTEM_DETAILS).subscribe((event) => {
            this.isSynchronised ? this.systemDetailsService.processUpdateFromSignalR(event) : this.systemDetailsDataChangeEvents.push(event);
        });
        this.connection.listenFor<ChangeEvent<UserApi>>(this.EVENT_NAMES._USER_DATA_CHANGED).subscribe((event) => {
            this.isSynchronised ? this.userService.processUpdateFromSignalR(event) : this.userDataChangeEvents.push(event);
        });
        this.connection.listenFor<ChangeEvent<UserGroupApi>>(this.EVENT_NAMES._USER_GROUP_DATA_CHANGED).subscribe((event) => {
            this.isSynchronised ? this.userGroupService.processUpdateFromSignalR(event) : this.userGroupsDataChangeEvents.push(event);
        });

        return this.connection;
    };

    public disconnect(): void {
        this.disconnecting = true;
        this.settingsDataChangeEvents = [];
        this.systemDetailsDataChangeEvents = [];
        this.userGroupsDataChangeEvents = [];
        this.isSynchronised = false;
        this.connection && this.connection.stop();
    };

    public synchroniseSignalREventsWithCachedData() {
        this.synchroniseLastEvent(this.settingsDataChangeEvents, this.settingsService.processUpdateFromSignalR);
        this.synchroniseLastEvent(this.systemDetailsDataChangeEvents, this.systemDetailsService.processUpdateFromSignalR);
        this.synchroniseLastEvent(this.userDataChangeEvents, this.userService.processUpdateFromSignalR);
        this.synchroniseLastEvent(this.userGroupsDataChangeEvents, this.userGroupService.processUpdateFromSignalR);
        this.isSynchronised = true;
    };

    private synchroniseLastEvent<T>(changeEvents: Array<ChangeEvent<T>>, callback: (event: ChangeEvent<T>) => void) {
        const lastEvent = ArrayUtilities.last(changeEvents);
        if (lastEvent) {
            callback(lastEvent);
        };
    };

    private showApplicationReloadPrompt() {
        //TODO: potentially reinitiate the cache loader and restart signalr rather than reloading the entire application
        // !this.applicationReloadPromptDisplayed && !this.disconnecting && this.overlayService.showAlertOverlay({
        //     messages: 'alert.message.close_this_alert_to_reload_the_application',
        //     title: 'alert.title.lost_connection_to_server', type: AlertType.Error
        // }).ghostOverlayRef.completed.subscribe(() => {
        //     location.reload();
        // });
        this.applicationReloadPromptDisplayed = true;
    };
};