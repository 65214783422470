export class GhostPermissionApi {
    public description: string;
    public isAllowed: boolean;
    public key: string;

    public constructor(item?: GhostPermissionApi) {
        this.description = (item && item.description) ? item.description : '';
        this.isAllowed = (item && item.isAllowed) ? true : false;
        this.key = (item && item.key) ? item.key : '';
    };
};

export class Ghost365PermissionApi {
    public access: boolean = true;
    public add: boolean = true;
    public edit: boolean = true;
    public permissionName: string = '';

    public constructor(item?: Ghost365PermissionApi) {
        if (item) {
            this.access = (item && typeof item.access === 'boolean') ? item.access : null;
            this.add = (item && typeof item.add === 'boolean') ? item.add : null;
            this.edit = (item && typeof item.edit === 'boolean') ? item.edit : null;
            this.permissionName = (item && item.permissionName) ? item.permissionName : '';
        };
    };
};

export class Ghost365PermissionView extends Ghost365PermissionApi {
    public description: string = '';

    public constructor(item?: Ghost365PermissionView) {
        super(item);
        this.description = item.description;
    };
};

export class UserGroupApi {
    public accountPermissions: Array<GhostPermissionApi> = [];
    public accounts365Permissions: Array<Ghost365PermissionApi> = [];
    public bookingPermissions: Array<GhostPermissionApi> = [];
    public companyFilter: Array<number>;
    public createdDate: string | Date;
    public dateCreated: string | Date;
    public dateLastModified: string | Date;
    public defaultBookingTemplateId: number;
    public excludeFromCallStats: boolean;
    public id: number;
    public lastModifiedDate: string | Date;
    public managementPermissions: Array<GhostPermissionApi> = [];
    public management365Permissions: Array<Ghost365PermissionApi> = [];
    public miscellaneousPermissions: Array<GhostPermissionApi> = [];
    public name: string;
    public rowVersion: number;
    public showBookingsTabs: boolean;
    public telephonyPermissions: Array<GhostPermissionApi> = [];

    public constructor(item?: UserGroupView | UserGroupApi) {
        this.accountPermissions = (item && item.accountPermissions && item.accountPermissions.length) ? item.accountPermissions.map(permission => new GhostPermissionApi(permission)) : [];
        this.accounts365Permissions = (item && item.accounts365Permissions && item.accounts365Permissions.length) ? item.accounts365Permissions.map(permission => new Ghost365PermissionApi(permission)) : [];
        this.bookingPermissions = (item && item.bookingPermissions && item.bookingPermissions.length) ? item.bookingPermissions.map(permission => new GhostPermissionApi(permission)) : [];
        this.managementPermissions = (item && item.managementPermissions && item.managementPermissions.length) ? item.managementPermissions.map(permission => new GhostPermissionApi(permission)) : [];
        this.management365Permissions = (item && item.management365Permissions && item.management365Permissions.length) ? item.management365Permissions.map(permission => new Ghost365PermissionApi(permission)) : [];
        this.miscellaneousPermissions = (item && item.miscellaneousPermissions && item.miscellaneousPermissions.length) ? item.miscellaneousPermissions.map(permission => new GhostPermissionApi(permission)) : [];
        this.telephonyPermissions = (item && item.telephonyPermissions && item.telephonyPermissions.length) ? item.telephonyPermissions.map(permission => new GhostPermissionApi(permission)) : [];
        this.companyFilter = (item && item.companyFilter) ? item.companyFilter : [];
        this.createdDate = (item && item.createdDate) ? item.createdDate : null;
        this.dateCreated = (item && item.dateCreated) ? item.dateCreated : null;
        this.dateLastModified = (item && item.dateLastModified) ? item.dateLastModified : null;
        this.defaultBookingTemplateId = (item && item.defaultBookingTemplateId) ? item.defaultBookingTemplateId : null;
        this.excludeFromCallStats = (item && item.excludeFromCallStats) ? true : false;
        this.id = (item && item.id) ? item.id : null;
        this.lastModifiedDate = (item && item.lastModifiedDate) ? item.lastModifiedDate : null;
        this.name = (item && item.name) ? item.name : '';
        this.rowVersion = (item && item.rowVersion) ? item.rowVersion : null;
        this.showBookingsTabs = (item && item.showBookingsTabs) ? true : false;
    };
};

export class UserGroupView extends UserGroupApi {
    public companyFilterAccessTotals: string;
    public createdDate: Date;
    public lastModifiedDate: Date;

    public constructor(item?: UserGroupView | UserGroupApi) {
        super(item);
        this.companyFilterAccessTotals = (item && item.companyFilter && item.companyFilter.length) ? `Access to ${item.companyFilter.length} Companies` : 'Access to All Companies';
        this.createdDate = (item && item.createdDate) ? new Date(item.createdDate) : null;
        this.lastModifiedDate = (item && item.lastModifiedDate) ? new Date(item.lastModifiedDate) : null;
    };
};