import { HttpErrorResponse } from "@angular/common/http";

export enum ErrorCodeApi {
    None = 0,
    PermissionError = 1001,
    BookingControlError = 1002,
    VehicleRequestedError = 1003,
    BookingRuleNotFoundError = 1004,
    BookingRuleDeleteError = 1005,
    VehicleProgrammingError = 1006,
    DriverShiftError = 1007,
    VehicleAllocationError = 1008,
    EmailError = 1009,
    CustomerError = 1010,
    DriverRequestedError = 1011,
    BookingNotFoundError = 1012,
    DispatchedBookingNotSentError = 1013,
    AuthenticationErrorUserSuspended = 1014,
    AuthenticationErrorWrongUsernameOrPassword = 1015,
    TemporaryLicenceExpired = 1016,
    AccountsPermissionError = 1017,
    BookingControlWarning = 1018,	// Obsolete
};

export interface HttpErrorResponseApi extends HttpErrorResponse {
    error: {
        errors?: Array<ServerValidationError>;
        errorMessages?: Array<ResponseErrorApi>;
        message?: string;
        modelState?: {
            error?: Array<ServerValidationError>;
        };
    };
};

export class ResponseErrorApi {
    public errorCode: ErrorCodeApi;
    public errorType: keyof typeof ErrorCodeApi;
    public messages: Array<string>;

    public constructor(item: ResponseErrorApi) {
        this.errorCode = item.errorCode;
        this.errorType = item.errorType;
        this.messages = item.messages;
    };
};

export class ResponseErrorView extends ResponseErrorApi {
    public summary: ResponseErrorSummary;

    public constructor(error?: ResponseErrorView) {
        super(error);
        this.summary = error.summary ? error.summary : null;
    };
};

export declare class ResponseErrorSummary {
    title?: string;
    subtitle?: string;
    message?: string;
};

export class ServerValidationError {
    public detail?: string;
    public title?: string;

    public constructor(item?: ServerValidationError) {
        this.detail = (item && item.detail) ? item.detail : '';
        this.title = (item && item.title) ? item.title : '';
    };
};