import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Endpoints } from '../endpoints';
import { ChangeEvent, ChangeEventType } from '../models/change-event.model';
import { GhostPermissionApi, UserGroupApi, UserGroupView } from '../models/user-group.model';
import { PermissionsService } from './permissions.service';
import { RequestService } from './request.service';

@Injectable()
export class UserGroupService {
    public static currentUserGroup: UserGroupView = null;
    public static currentUserGroupUpdateSubject = new Subject<UserGroupView>();

    public constructor(private requestService: RequestService) { };

    public getCurrentUserGroupByIdObservable(id: number) {
        return this.requestService.authenticatedGet(Endpoints.API_URL + Endpoints.USER_GROUPS + '/' + id).pipe(
            map((userGroup: UserGroupApi) => this.setCurrentUserGroup(userGroup))
        );
    };

    public processUpdateFromSignalR(event: ChangeEvent<UserGroupApi>) {
        if (event.changeType.toLowerCase() === ChangeEventType.update && event.data.id === UserGroupService.currentUserGroup.id) {
            UserGroupService.currentUserGroupUpdateSubject.next(this.setCurrentUserGroup(event.data));
        };
    };

    public setCurrentUserGroup(userGroup: UserGroupApi) {
        UserGroupService.currentUserGroup = new UserGroupView(userGroup);
        UserGroupService.currentUserGroup.bookingPermissions.map((permission: GhostPermissionApi) => {
            PermissionsService.BOOKING[permission.key] = permission.isAllowed;
        });
        UserGroupService.currentUserGroup.miscellaneousPermissions.map((permission: GhostPermissionApi) => {
            PermissionsService.MISCELLANEOUS[permission.key] = permission.isAllowed;
        });
        PermissionsService.permissionsChangedSubject.next();

        return UserGroupService.currentUserGroup;
    };
};