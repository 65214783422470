import { Injectable } from '@angular/core';
import { forkJoin, from, Observable } from "rxjs";
import { mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { GhostTranslationService } from './ghost-translation.service';
import { LoadingService } from './loading.service';
import { SettingsService } from './settings.service';
import { SignalRConnectionService } from './signalr-connection.service';
import { SystemDetailsService } from './system-details.service';
import { TimeService } from './time.service';
import { UserService } from './user.service';

@Injectable()
export class CacheLoaderService {
    public appCacheResolved: boolean = false;

    private cacheItemsTotal: number = null;
    private firstPriorityObservables: Array<Observable<any>> = [];

    public constructor(
        private authenticationService: AuthenticationService,
        private ghostTranslationService: GhostTranslationService,
        private loadingService: LoadingService,
        private settingsService: SettingsService,
        private signalRConnectionService: SignalRConnectionService,
        private systemDetailsService: SystemDetailsService,
        private timeService: TimeService,
        private userService: UserService
    ) {
        this.authenticationService.unauthenticate.subscribe(() => this.appCacheResolved = false);
    };

    public fetchAppData(): Observable<boolean> | boolean {
        this.initAppCacheObservables();
        this.ghostTranslationService.setDefaultLanguageTranslation().subscribe();
        this.timeService.getSystemTime();
        return forkJoin([from(this.signalRConnectionService.connect())]).pipe(
            mergeMap(() => {
                return forkJoin(this.firstPriorityObservables).pipe(
                    mergeMap(async () => {
                        this.emitLoadingProgress('cache.loading.message.finished_loadinge', true);
                        this.signalRConnectionService.synchroniseSignalREventsWithCachedData();
                        return this.appCacheResolved = true;
                    })
                );
            })
        );
    };

    private emitLoadingProgress(desription: string, finishedLoading?: boolean) {
        this.loadingService.emitProgress({
            description: desription,
            progress: !finishedLoading ? this.loadingService.getProgress(this.cacheItemsTotal) : 100
        }, finishedLoading);
    };

    private initAppCacheObservables() {
        this.firstPriorityObservables = [
            this.userService.getCurentUserAndUserGroupDataObservable().pipe(tap(() => {
                this.emitLoadingProgress('cache.loading.message.loaded_current_user_group_data');
            })),
            this.settingsService.getAppSettingsObservable().pipe(tap(() => {
                this.emitLoadingProgress('cache.loading.message.loaded_app_settings');
            })),
            this.systemDetailsService.getSystemDetailsObservable(() => this.ghostTranslationService.setSystemLanguageTranslation().subscribe()).pipe(tap(() => {
                this.emitLoadingProgress('cache.loading.message.loaded_system_details');
            })),
            this.timeService.getServerTimeZoneObservable().pipe(tap(() => {
                this.emitLoadingProgress('cache.loading.message.loaded_local_time_zone');
            })),
            this.timeService.getTimeZonesObservable().pipe(tap(() => {
                this.emitLoadingProgress('cache.loading.message.loaded_time_zones');
            }))
        ];
        this.cacheItemsTotal = this.firstPriorityObservables.length;
    };
};