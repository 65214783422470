import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services/authentication.service';

@Component({
    selector: 'app',
    styleUrls: ['app.component.css'],
    templateUrl: 'app.component.html',
    encapsulation: ViewEncapsulation.None,
    host: { class: 'app' }
})

export class AppComponent {
    public constructor(private authenticationService: AuthenticationService, private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.authenticationService.authenticated && (event.url === this.authenticationService.loginUrl || event.url === this.authenticationService.portalUrl)) {
                this.authenticationService.unauthenticateUser(false);
            };
        });
    };
};