import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { Endpoints } from '../endpoints';
import { AuthenticationResponse, Autocab365Session, DecodedJwtToken, SessionCli } from '../models/session.model';
import { RequestService } from './request.service';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthenticationService {
    public authenticated: boolean = false;
    public loginUrl: string = '/login';
    public portalUrl: string = 'https://portal.autocab365.com';
    public unauthenticate: Subject<void> = new Subject();
    public static sessionStorageKey = 'autocab365_session';

    public constructor(private router: Router, private requestService: RequestService) {
        SessionService.session = new Autocab365Session(StorageService.getItem(AuthenticationService.sessionStorageKey));
        if (SessionService.session && SessionService.session.token && !this.isTokenExpired()) {
            this.authenticated = true;
            this.runTokenValidatation();
        } else {
            StorageService.deleteItem(AuthenticationService.sessionStorageKey);
        };
    };

    public authenticateUser(apiUrl: string, token: string) {
        /*var jwt = this.parseToken(res.secret);
        SessionService.session = new Autocab365Session({
            apiUrl: Endpoints.API_URL,
            cli: new SessionCli(),
            engineer: jwt.engineer,
            token: res.secret,
            user: res.user,
        });
        StorageService.setItem(AuthenticationService.sessionStorageKey, SessionService.session);
        this.authenticated = true;
        this.runTokenValidatation();
        this.router.navigate(['accounts']);\*/

        Endpoints.API_URL = apiUrl;
        SessionService.session = new Autocab365Session({
            apiUrl: apiUrl,
            cli: new SessionCli(),
            token: token,
        });
        StorageService.setItem(AuthenticationService.sessionStorageKey, SessionService.session);
        this.authenticated = true;
        this.runTokenValidatation();
        this.router.navigate(['accounts']);
    };

    public redirectToLogin() {
        location.hostname === 'localhost' ? this.router.navigate([this.loginUrl]) : location.href = this.portalUrl;
    };

    public unauthenticateUser(navigateToLoginView: boolean = true) {
        StorageService.deleteItem(AuthenticationService.sessionStorageKey);
        SessionService.session = null;
        this.authenticated = false;
        this.unauthenticate.next();
        if (navigateToLoginView) {
            this.redirectToLogin();
        };
    };

    private isTokenExpired(): boolean {
        var token = SessionService.getToken();
        if (token) {
            try {
                var jwt = this.parseToken(token);
                if (jwt && jwt.exp) {
                    var expiryDate = new Date(jwt.exp * 1000);
                    if (expiryDate < new Date(new Date().getTime() + (30 * 60000)) && expiryDate < new Date()) {
                        return true;
                    };
                };
            } catch (err) {
                return false;
            };
        };
        return false;
    };

    private parseToken = (token: string): DecodedJwtToken => {
        var base64 = token.split('.')[1];
        return JSON.parse(window.atob(base64.replace('-', '+').replace('_', '/')));
    };

    private renewToken() {
        this.requestService.authenticatedPost(Endpoints.API_URL + Endpoints.AUTHENTICATE_RENEW, {}).subscribe(
            (res: AuthenticationResponse) => {
                if (res && res.secret) {
                    SessionService.setToken(res.secret);
                };
            },
            (err) => {
                console.log('Renew token error: ', err);
            }
        );
    };

    //TODO: check how often interval is run and if it forces change detection
    private runTokenValidatation() {
        interval(1000).subscribe(() => {
            if (this.authenticated && this.shouldRenewToken()) {
                this.renewToken();
            };
        }, (err: any) => {
            console.log('Error: ' + err);
        }, () => {
            console.log('Completed');
        });
    };

    private shouldRenewToken(): boolean {
        var token = SessionService.getToken();
        if (token) {
            try {
                var jwt = this.parseToken(token);
                if (jwt && jwt.exp) {
                    var expiryDate = new Date(jwt.exp * 1000);
                    if (expiryDate < new Date(new Date().getTime() + (30 * 60000)) && expiryDate > new Date()) {
                        return true;
                    };
                };
            } catch (err) {
                return false;
            };
        };
        return false;
    };
};