import { CoordinateApi } from "./coordinate.model";
import { UISettingsApi, UISettingsView } from "./ui-settings.model";

export enum AddressSearchSource {
    All = 'All',
    AutocabAddressSearch = 'AutocabAddressSearch',
    AutocabGeocoding = 'AutocabGeocoding',
    Google = 'Google',
    GoogleDirect = 'GoogleDirect',
    StreetsDatabase = 'StreetsDatabase'
};

export class SettingsApi {
    public accountingSettings: any;
    public addressSettings: AddressSettingsApi;
    public dispatchSettings: any;
    public moduleSettings: ModuleSettingsApi;
    public routeCalculationSettings: RouteCalculationSettingsApi;
    public telephonySettings: TelephonySettingsApi;
    public userInterfaceSettings: UISettingsApi;
    public vehicleCommunicationSettings: VehicleCommunicationSettingsApi;

    public constructor(item: SettingsApi) {
        this.accountingSettings = (item && item.accountingSettings) ? item.accountingSettings : null;
        this.addressSettings = (item && item.addressSettings) ? new AddressSettingsApi(item.addressSettings) : null;
        this.dispatchSettings = (item && item.dispatchSettings) ? item.dispatchSettings : null;
        this.moduleSettings = (item && item.moduleSettings) ? new ModuleSettingsApi(item.moduleSettings) : null;
        this.routeCalculationSettings = (item && item.routeCalculationSettings) ? new RouteCalculationSettingsApi(item.routeCalculationSettings) : null;
        this.telephonySettings = (item && item.telephonySettings) ? new TelephonySettingsApi(item.telephonySettings) : null;
        this.userInterfaceSettings = (item && item.userInterfaceSettings) ? new UISettingsApi(item.userInterfaceSettings) : new UISettingsApi();
        this.vehicleCommunicationSettings = (item && item.vehicleCommunicationSettings) ? new VehicleCommunicationSettingsApi(item.vehicleCommunicationSettings) : null;
    };
};

export class SettingsView {
    public accountingSettings: any;
    public addressSettings: AddressSettingsView = new AddressSettingsView();
    public dispatchSettings: any;
    public moduleSettings: ModuleSettingsView = new ModuleSettingsView();
    public routeCalculationSettings: RouteCalculationSettingsView = new RouteCalculationSettingsView();
    public telephonySettings: TelephonySettingsView = new TelephonySettingsView();
    public userInterfaceSettings: UISettingsView = new UISettingsView();
    public vehicleCommunicationSettings: VehicleCommunicationSettingsView = new VehicleCommunicationSettingsView();

    public constructor(item?: SettingsView | SettingsApi) {
        if (item) {
            item.accountingSettings && (this.accountingSettings = item.accountingSettings);
            item.addressSettings && (this.addressSettings = new AddressSettingsView(item.addressSettings));
            item.dispatchSettings && (this.dispatchSettings = item.dispatchSettings);
            item.moduleSettings && (this.moduleSettings = new ModuleSettingsView(item.moduleSettings));
            item.routeCalculationSettings && (this.routeCalculationSettings = new RouteCalculationSettingsView(item.routeCalculationSettings));
            item.telephonySettings && (this.telephonySettings = new TelephonySettingsView(item.telephonySettings));
            item.userInterfaceSettings && (this.userInterfaceSettings = new UISettingsView(item.userInterfaceSettings as UISettingsView));
            item.vehicleCommunicationSettings && (this.vehicleCommunicationSettings = new VehicleCommunicationSettingsView(item.vehicleCommunicationSettings));
        };
    };
};
export class AddressSettingsApi {
    public constrainResults: number;
    public countryCode: string;
    public enableTeleatlasAddressDatabase: boolean;
    public freetypeRadius: number;
    public googleApiKey: string;
    public languageCode: string;
    public localAddressCentre: CoordinateApi;
    public localAddressHeight: number;
    public localAddressWidth: number;
    public mappingProjection: string;
    public poiConfiguration: PoiConfigurationApi;
    public source: string;

    public constructor(item?: AddressSettingsApi) {
        this.constrainResults = (item && item.constrainResults);
        this.countryCode = (item && item.countryCode);
        this.enableTeleatlasAddressDatabase = (item && item.enableTeleatlasAddressDatabase);
        this.freetypeRadius = (item && item.freetypeRadius);
        this.googleApiKey = (item && item.googleApiKey);
        this.languageCode = (item && item.languageCode);
        this.localAddressCentre = (item && item.localAddressCentre) ? new CoordinateApi(item.localAddressCentre) : null;
        this.localAddressHeight = (item && item.localAddressHeight);
        this.localAddressWidth = (item && item.localAddressWidth);
        this.mappingProjection = (item && item.mappingProjection);
        this.poiConfiguration = (item && item.poiConfiguration) ? new PoiConfigurationApi(item.poiConfiguration) : null;
        this.source = (item && item.source);
    };
};

export class AddressSettingsView {
    public constrainResults: number = null;
    public countryCode: string = '';
    public enableTeleatlasAddressDatabase: boolean;
    public freetypeRadius: number = null;
    public googleApiKey: string = '';
    public languageCode: string = '';
    public localAddressCentre: CoordinateApi = null;
    public localAddressHeight: number = null;
    public localAddressWidth: number = null;
    public mappingProjection: string = '';
    public poiConfiguration: PoiConfigurationApi = null;
    public source: string = '';
    public isGoogleApi: boolean = false;

    public constructor(item?: AddressSettingsApi) {
        if (item) {
            item.constrainResults !== undefined && (this.constrainResults = item.constrainResults);
            item.countryCode && (this.countryCode = item.countryCode);
            item.enableTeleatlasAddressDatabase !== undefined && (this.enableTeleatlasAddressDatabase = item.enableTeleatlasAddressDatabase);
            item.freetypeRadius !== undefined && (this.freetypeRadius = item.freetypeRadius);
            item.googleApiKey && (this.googleApiKey = item.googleApiKey);
            item.languageCode && (this.languageCode = item.languageCode);
            item.localAddressCentre && (this.localAddressCentre = new CoordinateApi(item.localAddressCentre));
            item.localAddressHeight !== undefined && (this.localAddressHeight = item.localAddressHeight);
            item.localAddressWidth !== undefined && (this.localAddressWidth = item.localAddressWidth);
            item.mappingProjection && (this.mappingProjection = item.mappingProjection);
            item.poiConfiguration && (this.poiConfiguration = new PoiConfigurationApi(item.poiConfiguration));
            if (item.source) {
                this.source = item.source;
                this.isGoogleApi = this.source == AddressSearchSource.GoogleDirect || this.source == AddressSearchSource.Google;
            };
        };
    };
};

export class AnalyticsSettingsApi {
    public enabled: boolean;

    public constructor(item?: AnalyticsSettingsApi) {
        this.enabled = (item && item.enabled) ? item.enabled : false;
    };
};

export class AnalyticsSettingsView {
    public enabled: boolean = false;

    public constructor(item?: AnalyticsSettingsView) {
        this.enabled = (item && item.enabled) ? item.enabled : false;
    };
};

export class DriverOutsideSendModeApi {
    public appBooking: boolean;
    public ivrBooking: boolean;
    public operator: boolean;
    public textBooking: boolean;
    public textPoint: boolean;

    public constructor(item?: DriverOutsideSendModeApi) {
        this.appBooking = (item && item.appBooking) ? true : false;
        this.ivrBooking = (item && item.ivrBooking) ? true : false;
        this.operator = (item && item.operator) ? true : false;
        this.textBooking = (item && item.textBooking) ? true : false;
        this.textPoint = (item && item.textPoint) ? true : false;
    };
};

export class DriverOutsideSendModeView {
    public appBooking: boolean = false;
    public ivrBooking: boolean = false;
    public operator: boolean = false;
    public textBooking: boolean = false;
    public textPoint: boolean = false;

    public constructor(item?: DriverOutsideSendModeView) {
        if (item) {
            item.appBooking && (this.appBooking = item.appBooking);
            item.ivrBooking && (this.ivrBooking = item.ivrBooking);
            item.operator && (this.operator = item.operator);
            item.textBooking && (this.textBooking = item.textBooking);
            item.textPoint && (this.textPoint = item.textPoint);
        };
    };
};

export enum IvrDefaultModeApi {
    IvrOff,
    IvrOnLandlinesIvrOffMobiles,
    IvrOffLandlinesIvrOnMobiles,
    IvrOnLandlinesIvrOnMobiles
};

export class IvrSettingsApi {
    public defaultMode: string;
    public enabled: boolean;

    public constructor(item?: IvrSettingsApi) {
        this.defaultMode = (item && item.defaultMode) ? item.defaultMode : IvrDefaultModeApi[IvrDefaultModeApi.IvrOff];
        this.enabled = (item && item.enabled) ? true : false;
    };
};

export class IvrSettingsView {
    public defaultMode: string = '';
    public enabled: boolean = false;

    public constructor(item?: IvrSettingsApi) {
        if (item) {
            item.defaultMode && (this.defaultMode = item.defaultMode);
            item.enabled && (this.enabled = item.enabled);
        };
    };
};

export class ModuleSettingsApi {
    public analyticsSettings: AnalyticsSettingsApi;
    public externalBookingIntegrationSettings: ExternalBookingIntegrationSettingsApi;
    public ivrSettings: IvrSettingsApi;
    public loyaltyCardSettings: LoyaltyCardSettingApi;
    public multiCompanySettings: MultiCompanySettingsApi;
    public ringbackSettings: RingbackSettingsApi;
    public textMessagingSettings: TextMessagingSettingsApi;

    public constructor(item?: ModuleSettingsApi) {
        this.analyticsSettings = (item && item.analyticsSettings) ? new AnalyticsSettingsApi(item.analyticsSettings) : null;
        this.externalBookingIntegrationSettings = (item && item.externalBookingIntegrationSettings) ? new ExternalBookingIntegrationSettingsApi(item.externalBookingIntegrationSettings) : null;
        this.ivrSettings = (item && item.ivrSettings) ? new IvrSettingsApi(item.ivrSettings) : null;
        this.loyaltyCardSettings = (item && item.loyaltyCardSettings) ? new LoyaltyCardSettingApi(item.loyaltyCardSettings) : null;
        this.multiCompanySettings = (item && item.multiCompanySettings) ? new MultiCompanySettingsApi(item.multiCompanySettings) : null;
        this.ringbackSettings = (item && item.ringbackSettings) ? new RingbackSettingsApi(item.ringbackSettings) : null;
        this.textMessagingSettings = (item && item.textMessagingSettings) ? new TextMessagingSettingsApi(item.textMessagingSettings) : null;
    };
};

export class ModuleSettingsView {
    public analyticsSettings: AnalyticsSettingsView = new AnalyticsSettingsView();
    public externalBookingIntegrationSettings: ExternalBookingIntegrationSettingsView = new ExternalBookingIntegrationSettingsView();
    public ivrSettings: IvrSettingsView = new IvrSettingsView();
    public loyaltyCardSettings: LoyaltyCardSettingView = new LoyaltyCardSettingView();
    public multiCompanySettings: MultiCompanySettingsView = new MultiCompanySettingsView();
    public ringbackSettings: RingbackSettingsView = new RingbackSettingsView();
    public textMessagingSettings: TextMessagingSettingsView = new TextMessagingSettingsView();

    public constructor(item?: ModuleSettingsView) {
        if (item) {
            item.analyticsSettings && (this.analyticsSettings = new AnalyticsSettingsView(item.analyticsSettings));
            item.externalBookingIntegrationSettings && (this.externalBookingIntegrationSettings = new ExternalBookingIntegrationSettingsView(item.externalBookingIntegrationSettings));
            item.ivrSettings && (this.ivrSettings = new IvrSettingsView(item.ivrSettings));
            item.loyaltyCardSettings && (this.loyaltyCardSettings = new LoyaltyCardSettingView(item.loyaltyCardSettings));
            item.multiCompanySettings && (this.multiCompanySettings = new MultiCompanySettingsView(item.multiCompanySettings));
            item.ringbackSettings && (this.ringbackSettings = new RingbackSettingsView(item.ringbackSettings));
            item.textMessagingSettings && (this.textMessagingSettings = new TextMessagingSettingsView(item.textMessagingSettings));
        };
    };
};

export class ExternalBookingIntegrationSettingsApi {
    public system: string;

    public constructor(item?: ExternalBookingIntegrationSettingsApi) {
        this.system = (item && item.system) ? item.system : '';
    };
};

export class ExternalBookingIntegrationSettingsView {
    public system: string;

    public constructor(item?: ExternalBookingIntegrationSettingsView) {
        this.system = (item && item.system) ? item.system : '';
    };
};

export enum IndependentCompanyAccountsMode {
    OnAccountsOnly = 'OnAccountsOnly',
    Off = 'Off',
    On = 'On'
};

export class LoyaltyCardSettingApi {
    public enabled: boolean;

    public constructor(item?: LoyaltyCardSettingApi) {
        this.enabled = (item && item.enabled) ? item.enabled : false;
    };
};

export class LoyaltyCardSettingView {
    public enabled: boolean = false;

    public constructor(item?: LoyaltyCardSettingView) {
        item && item.enabled && (this.enabled = item.enabled);
    };
};

export class MultiCompanySettingsApi {
    public enableIndependentAppUsers: boolean;
    public enableIndependentBookingControls: boolean;
    public enableIndependentCompanyAccounts: IndependentCompanyAccountsMode;
    public enableIndependentCompanyAdvancedBackupZones: boolean;
    public enableIndependentCompanyFeederZones: boolean;
    public enableIndependentCompanyOverbookingProtection: boolean;
    public enableIndependentCompanyQueryRectangles: boolean;
    public enableIndependentCompanyTariffs: boolean;
    public enableIndependentCompanyZoneWarningTimes: boolean;
    public enableIndependentCompanyZones: boolean;
    public enableIndependentDriversFilter: boolean;
    public enableIndependentLoyalty: boolean;
    public enableIndependentQueuePositions: boolean;
    public enableIndependentVehiclesFilter: boolean;
    public haveMultiCompanyBookingControls: boolean;
    public haveMultiCompanyIndependentAccounts: boolean;
    public haveMultiCompanyIndependentAppUsers: boolean;
    public haveMultiCompanyIndependentFeederZones: boolean;
    public haveMultiCompanyIndependentLoyalty: boolean;
    public haveMultiCompanyIndependentOverbookingProtection: boolean;
    public haveMultiCompanyIndependentPricing: boolean;
    public haveMultiCompanyIndependentZones: boolean;
    public haveMultiCompanyZoneWarningTimes: boolean;
    public mobileBookingOverrideMultiCompany: boolean;
    public multiCompanyAccountsFilteringRequired: boolean;

    public constructor(item?: MultiCompanySettingsApi) {
        if (item) {
            item.enableIndependentAppUsers && (this.enableIndependentAppUsers = item.enableIndependentAppUsers);
            item.enableIndependentBookingControls && (this.enableIndependentBookingControls = item.enableIndependentBookingControls);
            item.enableIndependentCompanyAccounts && (this.enableIndependentCompanyAccounts = item.enableIndependentCompanyAccounts);
            item.enableIndependentCompanyAdvancedBackupZones && (this.enableIndependentCompanyAdvancedBackupZones = item.enableIndependentCompanyAdvancedBackupZones);
            item.enableIndependentCompanyFeederZones && (this.enableIndependentCompanyFeederZones = item.enableIndependentCompanyFeederZones);
            item.enableIndependentCompanyOverbookingProtection && (this.enableIndependentCompanyOverbookingProtection = item.enableIndependentCompanyOverbookingProtection);
            item.enableIndependentCompanyQueryRectangles && (this.enableIndependentCompanyQueryRectangles = item.enableIndependentCompanyQueryRectangles);
            item.enableIndependentCompanyTariffs && (this.enableIndependentCompanyTariffs = item.enableIndependentCompanyTariffs);
            item.enableIndependentCompanyZoneWarningTimes && (this.enableIndependentCompanyZoneWarningTimes = item.enableIndependentCompanyZoneWarningTimes);
            item.enableIndependentCompanyZones && (this.enableIndependentCompanyZones = item.enableIndependentCompanyZones);
            item.enableIndependentDriversFilter && (this.enableIndependentDriversFilter = item.enableIndependentDriversFilter);
            item.enableIndependentLoyalty && (this.enableIndependentLoyalty = item.enableIndependentLoyalty);
            item.enableIndependentQueuePositions && (this.enableIndependentQueuePositions = item.enableIndependentQueuePositions);
            item.enableIndependentVehiclesFilter && (this.enableIndependentVehiclesFilter = item.enableIndependentVehiclesFilter);
            item.haveMultiCompanyBookingControls && (this.haveMultiCompanyBookingControls = item.haveMultiCompanyBookingControls);
            item.haveMultiCompanyIndependentAccounts && (this.haveMultiCompanyIndependentAccounts = item.haveMultiCompanyIndependentAccounts);
            item.haveMultiCompanyIndependentAppUsers && (this.haveMultiCompanyIndependentAppUsers = item.haveMultiCompanyIndependentAppUsers);
            item.haveMultiCompanyIndependentFeederZones && (this.haveMultiCompanyIndependentFeederZones = item.haveMultiCompanyIndependentFeederZones);
            item.haveMultiCompanyIndependentLoyalty && (this.haveMultiCompanyIndependentLoyalty = item.haveMultiCompanyIndependentFeederZones);
            item.haveMultiCompanyIndependentOverbookingProtection && (this.haveMultiCompanyIndependentOverbookingProtection = item.haveMultiCompanyIndependentOverbookingProtection);
            item.haveMultiCompanyIndependentPricing && (this.haveMultiCompanyIndependentPricing = item.haveMultiCompanyIndependentPricing);
            item.haveMultiCompanyIndependentZones && (this.haveMultiCompanyIndependentZones = item.haveMultiCompanyIndependentZones);
            item.haveMultiCompanyZoneWarningTimes && (this.haveMultiCompanyZoneWarningTimes = item.haveMultiCompanyZoneWarningTimes);
            item.mobileBookingOverrideMultiCompany && (this.mobileBookingOverrideMultiCompany = item.mobileBookingOverrideMultiCompany);
            item.multiCompanyAccountsFilteringRequired && (this.multiCompanyAccountsFilteringRequired = item.multiCompanyAccountsFilteringRequired);
        };
    };
};

export class MultiCompanySettingsView {
    public enableIndependentAppUsers: boolean = false;
    public enableIndependentBookingControls: boolean = false;
    public enableIndependentCompanyAccounts: IndependentCompanyAccountsMode = IndependentCompanyAccountsMode.Off;
    public enableIndependentCompanyAccountsOnBookingAndDispatch?: boolean = false;
    public enableIndependentCompanyAdvancedBackupZones: boolean = false;
    public enableIndependentCompanyFeederZones: boolean = false;
    public enableIndependentCompanyOverbookingProtection: boolean = false;
    public enableIndependentCompanyQueryRectangles: boolean = false;
    public enableIndependentCompanyTariffs: boolean = false;
    public enableIndependentCompanyZoneWarningTimes: boolean = false;
    public enableIndependentCompanyZones: boolean = false;
    public enableIndependentDriversFilter: boolean = false;
    public enableIndependentLoyalty: boolean = false;
    public enableIndependentQueuePositions: boolean = false;
    public enableIndependentVehiclesFilter: boolean = false;
    public haveMultiCompanyBookingControls: boolean = false;
    public haveMultiCompanyIndependentAccounts: boolean = false;
    public haveMultiCompanyIndependentAppUsers: boolean = false;
    public haveMultiCompanyIndependentFeederZones: boolean = false;
    public haveMultiCompanyIndependentLoyalty: boolean = false;
    public haveMultiCompanyIndependentOverbookingProtection: boolean = false;
    public haveMultiCompanyIndependentPricing: boolean = false;
    public haveMultiCompanyIndependentZones: boolean = false;
    public haveMultiCompanyZoneWarningTimes: boolean = false;
    public mobileBookingOverrideMultiCompany: boolean = false;
    public multiCompanyAccountsFilteringRequired: boolean = false;

    public constructor(item?: MultiCompanySettingsApi | MultiCompanySettingsView) {
        if (item) {
            item.enableIndependentAppUsers && (this.enableIndependentAppUsers = item.enableIndependentAppUsers);
            item.enableIndependentBookingControls && (this.enableIndependentBookingControls = item.enableIndependentBookingControls);
            if (item.enableIndependentCompanyAccounts) {
                this.enableIndependentCompanyAccounts = item.enableIndependentCompanyAccounts;
                if (item.enableIndependentCompanyAccounts === IndependentCompanyAccountsMode.On) {
                    this.enableIndependentCompanyAccountsOnBookingAndDispatch = true;
                };
            };
            item.enableIndependentCompanyAdvancedBackupZones && (this.enableIndependentCompanyAdvancedBackupZones = item.enableIndependentCompanyAdvancedBackupZones);
            item.enableIndependentCompanyFeederZones && (this.enableIndependentCompanyFeederZones = item.enableIndependentCompanyFeederZones);
            item.enableIndependentCompanyOverbookingProtection && (this.enableIndependentCompanyOverbookingProtection = item.enableIndependentCompanyOverbookingProtection);
            item.enableIndependentCompanyQueryRectangles && (this.enableIndependentCompanyQueryRectangles = item.enableIndependentCompanyQueryRectangles);
            item.enableIndependentCompanyTariffs && (this.enableIndependentCompanyTariffs = item.enableIndependentCompanyTariffs);
            item.enableIndependentCompanyZoneWarningTimes && (this.enableIndependentCompanyZoneWarningTimes = item.enableIndependentCompanyZoneWarningTimes);
            item.enableIndependentCompanyZones && (this.enableIndependentCompanyZones = item.enableIndependentCompanyZones);
            item.enableIndependentDriversFilter && (this.enableIndependentDriversFilter = item.enableIndependentDriversFilter);
            item.enableIndependentLoyalty && (this.enableIndependentLoyalty = item.enableIndependentLoyalty);
            item.enableIndependentQueuePositions && (this.enableIndependentQueuePositions = item.enableIndependentQueuePositions);
            item.enableIndependentVehiclesFilter && (this.enableIndependentVehiclesFilter = item.enableIndependentVehiclesFilter);
            item.haveMultiCompanyBookingControls && (this.haveMultiCompanyBookingControls = item.haveMultiCompanyBookingControls);
            item.haveMultiCompanyIndependentAccounts && (this.haveMultiCompanyIndependentAccounts = item.haveMultiCompanyIndependentAccounts);
            item.haveMultiCompanyIndependentAppUsers && (this.haveMultiCompanyIndependentAppUsers = item.haveMultiCompanyIndependentAppUsers);
            item.haveMultiCompanyIndependentFeederZones && (this.haveMultiCompanyIndependentFeederZones = item.haveMultiCompanyIndependentFeederZones);
            item.haveMultiCompanyIndependentLoyalty && (this.haveMultiCompanyIndependentLoyalty = item.haveMultiCompanyIndependentFeederZones);
            item.haveMultiCompanyIndependentOverbookingProtection && (this.haveMultiCompanyIndependentOverbookingProtection = item.haveMultiCompanyIndependentOverbookingProtection);
            item.haveMultiCompanyIndependentPricing && (this.haveMultiCompanyIndependentPricing = item.haveMultiCompanyIndependentPricing);
            item.haveMultiCompanyIndependentZones && (this.haveMultiCompanyIndependentZones = item.haveMultiCompanyIndependentZones);
            item.haveMultiCompanyZoneWarningTimes && (this.haveMultiCompanyZoneWarningTimes = item.haveMultiCompanyZoneWarningTimes);
            item.mobileBookingOverrideMultiCompany && (this.mobileBookingOverrideMultiCompany = item.mobileBookingOverrideMultiCompany);
            item.multiCompanyAccountsFilteringRequired && (this.multiCompanyAccountsFilteringRequired = item.multiCompanyAccountsFilteringRequired);
        };
    };
};

export class PassengerLoyaltyApi {
    public loyaltyCardProvider: string;
    public enableLoyaltyCards: boolean;
    public enableLoyaltyCardClient: boolean;
    public address: string;
    public port: number;
    public mdtAllowsLoyaltyCards: boolean;
    public mdtAllowsManualInput: boolean;
    public mdtPasswordPrompt: boolean;
    public accountCardsSwipeMode: string;
    public prePayCardsSwipeMode: string;

    public constructor(item?: PassengerLoyaltyApi) {
        this.loyaltyCardProvider = (item && item.loyaltyCardProvider) ? item.loyaltyCardProvider : '';
        this.enableLoyaltyCards = (item && item.enableLoyaltyCards) ? true : false;
        this.enableLoyaltyCardClient = (item && item.enableLoyaltyCardClient) ? true : false;
        this.address = (item && item.address) ? item.address : '';
        this.port = (item && item.port) ? item.port : null;
        this.mdtAllowsLoyaltyCards = (item && item.mdtAllowsLoyaltyCards) ? true : false;
        this.mdtAllowsManualInput = (item && item.mdtAllowsManualInput) ? true : false;
        this.mdtPasswordPrompt = (item && item.mdtPasswordPrompt) ? true : false;
        this.accountCardsSwipeMode = (item && item.accountCardsSwipeMode) ? item.accountCardsSwipeMode : '';
        this.prePayCardsSwipeMode = (item && item.prePayCardsSwipeMode) ? item.prePayCardsSwipeMode : '';
    };
};

export class PassengerLoyaltyView extends PassengerLoyaltyApi {
    public accountCardsSwipeModeDescription: string;
    public prePayCardsSwipeModeDescription: string;

    public constructor(item?: PassengerLoyaltyView) {
        super(item);
        this.accountCardsSwipeModeDescription = (item && item.accountCardsSwipeModeDescription) ? item.accountCardsSwipeModeDescription : '';
        this.prePayCardsSwipeModeDescription = (item && item.prePayCardsSwipeModeDescription) ? item.prePayCardsSwipeModeDescription : '';
    };
};

export class PassengerReliabilityApi {
    public reliabilityRatingsEnabled: boolean;
    public reliabilityRatingsIncludeCancelledBookings: boolean;
    public reliabilityRatingsMaximumNumberOfBookingsForNewCustomer: number;
    public reliabilityRatingsThresholdForHigh: number;
    public reliabilityRatingsThresholdForMedium: number;

    public constructor(item?: PassengerReliabilityApi) {
        if (item) {
            item.reliabilityRatingsEnabled && (this.reliabilityRatingsEnabled = item.reliabilityRatingsEnabled);
            item.reliabilityRatingsIncludeCancelledBookings && (this.reliabilityRatingsIncludeCancelledBookings = item.reliabilityRatingsIncludeCancelledBookings);
            item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer !== undefined && item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer !== null && (this.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer = item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer);
            item.reliabilityRatingsThresholdForHigh !== undefined && item.reliabilityRatingsThresholdForHigh !== null && (this.reliabilityRatingsThresholdForHigh = item.reliabilityRatingsThresholdForHigh);
            item.reliabilityRatingsThresholdForMedium !== undefined && item.reliabilityRatingsThresholdForMedium !== null && (this.reliabilityRatingsThresholdForMedium = item.reliabilityRatingsThresholdForMedium);
        };
    };
};

export class PassengerReliabilityView {
    public reliabilityRatingsEnabled: boolean = false;
    public reliabilityRatingsIncludeCancelledBookings: boolean = false;
    public reliabilityRatingsMaximumNumberOfBookingsForNewCustomer: number = 0;
    public reliabilityRatingsThresholdForHigh: number = 0;
    public reliabilityRatingsThresholdForMedium: number = 0;

    public constructor(item?: PassengerReliabilityApi | PassengerReliabilityView) {
        if (item) {
            item.reliabilityRatingsEnabled && (this.reliabilityRatingsEnabled = item.reliabilityRatingsEnabled);
            item.reliabilityRatingsIncludeCancelledBookings && (this.reliabilityRatingsIncludeCancelledBookings = item.reliabilityRatingsIncludeCancelledBookings);
            item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer !== undefined && item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer !== null && (this.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer = item.reliabilityRatingsMaximumNumberOfBookingsForNewCustomer);
            item.reliabilityRatingsThresholdForHigh !== undefined && item.reliabilityRatingsThresholdForHigh !== null && (this.reliabilityRatingsThresholdForHigh = item.reliabilityRatingsThresholdForHigh);
            item.reliabilityRatingsThresholdForMedium !== undefined && item.reliabilityRatingsThresholdForMedium !== null && (this.reliabilityRatingsThresholdForMedium = item.reliabilityRatingsThresholdForMedium);
        };
    };
};

export enum PassengerReliabilityRating {
    NotSet,
    Whitelisted,
    New,
    Low,
    Medium,
    High
};

export class PoiTypeApi {
    public enabled: boolean;
    public poiId: number;
    public longDescription: string;
    public shortDescription: string;

    public constructor(item?: PoiTypeApi) {
        this.enabled = (item && item.enabled) ? true : false;
        this.poiId = (item && item.poiId) ? item.poiId : 0;
        this.longDescription = (item && item.longDescription) ? item.longDescription : '';
        this.shortDescription = (item && item.shortDescription) ? item.shortDescription : '';
    };
};

export class PoiTypeView {
    public enabled: boolean = false;
    public poiId: number = null;
    public longDescription: string = '';
    public shortDescription: string = '';

    public constructor(item?: PoiTypeApi | PoiTypeView) {
        if (item) {
            item.enabled && (this.enabled = item.enabled);
            item.poiId !== null && item.poiId !== undefined && (this.poiId = item.poiId);
            item.longDescription && (this.longDescription = item.longDescription);
            item.shortDescription && (this.shortDescription = item.shortDescription);
        };
    };
};

export class PoiConfigurationApi {
    public poiTypes: Array<PoiTypeApi>;

    public constructor(poiConfiguration: PoiConfigurationApi) {
        if (poiConfiguration && poiConfiguration.poiTypes && poiConfiguration.poiTypes.length) {
            this.poiTypes = new Array<PoiTypeApi>();
            for (var i = 0; i < poiConfiguration.poiTypes.length; i++) {
                this.poiTypes.push(new PoiTypeApi(poiConfiguration.poiTypes[i]));
            };
        };
    };
};

export enum RingbackDefaultMode {
    RingbackOff = 'RingbackOff',
    RingbackOffLandlinesTextbackOnMobiles = 'RingbackOffLandlinesTextbackOnMobiles',
    RingbackOnLandlinesTextbackOffMobiles = 'RingbackOnLandlinesTextbackOffMobiles',
    RingbackOnLandlinesRingbackOnMobiles = 'RingbackOnLandlinesRingbackOnMobiles',
    RingbackOnLandlinesTextbackOnMobiles = 'RingbackOnLandlinesTextbackOnMobiles'
};

export class RingbackSettingsApi {
    public enabled: boolean;
    public ringbackDefaultMode: RingbackDefaultMode;

    public constructor(item?: RingbackSettingsApi) {
        if (item) {
            item.enabled !== undefined && (this.enabled = item.enabled);
            item.ringbackDefaultMode && (this.ringbackDefaultMode = item.ringbackDefaultMode);
        };
    };
};

export class RingbackSettingsView {
    public enabled: boolean = false;
    public ringbackDefaultMode: RingbackDefaultMode = RingbackDefaultMode.RingbackOff;

    public constructor(item?: RingbackSettingsApi | RingbackSettingsView) {
        if (item) {
            item.enabled !== undefined && (this.enabled = item.enabled);
            item.ringbackDefaultMode && (this.ringbackDefaultMode = item.ringbackDefaultMode);
        };
    };
};

export class RouteCalculationSettingsApi {
    public calculationMode: string;
    public displayCalculatedRouteOnMap: boolean;
    public googleApiKey: string;
    public ignoreTimes: boolean;
    public maximumRouteLengthForVariations: number;
    public objective: string;
    public startEndToleranceForRoute: number;
    public useQuickestRouteForJourneysFurtherThan: number;

    public constructor(item?: RouteCalculationSettingsApi) {
        this.calculationMode = (item && item.calculationMode);
        this.displayCalculatedRouteOnMap = (item && item.displayCalculatedRouteOnMap);
        this.googleApiKey = (item && item.googleApiKey);
        this.ignoreTimes = (item && item.ignoreTimes);
        this.maximumRouteLengthForVariations = (item && item.maximumRouteLengthForVariations);
        this.objective = (item && item.objective);
        this.startEndToleranceForRoute = (item && item.startEndToleranceForRoute);
        this.useQuickestRouteForJourneysFurtherThan = (item && item.useQuickestRouteForJourneysFurtherThan);
    };
};

export class RouteCalculationSettingsView {
    public calculationMode: string = '';
    public displayCalculatedRouteOnMap: boolean = false;
    public googleApiKey: string = '';
    public ignoreTimes: boolean = false;
    public maximumRouteLengthForVariations: number = null;
    public objective: string = '';
    public startEndToleranceForRoute: number = null;
    public useQuickestRouteForJourneysFurtherThan: number = null;

    public constructor(item?: RouteCalculationSettingsApi) {
        if (item) {
            item.calculationMode && (this.calculationMode = item.calculationMode);
            item.displayCalculatedRouteOnMap && (this.displayCalculatedRouteOnMap = item.displayCalculatedRouteOnMap);
            item.googleApiKey && (this.googleApiKey = item.googleApiKey);
            item.ignoreTimes && (this.ignoreTimes = item.ignoreTimes);
            item.maximumRouteLengthForVariations !== undefined && (this.maximumRouteLengthForVariations = item.maximumRouteLengthForVariations);
            item.objective && (this.objective = item.objective);
            item.startEndToleranceForRoute !== undefined && (this.startEndToleranceForRoute = item.startEndToleranceForRoute);
            item.useQuickestRouteForJourneysFurtherThan !== undefined && (this.useQuickestRouteForJourneysFurtherThan = item.useQuickestRouteForJourneysFurtherThan);
        };
    };
};

export class SecuritySettingsApi {
    public passwordExpiryInDays: number;
    public passwordMaxAttempts: number;
    public passwordMinimumLength: number;
    public passwordStrength: string;

    public constructor(item?: SecuritySettingsApi) {
        this.passwordExpiryInDays = (item && (item.passwordExpiryInDays || item.passwordExpiryInDays === 0)) ? item.passwordExpiryInDays : null;
        this.passwordMaxAttempts = (item && (item.passwordMaxAttempts || item.passwordMaxAttempts === 0)) ? item.passwordMaxAttempts : null;
        this.passwordMinimumLength = (item && (item.passwordMinimumLength || item.passwordMinimumLength === 0)) ? item.passwordMinimumLength : null;
        this.passwordStrength = (item && item.passwordStrength) ? item.passwordStrength : '';
    };
};

export class SecuritySettingsView extends SecuritySettingsApi {
    public passwordExpiryEnabled: boolean = false;

    public constructor(item?: SecuritySettingsView) {
        super(item);
        this.passwordExpiryEnabled = (item && item.passwordExpiryInDays > 0) ? true : false;
    };
};

export class StreetEntryModeApi {
    public house: boolean;
    public zone: boolean;
    public name: boolean;
    public note: boolean;
    public block: boolean;
    public building: boolean;

    public constructor(item?: StreetEntryModeApi) {
        this.house = (item && item.house) ? true : false;
        this.zone = (item && item.zone) ? true : false;
        this.name = (item && item.name) ? true : false;
        this.note = (item && item.note) ? true : false;
        this.block = (item && item.block) ? true : false;
        this.building = (item && item.building) ? true : false;
    };
};

export class StreetEntryModeView {
    public block: boolean = false;
    public building: boolean = false;
    public house: boolean = false;
    public name: boolean = false;
    public note: boolean = false;
    public zone: boolean = false;

    public constructor(item?: StreetEntryModeApi | StreetEntryModeView) {
        if (item) {
            item.block && (this.block = item.block);
            item.building && (this.building = item.building);
            item.house && (this.house = item.house);
            item.name && (this.name = item.name);
            item.note && (this.note = item.note);
            item.zone && (this.zone = item.zone);
        };
    };
};

export class TelephonySettingsApi {
    public defaultMode: any;
    public enabled: boolean;
    public phoneSystemUrl: string;

    constructor(item?: TelephonySettingsApi) {
        if (item) {
            item.defaultMode && (this.defaultMode = item.defaultMode);
            item.enabled !== undefined && (this.enabled = item.enabled);
            item.phoneSystemUrl && (this.phoneSystemUrl = item.phoneSystemUrl);
        };
    };
};

export class TelephonySettingsView {
    public defaultMode: any;
    public enabled: boolean = false;
    public phoneSystemUrl: string = '';

    constructor(item?: TelephonySettingsApi | TelephonySettingsView) {
        if (item) {
            item.defaultMode && (this.defaultMode = item.defaultMode);
            item.enabled !== undefined && (this.enabled = item.enabled);
            item.phoneSystemUrl && (this.phoneSystemUrl = item.phoneSystemUrl);
        };
    };
};

export class TextMessagingSettingsApi {
    public driverOutsideSendMode: DriverOutsideSendModeApi;
    public textbackEnabled: boolean;

    public constructor(item?: TextMessagingSettingsApi) {
        this.driverOutsideSendMode = (item && item.driverOutsideSendMode) ? new DriverOutsideSendModeApi(item.driverOutsideSendMode) : new DriverOutsideSendModeApi();
        this.textbackEnabled = (item && item.textbackEnabled) ? true : false;
    };
};

export class TextMessagingSettingsView {
    public driverOutsideSendMode: DriverOutsideSendModeView = new DriverOutsideSendModeView();
    public textbackEnabled: boolean = false;

    public constructor(item?: TextMessagingSettingsView) {
        if (item) {
            item.driverOutsideSendMode && (this.driverOutsideSendMode = new DriverOutsideSendModeView(item.driverOutsideSendMode));
            item.textbackEnabled && (this.textbackEnabled = item.textbackEnabled);
        };
    };
};

export class VehicleCommunicationSettingsApi {
    public finishModeInput: string;
    public startModeInput: string;

    public constructor(item?: VehicleCommunicationSettingsApi) {
        this.finishModeInput = (item && item.finishModeInput) ? item.finishModeInput : '';
        this.startModeInput = (item && item.startModeInput) ? item.startModeInput : '';
    };
};

export class VehicleCommunicationSettingsView {
    public finishModeInput: string = '';
    public startModeInput: string = '';

    public constructor(item?: VehicleCommunicationSettingsView) {
        if (item) {
            item.finishModeInput && (this.finishModeInput = item.finishModeInput);
            item.startModeInput && (this.startModeInput = item.startModeInput);
        };
    };
};