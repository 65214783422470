import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

interface IProgress {
    description: string;
    progress: number;
};

@Injectable()
export class LoadingService {
    public appDataLoadingProgress = new Subject<any>();
    
    private progress = 0;
    
    public constructor() {
        this.progress = 0;
    };

    public emitProgress(progress: IProgress, finishedLoading?: boolean) {
        if (finishedLoading) {
            this.resetProgress();
        };
        this.appDataLoadingProgress.next(progress);
    };

    public getProgress(requestCount: number): number {
        this.progress += 100 / requestCount;
        return Math.floor(this.progress);
    };

    public onAppDataLoadigChange(): Observable<any> {
        return this.appDataLoadingProgress.asObservable();
    };

    public resetProgress() {
        this.progress = 0;
    };
};