import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerOptions, ResponseErrorService } from './response-error.service';
import { SessionService } from './session.service';

@Injectable()
export class RequestService {
    public constructor(private http: HttpClient, private responseErrorService: ResponseErrorService) { };

    public authenticatedDelete(url: string, payload?: any | null, options?: any): Observable<any> {
        return this.http.delete(url, this.getRequestOptions(options, payload));
    };

    public authenticatedDeleteWithErrorHandler(url: string, payload?: any | null, options?: any, errorHandlerOptions?: ErrorHandlerOptions): Observable<any> {
        return this.http.delete(url, this.getRequestOptions(options, payload)).pipe(catchError((error) => this.handleError(error, errorHandlerOptions)));
    };

    public authenticatedGet(url: string, options?: any): Observable<any> {
        return this.http.get(url, this.getRequestOptions(options));
    };

    public authenticatedGetWithErrorHandler(url: string, options?: any, errorHandlerOptions?: ErrorHandlerOptions): Observable<any> {
        return this.http.get(url, this.getRequestOptions(options)).pipe(catchError((error) => this.handleError(error, errorHandlerOptions)));
    };

    public authenticatedPost(url: string, payload: any | null, options?: any): Observable<any> {
        return this.http.post(url, payload, this.getRequestOptions(options));
    };

    public authenticatedPostWithErrorHandler(url: string, payload?: any | null, options?: any, errorHandlerOptions?: ErrorHandlerOptions): Observable<any> {
        return this.http.post(url, payload, this.getRequestOptions(options)).pipe(catchError((error) => this.handleError(error, errorHandlerOptions)));
    };

    public authenticatedPut(url: string, payload: any | null, options?: any): Observable<any> {
        return this.http.put(url, payload, this.getRequestOptions(options));
    };

    public authenticatedPutWithErrorHandler(url: string, payload: any | null, options?: any, errorHandlerOptions?: ErrorHandlerOptions): Observable<any> {
        return this.http.put(url, payload, this.getRequestOptions(options)).pipe(catchError((error) => this.handleError(error, errorHandlerOptions)));
    };

    public delete(url: string, options?: Object): Observable<any> {
        return this.http.delete(url, options);
    };

    public get(url: string, options?: Object): Observable<any> {
        return this.http.get(url, options);
    };

    public handleError = this.responseErrorService.handleError;

    public post(url: string, payload?: any | null, options?: Object): Observable<any> {
        return this.http.post(url, payload, options);
    };

    public put(url: string, payload?: any | null, options?: Object): Observable<any> {
        return this.http.put(url, payload, options);
    };

    private getRequestOptions(options: any, payload?: any) {
        let newOptions: any = {
            headers: new HttpHeaders({ 'Authentication-Token': SessionService.getToken() })
        };
        if (options) {
            if (options.headers) {
                newOptions.headers = Object.assign(options.headers, { 'Authentication-Token': SessionService.getToken() });
            };
            if (options.responseType) {
                newOptions.responseType = options.responseType;
            };
        };
        if (payload) {
            newOptions.body = payload;
        };
        return newOptions;
    };
};