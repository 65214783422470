import { OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentRef, EventEmitter } from "@angular/core";
import { fromEvent, Subject, Subscription } from "rxjs";
import { ShortcutConfig } from "../interfaces/shortcut-config.interface";
import { Dictionary } from "./dictionary.model";

export declare class GhostOverlayConfig extends OverlayConfig {
    centerHorizontally?: boolean;
    centerVertically?: boolean;
    dismissOnBackdropClick?: boolean;
    focusOnBackdropClick?: boolean;
    key?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    replaceByKey?: boolean;
    shortcuts?: Dictionary<ShortcutConfig>;
    singleInstance?: boolean;
};

export declare class GhostOverlayRes<T, V> {
    ghostOverlayRef: GhostOverlayRef<T, V>;
    componentRef: ComponentRef<T>;
};

export interface GhostOverlayVisibillityEvent {
    mutation: MutationRecord;
    visible: boolean;
};

export class GhostOverlayRef<T, V = any> {
    private focusChangeSubscription: Subscription;
    private onBackdropClickSubscription: Subscription;
    private visibilityChanges: MutationObserver;

    public activeElement: HTMLElement;
    public closed = new Subject<V>();
    public completed = new Subject<void>();
    public dismissed = new Subject<void>();
    public onVisibilityChange = new EventEmitter<GhostOverlayVisibillityEvent>();
    public parentShortcuts: Dictionary<ShortcutConfig>;
    public prevActiveElement: HTMLElement;

    public constructor(
        public data: T,
        public overlay: OverlayRef,
        public config: GhostOverlayConfig,
        private onDisposeOverlay: () => void,
        private shortcutsGetter: () => Dictionary<ShortcutConfig>,
        private shortcutsSetter: (shortcuts: Dictionary<ShortcutConfig>) => void
    ) {
        this.initialiseShortcuts();
        this.prevActiveElement = document.activeElement as HTMLElement;
        this.focusChangeSubscription = fromEvent(overlay.hostElement, 'focusin').subscribe(() => {
            this.activeElement = document.activeElement as HTMLElement;
        });
        this.onBackdropClickSubscription = this.overlay.backdropClick().subscribe(() => {
            if (this.config.dismissOnBackdropClick) {
                this.dismiss();
            };
            if (this.config.focusOnBackdropClick) {
                this.activeElement?.focus();
            };
        });
        this.visibilityChanges = new MutationObserver((mutations: MutationRecord[]) => mutations.forEach((mutation: MutationRecord) => {
            const visible: boolean = mutation.target && (<HTMLElement>mutation.target).hidden ? false : true;
            this.onVisibilityChange.emit({ mutation, visible });
            if (visible) {
                this.activeElement && this.activeElement.focus();
            } else {
                this.focusPreviousActiveElement();
            };
        }));
        this.visibilityChanges.observe(this.overlay.hostElement, {
            attributes: true,
            attributeFilter: ['hidden'],
            attributeOldValue: true
        });
    };

    public close = (data?: V) => {
        this.disposeListenersAndOverlay();
        this.closed.next(data);
        this.closed.complete();
        this.complete();
    };

    public dismiss = () => {
        this.disposeListenersAndOverlay();
        this.dismissed.next();
        this.dismissed.complete();
        this.complete();
    };

    public disposeListenersAndOverlay = () => {
        this.focusChangeSubscription?.unsubscribe();
        this.onBackdropClickSubscription?.unsubscribe();
        this.onDisposeOverlay();
        if (this.config) {
            if (this.config.shortcuts) {
                if (this.parentShortcuts) {
                    this.shortcutsSetter(this.parentShortcuts);
                    delete this.parentShortcuts;
                };
            };
        };
        this.focusPreviousActiveElement();
        this.overlay._keydownEvents.complete();
        this.overlay && this.overlay.dispose();
        this.onVisibilityChange.complete();
        this.visibilityChanges.disconnect();
    };

    public hide = () => {
        this.setOverlayVisibility(true);
    };

    public show = () => {
        this.initialiseShortcuts();
        this.setOverlayVisibility(false);
    };

    private complete = () => {
        this.completed.next();
        this.completed.complete();
    };

    private focusPreviousActiveElement() {
        if (this.prevActiveElement && this.prevActiveElement.offsetParent !== null) {
            this.prevActiveElement.focus()
        };
    };

    private initialiseShortcuts() {
        this.parentShortcuts = this.shortcutsGetter();
        if (this.config && this.config.shortcuts) {
            this.shortcutsSetter(this.config.shortcuts);
        };
    };

    private setOverlayVisibility(hidden: boolean) {
        if (this.config && this.config.hasBackdrop && this.overlay.backdropElement) {
            this.overlay.backdropElement.hidden = hidden;
        };
        this.overlay.hostElement.hidden = hidden;
    };
};