import { Dictionary } from '../../models/dictionary.model';
import { Keys } from '../../enums/keys.enum';
import { ShortcutConfig } from '../../interfaces/shortcut-config.interface';
import { Subject } from 'rxjs';

export class ConfirmationOverlayShortcutsConfiguration {
    public static shortcutsSubject = new Subject<string>();

    public static _SHORTCUTS: Dictionary<ShortcutConfig> = {
        [Keys.Escape]: {
            callback: () => ConfirmationOverlayShortcutsConfiguration.shortcutsSubject.next(Keys.Escape),
            description: 'shortcut.description.close',
            descriptionKey: 'Esc',
            key: Keys.Escape,
            visible: true
        },
        [Keys.N]: {
            callback: () => ConfirmationOverlayShortcutsConfiguration.shortcutsSubject.next(Keys.N),
            description: 'shortcut.description.no',
            descriptionKey: 'N',
            key: Keys.N,
            visible: true
        },
        [Keys.Y]: {
            callback: () => ConfirmationOverlayShortcutsConfiguration.shortcutsSubject.next(Keys.Y),
            description: 'shortcut.description.yes',
            descriptionKey: 'Y',
            key: Keys.Y,
            visible: true
        }
    };
};