import * as Sentry from "@sentry/browser";
import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserView } from "../models/user.model";

export class SentryErrorHandler implements ErrorHandler {
    static initialise(): void {
        Sentry.init({
            beforeSend: (event: any): Sentry.Event => {
                if (event.message instanceof HttpErrorResponse) {
                    if (event.message.status === 404 || event.message.status === 409) {
                        return null;
                    };
                };
                return event;
            },
            defaultIntegrations: false,
            dsn: 'https://96892d205b894898ba87a0064ec072e4@sentry.autocab.net/2',
            release: '0.0.0'
        });
    };

    public static configureScope(userId: number): void {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: userId?.toString() || null
            });
        });
    };

    public handleError(error: any): void {
        if (error instanceof HttpErrorResponse) {
            return;
        };
        Sentry.captureException(error);
        console.error(error);
    };
};