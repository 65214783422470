import { BehaviorSubject, Subject } from "rxjs";
import { SpinnerComponent } from "../components/spinner/spinner.component";
import { Keys } from "../enums/keys.enum";
import { ShortcutConfig } from "../interfaces/shortcut-config.interface";
import { Dictionary } from "../models/dictionary.model";
import { PermissionsService } from "./permissions.service";

export class ShortcutsService {
    public static shortcuts: Dictionary<ShortcutConfig> = {};
    public static shortcutsArray: Array<ShortcutConfig> = [];
    public static shortcutsArraySubject: BehaviorSubject<Array<ShortcutConfig>> = new BehaviorSubject([]);

    private static keyupKeyToPrevent: string;
    private static preventDefault = [Keys.F1, Keys.F2, Keys.F3, Keys.F4, Keys.F5, Keys.F6, Keys.F7, Keys.F8, Keys.F9, Keys.F10, Keys.F11, Keys.Alt + Keys.ArrowLeft];

    public constructor() { };

    public static init() {
        document.body.addEventListener('keydown', ShortcutsService.onKeydown);
        document.body.addEventListener('keyup', ShortcutsService.onKeyup);
    };

    public static destroy() {
        document.body.removeEventListener('keydown', ShortcutsService.onKeydown);
        document.body.removeEventListener('keyup', ShortcutsService.onKeyup);
    };

    public static getNormalisedKey(event: KeyboardEvent) {
        let key = event.key.length === 1 ? event.key.toUpperCase() : event.key;
        let normalisedKey = '';

        if (event.altKey) {
            normalisedKey = Keys.Alt;
        };

        if (event.ctrlKey) {
            normalisedKey = Keys.Ctrl;
        };

        if (event.shiftKey) {
            normalisedKey = Keys.Shift;
        };

        return normalisedKey + key;
    };

    public static onKeydown = (event: KeyboardEvent) => {
        const key = ShortcutsService.getNormalisedKey(event);
        if (ShortcutsService.preventDefault.indexOf(key as Keys) !== -1) {
            event.preventDefault();
            event.stopPropagation();
        };
        if (ShortcutsService.shortcuts[key]) {
            ShortcutsService.shortcuts[key].preventDefault && event.preventDefault();
            ShortcutsService.shortcuts[key].stopPropagation && event.stopPropagation();
        };
    };

    public static onKeyup = (event: KeyboardEvent) => {
        const key = ShortcutsService.getNormalisedKey(event);
        const keyupKeyToPrevent = ShortcutsService.keyupKeyToPrevent;
        if (keyupKeyToPrevent) {
            ShortcutsService.keyupKeyToPrevent = null;
            if (keyupKeyToPrevent === key) {
                return;
            };
        };
        const shortcut = ShortcutsService.shortcuts[key];
        if (shortcut && !shortcut.disabled && shortcut.callback) {
            if (!shortcut.permission || PermissionsService.BOOKING[shortcut.permission]) {
                shortcut.callback();
            };
        };
    };

    public static setNextKeyCodeToPreventOnKeyup(key: Keys) {
        ShortcutsService.keyupKeyToPrevent = key;
    };

    public static setShortcutListener = (shortcutsSubject: Subject<string>, shortcuts: Dictionary<() => any>, spinner?: SpinnerComponent) => {
        return shortcutsSubject.subscribe(key => {
            if (shortcuts[key] && (key === Keys.Escape || !spinner || !spinner.loading)) {
                shortcuts[key]();
            };
        });
    };

    public static getShortcuts = () => {
        return ShortcutsService.shortcuts;
    };

    public static setShortcuts = (shortcuts: Dictionary<ShortcutConfig>) => {
        ShortcutsService.shortcuts = shortcuts;
        ShortcutsService.shortcutsArray = Object.values<ShortcutConfig>(shortcuts);
        ShortcutsService.shortcutsArraySubject.next(ShortcutsService.shortcutsArray);
    };
};