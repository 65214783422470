import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Input, OnInit } from "@angular/core";

export interface IMutationRecord extends MutationRecord {
    target: HTMLElement,
    oldValue: string;
};

@Directive({
    selector: '[domChange]'
})

export class DomChangeDirective implements OnInit, OnDestroy {
    private changes: MutationObserver;
    @Input() public attributeFilter: Array<string>;
    @Input() public observeAttributes: boolean = true;
    @Input() public observeCharacterData: boolean;
    @Input() public observeChildList: boolean;
    @Output() public domChange = new EventEmitter<IMutationRecord>();
    @Output() public mutations = new EventEmitter<IMutationRecord[]>();

    public constructor(private elementRef: ElementRef) { };

    public ngOnInit() {
        const element = this.elementRef.nativeElement;

        this.changes = new MutationObserver((mutations: IMutationRecord[]) => {
            this.mutations.emit(mutations);
            mutations.forEach((mutation: IMutationRecord) => this.domChange.emit(mutation));
        });

        this.changes.observe(element, {
            attributes: this.observeAttributes,
            attributeFilter: this.attributeFilter,
            attributeOldValue: this.observeAttributes,
            characterData: this.observeCharacterData,
            characterDataOldValue: this.observeCharacterData,
            childList: this.observeChildList
        });
    };

    public ngOnDestroy(): void {
        this.changes.disconnect();
    };
};