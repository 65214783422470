import { BookingPermissions } from '../enums/booking-permissions.enum';
import { Injectable } from '@angular/core';
import { MiscellaneousPermissions } from '../enums/miscellaneous-permissions';
import { Subject } from 'rxjs';

@Injectable()
export class PermissionsService {
    public static permissionsChangedSubject = new Subject<void>();

    public static BOOKING = {
        [BookingPermissions.AccessBookingSuspensions]: false,
        [BookingPermissions.AccessCabExchangeTransfer]: false,
        [BookingPermissions.AccessCallDetails]: false,
        [BookingPermissions.AccessCallbackStatus]: false,
        [BookingPermissions.AccessChangeHistory]: false,
        [BookingPermissions.AccessChannelMonitor]: false,
        [BookingPermissions.AccessChannelStatistics]: false,
        [BookingPermissions.AccessClearedRejectedVehiclesCommand]: false,
        [BookingPermissions.AccessDataLogHistory]: false,
        [BookingPermissions.AccessDockets]: false,
        [BookingPermissions.AccessDriverInformation]: false,
        [BookingPermissions.AccessDriverMessageHistory]: false,
        [BookingPermissions.AccessDriverMessages]: false,
        [BookingPermissions.AccessDriverQueue]: false,
        [BookingPermissions.AccessFlightInformation]: false,
        [BookingPermissions.AccessHoldReleaseZones]: false,
        [BookingPermissions.AccessJobTemplates]: false,
        [BookingPermissions.AccessLiveDriverShifts]: false,
        [BookingPermissions.AccessNewDriverMessage]: false,
        [BookingPermissions.AccessNotificationHistory]: false,
        [BookingPermissions.AccessPrintBookingReceipt]: false,
        [BookingPermissions.AccessReports]: false,
        [BookingPermissions.AccessSupervisor]: false,
        [BookingPermissions.AccessVehicleInformation]: false,
        [BookingPermissions.AccessVehicleStatistics]: false,
        [BookingPermissions.AccessVehicleTracking]: false,
        [BookingPermissions.AllowFinishVehicleOutOfShift]: false,
        [BookingPermissions.AllowFinishVehicleWhenAllocated]: false,
        [BookingPermissions.AllowIvrToggleOnBooking]: false,
        [BookingPermissions.AllowLaunchCustomerWebsite]: false,
        [BookingPermissions.AllowModifyCreditCardPaymentsAmount]: false,
        [BookingPermissions.AllowModifyDispatchedBookings]: false,
        [BookingPermissions.AllowOverrideBookingControls]: false,
        [BookingPermissions.AllowReplyCustomerTextMessage]: false,
        [BookingPermissions.AllowReplyDriverTextMessage]: false,
        [BookingPermissions.AllowRingbackToggleOnBooking]: false,
        [BookingPermissions.AllowRingbackToggleOnBookingsWithMobileNumbers]: false,
        [BookingPermissions.AllowSendAllDriversTextMessage]: false,
        [BookingPermissions.AllowSendDriverBookingTextMessage]: false,
        [BookingPermissions.AllowSendDriverTextMessage]: false,
        [BookingPermissions.AllowStartVehicleOutOfShift]: false,
        [BookingPermissions.AllowViewCustomerTextMessages]: false,
        [BookingPermissions.AllowViewDriverTextMessages]: false,
        [BookingPermissions.BookingCommandCancelActive]: false,
        [BookingPermissions.BookingCommandCancelAdvanced]: false,
        [BookingPermissions.BookingCommandHoldActive]: false,
        [BookingPermissions.BookingCommandHoldAdvanced]: false,
        [BookingPermissions.BookingCommandHoldNew]: false,
        [BookingPermissions.BookingCommandOverrideActive]: false,
        [BookingPermissions.BookingCommandReleaseActive]: false,
        [BookingPermissions.BookingCommandReleaseAdvanced]: false,
        [BookingPermissions.BookingsRepeatBooking]: false,
        [BookingPermissions.CancelJobTemplates]: false,
        [BookingPermissions.ExportGlobalSearchResults]: false,
        [BookingPermissions.ExportHistory]: false,
        [BookingPermissions.Ghost365AccessActiveBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessCliBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessCurrentBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessAdvancedRepeatBookingsOneDayOption]: false,
        [BookingPermissions.Ghost365AccessAdvancedRepeatBookingsAllDaysOption]: false,
        [BookingPermissions.Ghost365AccessDispatchedBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessExchangedBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessPreBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessSearchBookingsPanel]: false,
        [BookingPermissions.Ghost365AccessWarningBookingsPanel]: false,
        [BookingPermissions.IncrementalReleaseCode]: false,
        [BookingPermissions.InsertDocketFromHistory]: false,
        [BookingPermissions.LogOnToBookings]: false,
        [BookingPermissions.ModifyGlobalSearchExportSettings]: false,
        [BookingPermissions.ModifyHistoryExportSettings]: false,
        [BookingPermissions.OperatorCommandOperatorMessaging]: false,
        [BookingPermissions.OperatorMessageHistoryShowAll]: false,
        [BookingPermissions.PlayPrebookingReleasedSound]: false,
        [BookingPermissions.ReassignBooking]: false,
        [BookingPermissions.TakeCreditCardPayments]: false,
        [BookingPermissions.ToggleDriverSuspended]: false,
        [BookingPermissions.ToggleIvrSystem]: false,
        [BookingPermissions.ToggleMobileBookingSystem]: false,
        [BookingPermissions.ToggleTextBookingSystem]: false,
        [BookingPermissions.VehicleCommandAccount]: false,
        [BookingPermissions.VehicleCommandCancelDispatched]: false,
        [BookingPermissions.VehicleCommandClear]: false,
        [BookingPermissions.VehicleCommandDispatch]: false,
        [BookingPermissions.VehicleCommandDriverMessageDelete]: false,
        [BookingPermissions.VehicleCommandFinish]: false,
        [BookingPermissions.VehicleCommandJobs]: false,
        [BookingPermissions.VehicleCommandLocate]: false,
        [BookingPermissions.VehicleCommandManualDispatch]: false,
        [BookingPermissions.VehicleCommandMobile]: false,
        [BookingPermissions.VehicleCommandNoFare]: false,
        [BookingPermissions.VehicleCommandPlot]: false,
        [BookingPermissions.VehicleCommandQuery]: false,
        [BookingPermissions.VehicleCommandQueuePosition]: false,
        [BookingPermissions.VehicleCommandRecover]: false,
        [BookingPermissions.VehicleCommandRemovePenalty]: false,
        [BookingPermissions.VehicleCommandRequestVehicle]: false,
        [BookingPermissions.VehicleCommandRingback]: false,
        [BookingPermissions.VehicleCommandStart]: false,
        [BookingPermissions.VehicleCommandViewCurrentJob]: false,
        // Hidden in ghost API
        // [BookingPermissions.VehicleCommandViewVehicleDetails]: false,
        [BookingPermissions.VehicleCommandVoiceRequest]: false,
        [BookingPermissions.VehicleQueryDispatch]: false,
        [BookingPermissions.ZoneCommandMapView]: false,
        [BookingPermissions.ZoneCommandStreetView]: false
    };

    public static MISCELLANEOUS = {
        [MiscellaneousPermissions.CustomiseSettings]: false,
        [MiscellaneousPermissions.AccessPrinting]: false,
        [MiscellaneousPermissions.ShowEmergencyForm]: false,
        [MiscellaneousPermissions.SaveExportSettings]: false,
        [MiscellaneousPermissions.AutoApproveClient]: false
    };

    public constructor() { };
};