export class Endpoints {
    private static _API_URL = 'https://server1-000016.ghostapi.net:29003';
    public static set API_URL(url: string) {
        Endpoints._API_URL = url;
    };
    public static get API_URL() {
        return Endpoints._API_URL;
    };
    public static ANALYTICS_TOKENS = '/api/v1/analyticstokens';
    public static AUTHENTICATE: string = '/api/v1/authenticate';
    public static AUTHENTICATE_RENEW: string = '/api/v1/authenticate/renew';
    public static SETTINGS = '/api/v1/settings';
    public static SYSTEM_DETAILS: string = '/api/v1/systemdetails';
    public static SYSTEM_TIME: string = '/api/ghostcompanion/v1/systemtime';
    public static TIMEZONE_SERVER: string = '/api/ghost/v1/timezones/server';
    public static TIMEZONES: string = '/api/ghost/v1/timezones';
    public static USERS = {
        _PICTURE: '/picture',
        _USERS: '/api/ghostcompanion/v1/users/',
        _WHO_AM_I: '/api/ghost/v1/users/whoami'
    };
    public static USER_GROUPS: string = '/api/ghostcompanion/v1/usergroups';
};
