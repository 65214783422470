export class GlobalSearchHistoricSourcesApi {
    public cancelled: boolean;
    public completed: boolean;
    public dispatched: boolean;
    public noJob: boolean;
    public recover: boolean;
    public skipped: boolean;
    public suspended: boolean;

    public constructor(item?: GlobalSearchHistoricSourcesApi) {
        this.cancelled = (item && item.cancelled) ? true : false;
        this.completed = (item && item.completed) ? true : false;
        this.dispatched = (item && item.dispatched) ? true : false;
        this.noJob = (item && item.noJob) ? true : false;
        this.recover = (item && item.recover) ? true : false;
        this.skipped = (item && item.skipped) ? true : false;
        this.suspended = (item && item.suspended) ? true : false;
    };
};

export class GlobalSearchHistoricSourcesView {
    public cancelled: boolean = false;
    public completed: boolean = false;
    public dispatched: boolean = false;
    public noJob: boolean = false;
    public recover: boolean = false;
    public skipped: boolean = false;
    public suspended: boolean = false;

    public constructor(item?: GlobalSearchHistoricSourcesView) {
        if (item) {
            item.cancelled && (this.cancelled = true);
            item.completed && (this.completed = true);
            item.dispatched && (this.dispatched = true);
            item.noJob && (this.noJob = true);
            item.recover && (this.recover = true);
            item.skipped && (this.skipped = true);
            item.suspended && (this.suspended = true);
        };
    };
};