import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { AlertOverlayData, AlertType } from "../components/alert/alert.component";
import { ConfirmationType } from "../components/confirmation/confirmation.component";
import { HttpStatusCode } from "../models/http-status-code.model";
import { ErrorCodeApi, HttpErrorResponseApi, ResponseErrorApi, ServerValidationError } from "../models/response-error.model";
import { OverlayService } from "./overlay.service";

export interface ErrorHandlerOptions {
    onCancelOverride?: () => void;
    onCloseErrorOverlay?: () => void;
    onOverride?: () => void;
};

@Injectable()
export class ResponseErrorService {
    public RESPONSE_ERROR_SUMMARIES: { [key in ErrorCodeApi]?: string } = {
        [ErrorCodeApi.BookingControlError]: 'alert.question.booking_control_settings_would_you_like_to_override',
        [ErrorCodeApi.DriverRequestedError]: 'alert.question.driver_request_settings_would_you_like_to_override',
        [ErrorCodeApi.DriverShiftError]: 'alert.question.driver_shift_pattern_settings_would_you_like_to_override',
        [ErrorCodeApi.VehicleAllocationError]: 'alert.question.vehicle_allocation_settings_would_you_like_to_override',
        [ErrorCodeApi.VehicleRequestedError]: 'alert.question.vehicle_request_settings_would_you_like_to_override',
    };

    public HTTP_STATUS_CODE_SUMMARIES: { [key in HttpStatusCode]?: { title: string, defaultMessage: string } } = {
        [HttpStatusCode.BAD_REQUEST]: {
            title: 'alert.title.bad_request',
            defaultMessage: 'alert.message.sorry_your_browser_sent_a_request_that_this_server_could_not_understand'
        },
        [HttpStatusCode.CONFLICT]: {
            title: 'alert.title.conflict',
            defaultMessage: 'alert.message.this_item_has_been_modified_by_another_user'
        },
        [HttpStatusCode.FORBIDDEN]: {
            title: 'alert.title.forbidden',
            defaultMessage: 'alert.message.sorry_you_are_not_allowed_to_do_this'
        },
        [HttpStatusCode.NOT_ACCEPTABLE]: {
            title: 'alert.title.warning_overridable_setting',
            defaultMessage: 'alert.message.would_you_like_to_override'
        },
        [HttpStatusCode.NOT_FOUND]: {
            title: 'alert.title.not_found',
            defaultMessage: 'alert.message.the_requested_url_was_not_found_on_this_server'
        },
    };

    private GENERIC_OVERRIDABLE_ERROR_SUMMARY = {
        title: 'alert.title.warning_overridable_setting',
        message: 'alert.message.would_you_like_to_override'
    };

    private GENERIC_ERROR = {
        title: 'alert.title.error',
        message: 'alert.message.an_unknown_error_has_occurred'
    };

    private GENERIC_WARNING = {
        title: 'alert.title.warning',
        message: 'alert.message.an_unknown_warning_has_occurred'
    };

    public constructor(private overlayService: OverlayService) { };

    public handleError = (response: HttpErrorResponseApi, errorHandlerOptions?: ErrorHandlerOptions) => {
        if (response.error) {
            if (response.error.errorMessages) {
                this.handleErrorMessages(response.status, response.error.errorMessages, errorHandlerOptions);
            } else if (response.error.errors && response.error.errors.length) {
                this.handleOldErrorTypeMessages(response.error.errors, errorHandlerOptions);
            } else if (response.error.modelState && response.error.modelState.error && response.error.modelState.error.length) {
                this.handleOldErrorTypeMessages(response.error.modelState.error, errorHandlerOptions);
            } else if (response.error.message) {
                this.showAlertOverlay({ messages: response.error.message, title: this.GENERIC_ERROR.title, type: AlertType.Error }, errorHandlerOptions);
            } else {
                this.showAlertOverlay({ messages: this.GENERIC_ERROR.message, title: this.GENERIC_ERROR.title, type: AlertType.Error }, errorHandlerOptions);
            };
        } else {
            const details = this.HTTP_STATUS_CODE_SUMMARIES[response.status] || this.GENERIC_ERROR;
            this.showAlertOverlay({ messages: details.defaultMessage, title: details.title, type: AlertType.Error }, errorHandlerOptions);
        };

        return throwError(response);
    };

    private handleErrorMessages(errorStatusCode: HttpStatusCode, responseMessages: Array<ResponseErrorApi>, errorHandlerOptions?: ErrorHandlerOptions) {
        const messages: string | Array<string> = responseMessages && responseMessages.length ? responseMessages.map(error => error.messages.join(".\n")) : [];
        switch (errorStatusCode) {
            case HttpStatusCode.BAD_REQUEST:
                this.showAlertOverlay({
                    messages: messages.length ? messages : this.HTTP_STATUS_CODE_SUMMARIES[HttpStatusCode.BAD_REQUEST].defaultMessage,
                    title: this.HTTP_STATUS_CODE_SUMMARIES[HttpStatusCode.BAD_REQUEST].title,
                    type: AlertType.Error
                }, errorHandlerOptions);
                break;
            case HttpStatusCode.NOT_ACCEPTABLE:
                this.showNotAcceptableConfirmationOverlay(responseMessages, errorHandlerOptions);
                break;
            case HttpStatusCode.FORBIDDEN:
                this.showAlertOverlay({ messages, title: this.HTTP_STATUS_CODE_SUMMARIES[HttpStatusCode.FORBIDDEN].title, type: AlertType.Error }, errorHandlerOptions);
                break;
            case HttpStatusCode.CONFLICT:
                this.showAlertOverlay({ messages, title: this.HTTP_STATUS_CODE_SUMMARIES[HttpStatusCode.CONFLICT].title, type: AlertType.Error }, errorHandlerOptions);
                break;
            case HttpStatusCode.PARTIAL_CONTENT:
                const message = responseMessages[0].errorCode === ErrorCodeApi.DispatchedBookingNotSentError ? messages : this.GENERIC_WARNING.message;
                this.showAlertOverlay({ messages: message, title: this.GENERIC_WARNING.title, type: AlertType.Warning }, errorHandlerOptions);
                break;
            default:
                this.showAlertOverlay({ messages: this.GENERIC_ERROR.message, title: this.GENERIC_ERROR.title, type: AlertType.Error }, errorHandlerOptions);
                break;
        };
    };

    //NOTE: added to handle differing errors from different server versions (ordinarily this should not happen)
    private handleOldErrorTypeMessages(errors: Array<ServerValidationError>, errorHandlerOptions?: ErrorHandlerOptions) {
        let messages: Array<string> = [];
        for (let i = 0, len = errors.length; i < len; i++) {
            errors[i].title && messages.push(errors[i].title);
        };
        this.showAlertOverlay({ messages: messages.length ? messages : this.GENERIC_ERROR.message, title: this.GENERIC_ERROR.title, type: AlertType.Error }, errorHandlerOptions);
    };

    private showAlertOverlay(data?: AlertOverlayData, errorHandlerOptions?: ErrorHandlerOptions) {
        this.overlayService.showAlertOverlay(data).ghostOverlayRef.completed.subscribe(errorHandlerOptions?.onCloseErrorOverlay);
    };

    private showNotAcceptableConfirmationOverlay(responseMessages: Array<ResponseErrorApi>, errorHandlerOptions?: ErrorHandlerOptions) {
        const ghostOverlayRes = this.overlayService.showConfirmationOverlay({
            extendedQuestions: responseMessages.map(error => {
                return { title: this.RESPONSE_ERROR_SUMMARIES[error.errorCode] ? this.RESPONSE_ERROR_SUMMARIES[error.errorCode] : this.GENERIC_OVERRIDABLE_ERROR_SUMMARY.title, messages: error.messages }
            }),
            title: this.HTTP_STATUS_CODE_SUMMARIES[HttpStatusCode.NOT_ACCEPTABLE].title,
            type: ConfirmationType.Warning
        });
        ghostOverlayRes.ghostOverlayRef.closed.subscribe(override => {
            if (override) {
                errorHandlerOptions && errorHandlerOptions.onOverride && errorHandlerOptions.onOverride();
            } else {
                errorHandlerOptions && errorHandlerOptions.onCancelOverride && errorHandlerOptions.onCancelOverride();
            };
        });
        ghostOverlayRes.ghostOverlayRef.dismissed.subscribe(errorHandlerOptions?.onCloseErrorOverlay);

        return ghostOverlayRes;
    };
};