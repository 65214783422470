export class ChangeEvent<T> {
    changeType: ChangeEventType;
    data: T;
};

export enum ChangeEventType {
    delete = 'delete',
    insert = 'insert',
    update = 'update',
}

export enum ChangeEventTypeEnum {
    delete,
    insert,
    update
};