import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Optional, Output } from "@angular/core";
import { fromEvent, Subscription, timer } from "rxjs";
import { take } from "rxjs/operators";
import { Keys } from "../enums/keys.enum";

export const NavDataAttributes = {
    NODE_INDEX: 'data-nav-node-index',
    GROUP_NODE_INDEX: 'data-nav-group-node-index',
    NO_NAV_ON_ENTER: 'data-no-nav-on-enter'
};

export const NavDatasetKeys = {
    ENTER_NEXT_NODE_NAME: 'enterNextNodeName',
    GROUP_NODE_INDEX: 'navGroupNodeIndex',
    NEXT_NODE_OVERRIDE: 'nextNodeOverride',
    NO_NAV_ON_ENTER: 'noNavOnEnter',
    NODE_INDEX: 'navNodeIndex',
};

export const NavigationDirectiveClasses = {
    KEYBOARD_NAVIGATION_CONTAINER: 'keyboard-navigation-container',
    KEYBOARD_NAVIGATION_NODE: 'keyboard-navigation-node',
    NAVIGATION_GROUP: 'navigation-group',
    NAVIGATION_GROUP_NODE: 'navigation-group-node'
};

export interface NavigationNodeFocusEvent {
    containerId?: string;
    element: HTMLInputElement;
    formControlName?: string;
    name?: string;
};

@Directive({
    exportAs: 'keyboardNavDirective',
    selector: '.keyboard-navigation-container'
})
export class KeyboardNaviagtionContainerDirective implements AfterViewInit {
    public isAfterViewInit: boolean = false;
    public nodes: Array<HTMLInputElement> = [];

    @Input() public disableWhenCtrlKeyPressed: boolean = false;
    @Input() public focusFirstValidNode: boolean = false;

    @Output() public onNavNodeFocus = new EventEmitter<NavigationNodeFocusEvent>();

    @HostListener('keydown', ['$event']) public keydown(event: KeyboardEvent) {
        this.onKeydown(event, document.activeElement as HTMLElement);
    };

    public constructor(private elementRef: ElementRef) { };

    public ngAfterViewInit() {
        this.setNavigationNodes();
        this.isAfterViewInit = true;
        if (this.focusFirstValidNode) {
            this.focusFirstValidNavigationElement();
        };
    };

    public ngOnDestroy() {
        for (let i = 0; i < this.nodes.length; i++) {
            this.nodes[i] = null;
        };
        this.nodes = [];
    };

    public emitNavNodeFocus = (event: NavigationNodeFocusEvent) => {
        event.containerId = this.elementRef.nativeElement.id;
        this.onNavNodeFocus.next(event);
    };

    public focusByNameAttribute(name: string, event?: KeyboardEvent, currentIndex?: number, currentNodeName?: string) {
        if (name) {
            let element = this.elementRef.nativeElement.querySelector(`[name='${name}']`) as HTMLInputElement;
            if (element) {
                if (this.isHidden(element)) {
                    this.focusNextNodeByCurrentNodeName(currentNodeName);
                } else {
                    this.focusNodeElement(element, this.nodes, currentIndex);
                };
            } else if (event && typeof currentIndex === 'number') {
                this.goToNextNode(event, this.nodes, currentIndex);
            };
        };
    };

    public focusFirstValidNavigationElement() {
        this.goToNextNode(null, this.nodes, null, 0);
    };

    public focusHtmlElement = (htmlElement: HTMLInputElement) => {
        htmlElement.focus();
        htmlElement.tagName === 'INPUT' && htmlElement.select();
    };

    public focusNodeElement = (element: HTMLInputElement, nodes: Array<HTMLInputElement>, focusIndex: number, isFocusPrev?: boolean) => {
        if (element.classList.contains(NavigationDirectiveClasses.NAVIGATION_GROUP)) {
            const elements = element.querySelectorAll(`.${NavigationDirectiveClasses.NAVIGATION_GROUP_NODE}`) as NodeListOf<HTMLInputElement>;

            if (elements && elements.length) {
                let nextIndex = null;

                for (let i = 0; i < elements.length; i++) {
                    if (elements[i].isConnected && !elements[i].disabled && !this.isHidden(elements[i])) {
                        nextIndex = i;
                        break;
                    };
                };

                if (nextIndex !== null) {
                    this.focusHtmlElement(elements[nextIndex])
                } else {
                    isFocusPrev ? this.goToPrevNode(null, nodes, focusIndex) : this.goToNextNode(null, nodes, focusIndex);
                };
            } else {
                this.goToNextNode(null, nodes, focusIndex);
            };
        } else {
            this.focusHtmlElement(element);
        };
    };

    public goToNextNode = (event: KeyboardEvent, nodes: Array<HTMLInputElement>, currentIndex: number, focusIndex?: number) => {
        event && event.preventDefault();
        event && event.stopPropagation();

        let lastIndex = nodes.length ? nodes.length - 1 : 0;
        let index = focusIndex !== undefined ? focusIndex : currentIndex < lastIndex ? currentIndex + 1 : 0;
        let nextIndex: number = null;

        for (let i = index; i < nodes.length; i++) {
            if (nodes[i].isConnected && !nodes[i].disabled && !this.isHidden(nodes[i])) {
                nextIndex = i;
                break;
            };
        };

        if (nextIndex === null && index !== 0) {
            for (let i = 0; i < index; i++) {
                if (nodes[i].isConnected && !nodes[i].disabled && !this.isHidden(nodes[i])) {
                    nextIndex = i;
                    break;
                };
            };
        };

        if (nextIndex !== null) {
            this.focusNodeElement(nodes[nextIndex], nodes, nextIndex);
        };
    };

    public goToPrevNode = (event: KeyboardEvent, nodes: Array<HTMLInputElement>, currentIndex: number) => {
        event && event.preventDefault();
        event && event.stopPropagation();

        let lastIndex = nodes.length ? nodes.length - 1 : 0;
        let index = currentIndex > 0 ? currentIndex - 1 : lastIndex;
        let nextIndex: number = null;

        for (let i = index; i >= 0; i--) {
            if (nodes[i].isConnected && !nodes[i].disabled && !this.isHidden(nodes[i])) {
                nextIndex = i;
                break;
            };
        };

        if (nextIndex === null && index !== lastIndex) {
            for (let i = lastIndex; i > index; i--) {
                if (nodes[i].isConnected && !nodes[i].disabled && !this.isHidden(nodes[i])) {
                    nextIndex = i;
                    break;
                };
            };
        };

        if (nextIndex !== null) {
            this.focusNodeElement(nodes[nextIndex], nodes, nextIndex, true);
        };
    };

    public focusNextNodeByCurrentNodeName(name?: string) {
        if (name) {
            let element = this.elementRef.nativeElement.querySelector(`[name='${name}']`) as HTMLInputElement;

            if (element) {
                const index = element.dataset[NavDatasetKeys.NODE_INDEX] ? parseInt(element.dataset[NavDatasetKeys.NODE_INDEX]) : null;
                const nextNodeOverrideName = element.dataset[NavDatasetKeys.NEXT_NODE_OVERRIDE] || null;

                if (index) {
                    nextNodeOverrideName ? this.focusByNameAttribute(nextNodeOverrideName, null, index) : this.goToNextNode(null, this.nodes, index);
                } else {
                    const closestNode = element.closest(`[${NavDataAttributes.NODE_INDEX}]`) as HTMLInputElement;

                    if (closestNode) {
                        const index = closestNode.dataset[NavDatasetKeys.NODE_INDEX] ? parseInt(closestNode.dataset[NavDatasetKeys.NODE_INDEX]) : null;
                        const nextNodeOverrideName = closestNode.dataset[NavDatasetKeys.NEXT_NODE_OVERRIDE] || null;

                        if (index) {
                            nextNodeOverrideName ? this.focusByNameAttribute(nextNodeOverrideName, null, index) : this.goToNextNode(null, this.nodes, index);
                        };
                    };
                };
            };
        };
    };

    public focusNavigationNodeByElement(element: HTMLElement, focusNext?: boolean, focusPrev?: boolean) {
        if (element) {
            const index = element.dataset[NavDatasetKeys.NODE_INDEX] ? parseInt(element.dataset[NavDatasetKeys.NODE_INDEX]) : null;

            if (index !== -1) {
                if (focusNext) {
                    this.goToNextNode(null, this.nodes, index);
                } else if (focusPrev) {
                    this.goToPrevNode(null, this.nodes, index);
                } else {
                    element.focus();
                };
            };
        };
    };

    public focusNextNavigationNode(currentNodeName?: string, nextNodeName?: string, delay?: number) {
        const focus = () => {
            if (nextNodeName) {
                this.focusByNameAttribute(nextNodeName, null, null, currentNodeName);
            } else if (currentNodeName) {
                this.focusNextNodeByCurrentNodeName(currentNodeName);
            };
        };

        delay ? timer(delay).pipe(take(1)).subscribe(focus) : focus();
    };

    public isHidden(element: HTMLElement) {
        return element.offsetParent === null;
    };

    public onKeydown = (event: KeyboardEvent, element: HTMLElement) => {
        if (this.disableWhenCtrlKeyPressed && event.ctrlKey) {
            return;
        };
        if (this.nodes && this.nodes.length) {
            const index = element.dataset[NavDatasetKeys.NODE_INDEX] ? parseInt(element.dataset[NavDatasetKeys.NODE_INDEX]) : null;
            const nextNodeOverrideName = element.dataset[NavDatasetKeys.NEXT_NODE_OVERRIDE] || null;
            const noNavOnEnter = element.dataset[NavDatasetKeys.NO_NAV_ON_ENTER] ? Boolean(element.dataset[NavDatasetKeys.NO_NAV_ON_ENTER]) : false;

            if (index !== null) {
                switch (event.key) {
                    case Keys.ArrowUp:
                        this.goToPrevNode(event, this.nodes, index);
                        break;
                    case Keys.ArrowDown:
                        nextNodeOverrideName ? this.focusByNameAttribute(nextNodeOverrideName, event, index) : this.goToNextNode(event, this.nodes, index);
                        break;
                    case Keys.Enter:
                        if (!noNavOnEnter) {
                            nextNodeOverrideName ? this.focusByNameAttribute(nextNodeOverrideName, event, index) : this.goToNextNode(event, this.nodes, index);
                        };
                        break;
                    case Keys.Space:
                        event.stopPropagation();
                    default:
                        break;
                };
            };
        };
    };

    public setNavigationNodes() {
        this.nodes = [];

        this.elementRef.nativeElement.querySelectorAll(`.${NavigationDirectiveClasses.KEYBOARD_NAVIGATION_NODE}`).forEach((element, index) => {
            element.setAttribute(NavDataAttributes.NODE_INDEX, index.toString());
            this.nodes.push(element);
        });
    };
};

@Directive({
    selector: '.keyboard-navigation-node'
})
export class KeyboardNavigationNodeDirective implements AfterViewInit, OnDestroy {
    private onNodeFocusSubscription: Subscription;

    public constructor(private elementRef: ElementRef, @Optional() private navigationContainer: KeyboardNaviagtionContainerDirective) { };

    public ngAfterViewInit() {
        if (this.navigationContainer) {
            if (this.navigationContainer.isAfterViewInit) {
                this.navigationContainer.setNavigationNodes();
            };

            this.onNodeFocusSubscription = fromEvent<FocusEvent>(this.elementRef.nativeElement, 'focus').subscribe(() => {
                this.navigationContainer.emitNavNodeFocus({
                    element: this.elementRef.nativeElement,
                    formControlName: this.elementRef.nativeElement.getAttribute('formcontrolname'),
                    name: this.elementRef.nativeElement.name
                });
            });
        };
    };

    public ngOnDestroy() {
        this.onNodeFocusSubscription?.unsubscribe();
    };
};

@Directive({
    exportAs: 'navigationGroup',
    selector: '.navigation-group'
})
export class KeyboardNavigationGroupDirective implements AfterViewInit {
    public isAfterViewInit: boolean = false;
    public nodes = [];
    public isVerticalNode: boolean = false;
    public emitNavNodeFocus: (event: NavigationNodeFocusEvent) => void;

    @HostListener('keydown', ['$event']) public keydown(event: KeyboardEvent) {
        if (this.navigationContainer?.disableWhenCtrlKeyPressed && event.ctrlKey) {
            return;
        };
        if (this.nodes && this.nodes.length) {
            const activeElement = document.activeElement as HTMLElement;
            const index = activeElement.dataset[NavDatasetKeys.GROUP_NODE_INDEX] ? parseInt(activeElement.dataset[NavDatasetKeys.GROUP_NODE_INDEX]) : null;

            if (index !== null) {
                if (activeElement.tagName === 'TEXTAREA') {
                    const textarea = activeElement as HTMLTextAreaElement;

                    if (textarea.selectionStart !== 0 && (event.key === Keys.ArrowUp || event.key === Keys.ArrowLeft)) {
                        return;
                    };
                    if (textarea.selectionEnd !== textarea.textLength && (event.key === Keys.Enter || event.key === Keys.ArrowDown || event.key === Keys.ArrowRight)) {
                        return;
                    };
                };

                switch (event.key) {
                    case Keys.ArrowLeft:
                        this.navigationContainer && this.navigationContainer.goToPrevNode(event, this.nodes, index);
                        break;
                    case Keys.ArrowRight:
                        this.navigationContainer && this.navigationContainer.goToNextNode(event, this.nodes, index);
                        break;
                    case Keys.ArrowUp:
                    case Keys.ArrowDown:
                    case Keys.Enter:
                        this.navigationContainer && this.navigationContainer.onKeydown(event, this.elementRef.nativeElement);
                        break;
                    case Keys.Space:
                        event.stopPropagation();
                        break;
                    default:
                        break;
                };
            };
        };
    };

    public constructor(private elementRef: ElementRef, @Optional() private navigationContainer: KeyboardNaviagtionContainerDirective) { };

    public ngAfterViewInit() {
        this.setNavigationGroupNodes();
        this.isAfterViewInit = true;

        if (this.navigationContainer) {
            this.emitNavNodeFocus = this.navigationContainer.emitNavNodeFocus;
        };
    };

    public focus(index: number = 0) {
        if (this.nodes.length) {
            for (let i = index; i < this.nodes.length; i++) {
                const node = this.nodes[i];
                if (node.isConnected && !node.disabled && node.offsetParent) {
                    node.focus();
                    break;
                };
            };
        };
    };

    public setNavigationGroupNodes() {
        this.isVerticalNode = this.elementRef.nativeElement.classList.contains(NavigationDirectiveClasses.KEYBOARD_NAVIGATION_NODE);
        this.nodes = [];

        this.elementRef.nativeElement.querySelectorAll(`.${NavigationDirectiveClasses.NAVIGATION_GROUP_NODE}`).forEach((element, index) => {
            element.setAttribute(NavDataAttributes.GROUP_NODE_INDEX, index.toString());
            this.nodes.push(element);
        });
    };
};

@Directive({
    selector: '.navigation-group-node'
})
export class KeyboardNavigationGroupNodeDirective implements AfterViewInit, OnDestroy {
    private onNodeFocusSubscription: Subscription;

    public constructor(private elementRef: ElementRef, @Optional() private navigationGroupDirective: KeyboardNavigationGroupDirective) { };

    public ngAfterViewInit() {
        if (this.navigationGroupDirective) {
            if (this.navigationGroupDirective.isAfterViewInit) {
                this.navigationGroupDirective.setNavigationGroupNodes();
            };

            this.onNodeFocusSubscription = fromEvent<FocusEvent>(this.elementRef.nativeElement, 'focus').subscribe(() => {
                this.navigationGroupDirective.emitNavNodeFocus && this.navigationGroupDirective.emitNavNodeFocus({
                    element: this.elementRef.nativeElement,
                    formControlName: this.elementRef.nativeElement.getAttribute('formcontrolname'),
                    name: this.elementRef.nativeElement.name
                });
            });
        };
    };

    public ngOnDestroy() {
        this.onNodeFocusSubscription?.unsubscribe();
    };
};