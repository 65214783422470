export class GlobalSearchAddressFilterOptionsApi {
    public ignorePostcode: boolean;
    public ignoreTown: boolean;

    public constructor(item?: GlobalSearchAddressFilterOptionsApi) {
        this.ignorePostcode = (item && item.ignorePostcode) ? true : false;
        this.ignoreTown = (item && item.ignoreTown) ? true : false;
    };
};

export class GlobalSearchAddressFilterOptionsView {
    public ignorePostcode: boolean = false;
    public ignoreTown: boolean = false;

    public constructor(item?: GlobalSearchAddressFilterOptionsView) {
        if (item) {
            item.ignorePostcode && (this.ignorePostcode = true);
            item.ignoreTown && (this.ignoreTown = true);
        };
    };
};