import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

export const AppRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { };
