import { Overlay, OverlayConfig, PositionStrategy } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Injector, Type } from "@angular/core";
import { AlertOverlayShortcutsConfiguration } from "../components/alert/alert-shortcuts.configuration";
import { AlertOverlayComponent, AlertOverlayData } from "../components/alert/alert.component";
import { ConfirmationOverlayShortcutsConfiguration } from "../components/confirmation/confirmation-shortcuts.configuration";
import { ConfirmationOverlayComponent, ConfirmationOverlayData } from "../components/confirmation/confirmation.component";
import { Dictionary } from "../models/dictionary.model";
import { GhostOverlayConfig, GhostOverlayRef, GhostOverlayRes } from "../models/ghost-overlay.model";
import { ObjectUtilities } from "../utilities/object.utilities";
import { ShortcutsService } from "./shortcuts.service";

@Injectable()
export class OverlayService {
    private overlays: Dictionary<GhostOverlayRef<any>> = {};
    private singleInstanceOverlays: Dictionary<GhostOverlayRes<any, any>> = {};

    public constructor(private overlay: Overlay) { };

    public closeVisibleOverlays() {
        Object.keys(this.overlays).forEach(key => {
            const overlayElement = this.overlays[key].overlay.overlayElement;
            if (overlayElement) {
                const DOMRect = overlayElement.getBoundingClientRect();
                if (DOMRect.height || DOMRect.width) {
                    this.overlays[key].dismiss();
                };
            };
        });
    };

    public createOverlay = <T, V>(component: Type<T>, data?, config?: GhostOverlayConfig): GhostOverlayRes<T, V> => {
        const overlayConfig = Object.assign({ focusOnBackdropClick: true, singleInstance: false, key: ObjectUtilities.generateGuid() }, config);

        if (overlayConfig.singleInstance && this.singleInstanceOverlays[component.name]) {
            return;
        };
        if (config.key && this.overlays[config.key] && config.replaceByKey) {
            this.overlays[config.key].disposeListenersAndOverlay();
        };

        const onDisposeOverlay = () => {
            delete this.overlays[config.key];
            if (overlayConfig.singleInstance) {
                delete this.singleInstanceOverlays[component.name];
            };
        };
        const overlayRef = this.overlay.create(this.getOverlayConfig(overlayConfig));
        const ghostOverlayRef = new GhostOverlayRef<T, V>(data, overlayRef, overlayConfig, onDisposeOverlay, ShortcutsService.getShortcuts, ShortcutsService.setShortcuts);
        const injector: Injector = Injector.create({ providers: [{ provide: GhostOverlayRef, useValue: ghostOverlayRef }] });
        const ghostOverlayRes: GhostOverlayRes<T, V> = {
            componentRef: overlayRef.attach(new ComponentPortal(component, null, injector)),
            ghostOverlayRef: ghostOverlayRef
        };
        this.overlays[config.key] = ghostOverlayRef;

        if (overlayConfig.singleInstance) {
            this.singleInstanceOverlays[component.name] = ghostOverlayRes;
        };

        return ghostOverlayRes;
    };

    public createOverlayModal = <T, V>(component: Type<T>, data, config?: GhostOverlayConfig) => {
        return this.createOverlay<T, V>(component, data, Object.assign({
            centerHorizontally: true,
            centerVertically: true,
            hasBackdrop: true,
            panelClass: 'overlay-modal'
        }, config));
    };

    public createSingleInstanceOverlayModal = <T, V>(component: Type<T>, data?, config?: GhostOverlayConfig): GhostOverlayRes<T, V> | undefined => {
        return this.createOverlayModal(component, data, Object.assign({ singleInstance: true }, config));
    };

    public showAlertOverlay = (data?: AlertOverlayData, config?: GhostOverlayConfig) => {
        return this.createOverlayModal(AlertOverlayComponent, data, Object.assign({
            height: 'auto',
            width: 600,
            shortcuts: AlertOverlayShortcutsConfiguration._SHORTCUTS
        }, config));
    };

    public showConfirmationOverlay = (data?: ConfirmationOverlayData, config?: GhostOverlayConfig) => {
        return this.createOverlayModal<ConfirmationOverlayComponent, boolean>(ConfirmationOverlayComponent, data, Object.assign({
            height: 'auto',
            width: 600,
            shortcuts: ConfirmationOverlayShortcutsConfiguration._SHORTCUTS
        }, config));
    };

    private getOverlayConfig(config?: GhostOverlayConfig): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: config && config.hasBackdrop,
            backdropClass: config && this.mergeElementClasses(['ghost-overlay-backdrop'], config.backdropClass),
            height: config && config.height !== undefined ? config.height : '100%',
            width: config && config.width !== undefined ? config.width : '100%',
            maxHeight: config && config.maxHeight !== undefined ? config.maxHeight : undefined,
            maxWidth: config && config.maxWidth !== undefined ? config.maxWidth : undefined,
            minHeight: config && config.minHeight !== undefined ? config.minHeight : undefined,
            minWidth: config && config.minWidth !== undefined ? config.minWidth : undefined,
            panelClass: this.mergeElementClasses(['ghost-overlay-pane'], config.panelClass),
            positionStrategy: config.positionStrategy ? config.positionStrategy : this.getOverlayPositionStrategy(config),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });
    };

    private getOverlayPositionStrategy(config?: GhostOverlayConfig): PositionStrategy {
        let position = this.overlay.position().global();
        if (config) {
            config.centerHorizontally && position.centerHorizontally();
            config.centerVertically && position.centerVertically();
            config.top !== undefined && position.top(config.top);
            config.right !== undefined && position.right(config.right);
            config.bottom !== undefined && position.bottom(config.bottom);
            config.left !== undefined && position.left(config.left);
        };
        return position;
    };

    private mergeElementClasses(defaultClasses: string | Array<string>, extensionClasses?: string | Array<string>) {
        return !extensionClasses ? defaultClasses : Array.isArray(extensionClasses) ? [...defaultClasses, ...extensionClasses] : [...defaultClasses, extensionClasses];
    };
};