import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {
    public static deleteItem(key): void {
        delete localStorage[key];
    };

    public static getItem(key): any {
        return JSON.parse(localStorage.getItem(key));
    };

    public static setItem(key, payload): void {
        localStorage.setItem(key, this.stringify(payload));
    };

    public static stringify(json): string {
        return JSON.stringify(json);
    };
};