export enum Keys {
    Backspace = 'Backspace',
    Tab = 'Tab',
    Enter = 'Enter',
    Shift = 'Shift',
    Ctrl = 'Control',
    Alt = 'Alt',
    AltGraph = 'AltGraph',
    PauseBreak = 'Pause',
    CapsLock = 'CapsLock',
    Escape = 'Escape',
    Space = ' ',
    PageUp = 'PageUp',
    PageDown = 'PageDown',
    End = 'End',
    Home = 'Home',

    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',

    Insert = 'Insert',
    Delete = 'Delete',

    Zero = '0',
    One = '1',
    Two = '2',
    Three = '3',
    Four = '4',
    Five = '5',
    Six = '6',
    Seven = '7',
    Eight = '8',
    Nine = '9',

    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    O = 'O',
    P = 'P',
    Q = 'Q',
    R = 'R',
    S = 'S',
    T = 'T',
    U = 'U',
    V = 'V',
    W = 'W',
    X = 'X',
    Y = 'Y',
    Z = 'Z',

    Multiply = '*',
    Add = '+',
    Subtract = '-',
    Decimal = '.',
    Divide = '/',

    F1 = 'F1',
    F2 = 'F2',
    F3 = 'F3',
    F4 = 'F4',
    F5 = 'F5',
    F6 = 'F6',
    F7 = 'F7',
    F8 = 'F8',
    F9 = 'F9',
    F10 = 'F10',
    F11 = 'F11',
    F12 = 'F12',

    NumLock = 'NumLock',
    ScrollLock = 'ScrollLock',
    SemiColon = ';',
    Equal = '=',
    Comma = ',',
    Dash = '-',
    Hash = '#',
    Period = '.',
    Slash = '/',
    Backquote = '`',
    BracketLeft = '[',
    Backslash = '\\',
    BracketRight = ']',
    Quote = `'`,
};